    <ngx-loading-bar [includeSpinner]=false color="#9575cd"></ngx-loading-bar>
    <div #myLauncher id='myLauncher' (click)='openWidget()' >?</div>
    <div #reconnectDialog id="ReconectDialog" style="display: none; overflow-y: hidden">
        <div style='margin: 20px; vertical-align: middle; align-content: center; text-align: center;'>
            Reconnecting with LifeReader
            <br/>

        </div>
    </div>
    <div #page class="no-maintenance">
        <ng-container>
            <ngx-loadable #headerComponent [show]="showHeaderComponent" module="header">
            </ngx-loadable>
            <app-alert></app-alert>

            <router-outlet [hidden]="!headerComponentShown" ></router-outlet>
            <app-footer></app-footer>
        <div [hidden]="prod">{{version}}</div>
            <div *ngIf="lastActiveDate$ | async as lastActiveDate">
                <p>Last active {{ lastActiveDate  }}.</p>
            </div>
    </ng-container>

    </div>

