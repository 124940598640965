import {Injectable} from '@angular/core';
import {ElectronMessage} from '../entity/electron-message';
import {environment} from '../../environments/environment';
import {AllEmiterService} from './all-emiter.service';

let electron;

@Injectable({
    providedIn: 'root',
})

export class ElectronService {
    constructor(
        private allEmiterService: AllEmiterService
    ) {
        if (environment.electron) {
            electron = (<any>window).require('electron');
            electron.ipcRenderer.on('angular', (event, arg) => {
                this.processElectronRequest(arg);
            });
        }
    }

    private processElectronRequest(request: ElectronMessage) {
        switch (request.action) {
            case 'changeStatus':
                this.allEmiterService.onReaderWantsToChangeStatus(request.p1);
                break;

        }
    }

    private sendAction (message: ElectronMessage) {
        if (environment.electron) {
            electron.ipcRenderer.send('electron', message);
        }
    }

    public setBadgeText(text: string) {
        const message: ElectronMessage = new ElectronMessage();
        message.action = 'setBadgetText';
        message.p1 = text;
        this.sendAction(message);
    }
    public setBadgeIcon(image: string) {
        const message: ElectronMessage = new ElectronMessage();
        message.action = 'setBadgeIcon';
        message.p1 = image;
        this.sendAction(message);
    }
    public bounceDock(type: string = 'critical') {
        const message: ElectronMessage = new ElectronMessage();
        message.action = 'bounceDock';
        message.p1 = type;
        this.sendAction(message);
    }

    public setDockMenu() {
        const message: ElectronMessage = new ElectronMessage();
        message.action = 'setDockMenu';
        this.sendAction(message);
    }

    public removeDockMenu() {
        const message: ElectronMessage = new ElectronMessage();
        message.action = 'removeDockMenu';
        this.sendAction(message);
    }

}
