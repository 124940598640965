<div class="modal-body text-center impulse">
    <h2>Chat Request</h2>
    <p>from {{clientName}}</p>
    <div>
        <span class="timer">{{time}}</span>
    </div>
    <div class="button-block">
        <button (click)="doAccept()" [disabled]="chatRequestAccepted" class="btn btn-success">accept chat</button>
        <button (click)="doDecline()" class="btn btn-default">decline</button>
    </div>
</div>
