import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {interval} from 'rxjs';
import {Subscription} from 'rxjs/internal/Subscription';
import {Reader} from '../modules/shared/entity/reader';
import Timeout = NodeJS.Timeout;

@Injectable({
    providedIn: 'root'
})
export class UtilService {
    private isBrowser: boolean = isPlatformBrowser(this.platformId);

    // private isBrowser=true;
    constructor(@Inject(PLATFORM_ID) private platformId: Object,
    ) {

    }

    public setTimeout(callback, time = 1) {
        if (this.isBrowser) {
            return setTimeout(callback, time);
        } else {
            return null;
        }
    }

  public setInterval(callback, time): Subscription | null {
    if (this.isBrowser) {
      // return setInterval(callback, time);
      return interval(time).subscribe(() => {
        callback();
      });
    } else {
      return null;
    }
  }

  public clearInterval(obs: Subscription) {
    try {
      if (obs) {
        obs.unsubscribe();
      }

    } catch (e) {
      console.error(e);
    }
  }

  public clearTimeOut(timer: Timeout) {
    if (this.isBrowser) {
      clearTimeout(timer);
    } else {
      return null;
    }
  }

  public localStorageSetItem(key: string, value: string) {
    if (this.isBrowser) {
      try {
        localStorage.setItem(key, value);
      } catch (e) {
      }
    }
  }
  public localStorageGetItem(key: string, ) {
    if (this.isBrowser) {
      return localStorage.getItem(key);
    } else {
      return null;
    }
  }
  public localStorageRemoveItem(key: string, ) {
    if (this.isBrowser) {
      localStorage.removeItem(key);
    } else {
      return '';
    }
  }
  public localStorageClear() {
    if (this.isBrowser) {
      localStorage.clear();
    } else {
      return '';
    }
  }
  public getDefaultReader(): Reader {

    const reader: Reader = new Reader();

      reader.id = 1;
      reader.nickname = 'LifeReader';
      reader.photoOrig = 'nobody_orig.jpg';
      reader.available = true;
      reader.busy = false;
      reader.logged = false;
      reader.readingsNumber = 42170;
      reader.userPhoneOnlineState = 2;
      reader.userChatOnlineState = 0;
      reader.userEmailOnlineState = 0;
      reader.userPhoneOnlineStatusBusy = false;
      reader.zodiac = 'gemini';
      reader.alert = 'Ease your mind. Certified psychic/ life coach &amp; healer.';
      reader.shortDescr = '';
      reader.rateId = 5;
      const callRates = {
        id: 210,
        currency: {
          asciiSymbol: '$',
          sign: 'USD',
          id: 1
        },
        callRate: 6.5,
        chatRate: 6.5,
        emailRate: 0,
        rateId: 5,
        actualDate: 1465913194000,
        currencyId: 1
      };
      reader.callRates = callRates;
      const chatRates = {
        id: 210,
        currency: {
          asciiSymbol: '$',
          sign: 'USD',
          id: 1
        },
        callRate: 6.5,
        chatRate: 6.5,
        emailRate: 0,
        rateId: 5,
        actualDate: 1465913194000,
        currencyId: 1
      };
      reader.chatRates = chatRates;
      const consultantRating = {
        rating: 10,
        reviews: 24507,
        percentageRating: 99
      };
      reader.consultantRating = consultantRating;
      reader.since = 1441306863000;
      reader.verbs = [
        'Love & Relationship',
        'Finance & Money',
        'Life Coaching'
      ];
      reader.premier = true;
      reader.favorite = false;
      reader.giftCard = null;
      reader.freeChatMinutes = 4;
      reader.about = '';
      reader.aboutHtml = '';

      return reader;
  }

    public startAddFundsAttempt() {
        const addFundsAttempt = Math.random().toString(36).slice(2, 7);
        this.localStorageSetItem('addFundsAttempt', addFundsAttempt);
    }

    public async delay(time: number) {
        await new Promise(f => setTimeout(f, time));
    }


}
