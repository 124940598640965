import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BackRouter} from '../../../modules/shared/services/back-router';
import {UserDetails} from '../../../modules/shared/entity/user-details';
import {SubSink} from 'subsink';
import {EndChatRequestDto} from '../../../modules/chat/dto/end-chat-request.dto';
import {ChatMessages} from '../../../entity/chat-messages';
import {Api} from '../api/api';
import {ManualTopUpDto} from '../request/manual-top-up.dto';

@Injectable({
    providedIn: 'root',
})
export class ChatService implements OnDestroy {
    data: UserDetails;
    private subs = new SubSink();

    constructor(private httpClient: HttpClient,
                private br: BackRouter,
                private api: Api) {
    }



    sendChatKilled(userId) {
        return this.httpClient.get(
            this.br.getServerUrl() + `/newchat/chat/chatKilled/${userId}`,
            {withCredentials: true, headers: { ignoreLoadingBar: '' }}
        ).toPromise();
    }

    generateTelegramLink(javaId, username) {
        return this.httpClient.post(
            this.br.getServerUrl() + `/newchat/telegram/token/`,
            {javaId, username},
            {withCredentials: true, headers: { ignoreLoadingBar: '' }}
        ).toPromise();
    }
    sendTelegramMessage(javaId, message) {
        return this.httpClient.post(
            this.br.getServerUrl() + `/newchat/telegram/send`,
            {javaId, message},
            {withCredentials: true, headers: { ignoreLoadingBar: '' }}
        ).toPromise();
    }

    getCallInfo(CallSid) {
        return this.httpClient.post(
            this.br.getServerUrl() + `/newchat/call/info`,
            {CallSid},
            {withCredentials: true, headers: { ignoreLoadingBar: '' }}
        ).toPromise();
    }



    async chatAccepted(readingId) {
        const chatMessage: ChatMessages = await this.api.get(`/chat/accepted/${readingId}`, null);
        return chatMessage;
    }

    async chatDeclined(readingId) {
        return await this.api.get(`/chat/declined/${readingId}`, null);
    }

    async informerRaised(chatId: number) {
        return await this.api.get(`/chat/informer-raised/${chatId}`, null);
    }

    async manualTopUp(dto: ManualTopUpDto): Promise<boolean> {
        const result = this.api.post(`/chat/manual-top-up/`, dto);
        return result;
    }



    /**
     * Call Java ws to let them know user is connected and stop the server from finishing the chat
     * @param chatId
     * @param userId
     */
    chatIsAlive(chatId: number, userId: number) {
        return this.httpClient.post(
            this.br.getJavaWebServicesUrl() + `/rest/reading/chat/${chatId}/ping?userId=${userId}`,{},
            {headers: { ignoreLoadingBar: '' }}
        ).toPromise();
    }

    chatFinished(body: EndChatRequestDto) {
        return this.httpClient.post(
            this.br.getJavaWebServicesUrl() + `/rest/reading/chat/finish`, body,
            {headers: { ignoreLoadingBar: '' }}
        ).toPromise();
    }


    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
