import {Component, OnInit} from '@angular/core';
import {ServerErrors} from '../../helpers/server-errors';
import {FormBuilder, Validators} from '@angular/forms';
import {FormHelper} from '../../helpers/form.helper';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {UserService} from '../../../../services/user.service';
import {UtilService} from '../../../../services/util.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent extends ServerErrors implements OnInit {

    success;

    constructor(
        private fb: FormBuilder,
        public bsModalRef: BsModalRef,
        private userService: UserService,
        private utilService: UtilService,
    ) {
        super();
    }

    ngOnInit() {
        this.buildForm();
    }

    buildForm() {
        this.form = this.fb.group({
            email: ['', Validators.compose([
                Validators.email,
                Validators.required,
            ])],
        });
    }

    doSubmit() {
        const self = this;
        FormHelper.touchedForm(this.form);
        if (!this.form.valid) {
            return;
        }
        this.success = false;
        this.userService.forgetPassword(this.form.value).subscribe((res) => {
            this.success = true;
            self.utilService.setTimeout(() => {
                this.bsModalRef.hide();
            }, 5000);
        });
    }
}
