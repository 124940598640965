import {Type} from 'class-transformer';
import {UserDetails} from '../modules/shared/entity/user-details';

export class LoginResponse {
    jsessionId: string;
    rememberMe: string;
    role: string;
    time = Date.now();
    @Type(() => UserDetails)
    userDetails: UserDetails;
    token: string;

    toString() {
        return JSON.stringify(this);
    }
}
