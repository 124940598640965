import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CustomerService} from '../../../customer/services/customer.service';
import {Reader} from '../../entity/reader';
import {BsModalService} from 'ngx-bootstrap/modal';
import {UserService} from '../../../../services/user.service';
import {RegistrationComponent} from '../registration/registration.component';
import {SubSink} from 'subsink';

@Component({
    selector: 'shared-favourite',
    templateUrl: './favourite.component.html',
    styleUrls: ['./favourite.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavouriteComponent implements OnInit, OnDestroy {
    @Input()
    favorite = true;
    @Input()
    full = false;
    @Input()
    reader: Reader;
    @Input()
    type = 0;
    role: string;
    private subs = new SubSink();

    constructor(
        private customerService: CustomerService,
        private bsModalService: BsModalService,
        private userService: UserService,
        private cdr: ChangeDetectorRef,
    ) {
    }

    ngOnInit() {
    }

    addFav() {
        const self = this;
        if (!this.userService.isLoggedIn()) {
            this.bsModalService.show(RegistrationComponent, {
                class: 'modal-dialog-steps',
                backdrop: true,
                keyboard: true,
                animated: false,
            });
            return;
        }

        if (this.userService.getData().role !== 'ROLE_CUSTOMER') {
            return;
        }

        this.subs.sink = this.customerService.addReaderFavorite({readerId: this.reader.id}).subscribe((res) => {
            this.reader.favorite = res.favourite;
            // console.og('Res: ' +  this.reader.favorite);
            self.cdr.detectChanges();
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
