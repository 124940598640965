import {AfterContentInit, Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {UtilService} from '../services/util.service';

@Directive({
    selector: '[appAutoFocus]'
})
export class AutoFocusDirective implements AfterContentInit, OnChanges {

    public constructor(
        private el: ElementRef,
        private utilService: UtilService
    ) {

    }
    @Input() appAutoFocus: boolean;

    ngOnChanges(changes: SimpleChanges) {

        this.setFocus(this.appAutoFocus);
    }
    public ngAfterContentInit() {
    }

    public setFocus(focus) {
        const self = this;
        if (focus) {
            self.utilService.setTimeout(() => {
                this.el.nativeElement.focus();
            }, 500);
        }

    }

}
