import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BackRouter} from '../../../modules/shared/services/back-router';
import {UserDetails} from '../../../modules/shared/entity/user-details';
import {SubSink} from 'subsink';
import {EndChatRequestDto} from '../../../modules/chat/dto/end-chat-request.dto';
import {LoginRequestDto} from './dto/login.request.dto';
import {LoginResponseDto} from './dto/login.response.dto';
import {UtilService} from '../../util.service';
import {Api} from '../api/api';
import {AllEmiterService} from '../../all-emiter.service';

@Injectable({
    providedIn: 'root',
})
export class AuthNestjsService implements OnDestroy {
    data: UserDetails;

    constructor(private utilService: UtilService, private br: BackRouter, private api: Api, private allEmiterService: AllEmiterService,) {
    }



    async login(dto: LoginRequestDto) {
        const response: LoginResponseDto = await this.api.post('/auth/login', dto);
        if (response) {
            this.utilService.localStorageSetItem('token', response.token);
        }
        // @ts-ignore
        return response;
    }

    async test() {
        try {
            const response: LoginResponseDto = await this.api.get('/auth/test', {});
        } catch (e) {
            if (e?.error?.statusCode === 401) {
                this.allEmiterService.onUserLogOut();
            }
        }

    }




    ngOnDestroy(): void {
    }
}
