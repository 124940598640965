import {UserDetails} from '../modules/shared/entity/user-details';
import {Type} from 'class-transformer';

export class SocialLoginResponse {
    role: string;
    @Type(() => UserDetails)
    userDetails: UserDetails;
    token: string;
    newAccount: boolean;
}
