import {Inject, Injectable, InjectionToken} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {Router} from '@angular/router';
import {CookieService} from 'ng2-cookies';
import {first, tap} from 'rxjs/operators';
import {of, throwError} from 'rxjs';
import {WINDOW} from '../providers/window.provider';
import {AllEmiterService} from '../services/all-emiter.service';
import {UserService} from '../services/user.service';
import {ChatService} from '../services/nestjs/chat/chat.service';
import {AlertService} from '../services/alert.service';
import {environment} from '../../environments/environment';
import {RequestQueueService} from '../services/request-queue.service';
import { EMPTY } from 'rxjs';
import {UtilService} from '../services/util.service';
import { NGXLogger } from 'ngx-logger';
import {ErrorService} from '../services/error.service';
import {SessionDieService} from '../services/nestjs/logs/session-die/session-die.service';
import * as Sentry from '@sentry/angular';
// import {makeStateKey, TransferState} from '@angular/platform-browser';
export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');
const DEFAULT_MAX_RETRIES = 3;
const DEFAULT_BACKOFF = 1000;


@Injectable()
export class AuthInterceptor implements HttpInterceptor {


    private ip;
    private showErrorMessages = false;
    private currentUserInvalid = 0;
    constructor(private router: Router,
                private alertService: AlertService,
                private cookie: CookieService,
                @Inject(WINDOW) private window: Window,
                private allEmiterService: AllEmiterService,
                @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number,
                private queueService: RequestQueueService,
                private utilService: UtilService,
                private logger: NGXLogger,
                private errorService: ErrorService,
                private sessionDieService: SessionDieService,
                public util: UtilService,
                ) {

        this.ip = this.window.location.origin;
        this.showErrorMessages = (environment.name === 'production' || environment.name === 'stage') ? false : true;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const self = this;
        request = request.clone({
            headers: request.headers.delete('timeout')
        });
        let req = request.clone({
            withCredentials: true,
        });
        const token = this.util.localStorageGetItem('token');
        const tokenRegistration = this.util.localStorageGetItem('token-registration');
        // Special scenario where we do not sent the token to avoid conflict when user is adding the cc from the app and there is already
        // one user log in  the web
        const ignoreToken = this.util.localStorageGetItem('ignoreToken');
        const url = request.url;
        if (token) {
            if (url.includes('rest/v1/credit_card/') && ignoreToken === 'true') {
                // console.og('Ignoring token for request', url);
            } else {
                req = req.clone({
                    setHeaders: {
                        Authorization: 'Bearer ' + token
                    }
                });
            }

        }
        if (tokenRegistration) {
            if (
                url.includes('/rest/user/consultant/registration/step2') ||
                url.includes('/rest/user/consultant/registration/step2/avatar')
            ) {
                req = req.clone({
                    setHeaders: {
                        Authorization: 'Bearer ' + tokenRegistration
                    }
                });
            }
        }

        return this.handleRequest(next, req);

        // // Check if is first time
        // const reconnecting = self.utilService.localStorageGetItem('reconnecting');
        // // In case the system is trying to reconnect, all the ping request should be process
        // if (reconnecting === 'true' && request.url.includes('ping')) {
        //     return this.handleRequest(next, req);
        // } else if ( reconnecting !== 'true') {
        //     const firstTime = self.utilService.localStorageGetItem('firstTime');
        //     if (firstTime === 'true') {
        //         return this.queueService.intercept(req, next);
        //     } else {
        //         // const key = makeStateKey(req.urlWithParams);
        //         // const storedResponse: string = this.transferState.get(key, null);
        //         // if (storedResponse) {
        //         //     const response = new HttpResponse({ body: storedResponse, status: 200 });
        //         //     return of(response);
        //         // }
        //         return this.handleRequest(next, req);
        //     }
        // } else {
        //     return EMPTY;
        // }
    }

    private handleRequest(next: HttpHandler, req: HttpRequest<any>) {
        const self = this;
        return next.handle(req).pipe(
            // timeout(this.timeoutValueNumeric),
            tap(async (event: HttpEvent<any>) => {

                if (event instanceof HttpResponse) {
                    if (!event.url.includes('ping')) {
                            // Check header x-currentuserid
                            // const xcurrentuserid = event.headers.get('x-currentuserid');

                            // // console.og('xcurrentuserid', xcurrentuserid);
                            // const uid = self.utilService.localStorageGetItem('uid');

                            // Check if there is an user logged in angular
                            // const authCookie = this.cookie.get('auth');
                            // if (xcurrentuserid != null) {
                            //     // If user is logged in
                            //     if (uid && uid !== 'null') {
                            //         // Check if xcurrentuserid is none, and I have an user in angular I must re login
                            //         if (xcurrentuserid === 'None' && uid) {
                            //             self.currentUserInvalid++;
                            //             if (self.currentUserInvalid >= 3) {
                            //                 self.sessionDieService.create();
                            //                 self.allEmiterService.onUserLogOut();
                            //             }
                            //
                            //             return;
                            //         }
                            //         if (xcurrentuserid === 'None' && !uid) {
                            //             // Nothing change
                            //             self.currentUserInvalid = 0;
                            //             return;
                            //         }
                            //         // tslint:disable-next-line:triple-equals
                            //         if (xcurrentuserid == uid) {
                            //             // Nothing change, but I check if the user has already logged in (using session storage)
                            //             self.currentUserInvalid = 0;
                            //             return;
                            //         }
                            //         // tslint:disable-next-line:triple-equals
                            //         if (xcurrentuserid != uid) {
                            //             // I must reload and show new user
                            //             // It would reload the page all the time
                            //             // self.allEmiterService.onUserLogOut();
                            //             // self.window.location.href = '';
                            //             // return;
                            //
                            //         }
                            //     } else {
                            //         if ( xcurrentuserid !== 'None') {
                            //             self.currentUserInvalid++;
                            //             if (self.currentUserInvalid >= 3) {
                            //                 self.sessionDieService.create();
                            //                 self.allEmiterService.onUserLogOut();
                            //             }
                            //             // await self.userService.getDetails().toPromise();
                            //             // self.window.location.reload();
                            //         }
                            //     }
                            //
                            // }
                    }
                }
            }, (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    console.error('Error web service', err.url, err.status, err.statusText, err.name);
                    // https://lifereader.sentry.io/issues/5946745471/events/0e9dfc4cd1054600a51ba07589556d1a/
                    // Add better logs
                    Sentry.addBreadcrumb({message: 'Error web service', data: {req} });
                    if (err.status === 401) {
                        // Token not valid
                        this.allEmiterService.onUserLogOut();
                        // this.window.location.reload();

                        return throwError(err);
                    } else {
                        // Check if system is trying to reconnect to the backEnd
                        const reconnecting = self.utilService.localStorageGetItem('reconnecting');
                        if (reconnecting !== 'true') {
                            this.handleHttpError(err);
                            return throwError(err);
                        }
                    }
                } else {
                    if (this.showErrorMessages) {
                        if (err.name === 'TimeoutError' ) {
                            this.alertService.alert({
                                title: `Network error`,
                                body: `Tried to load '${req.urlWithParams}' 1 times without success`,
                                type: 'error',
                            });
                        } else {
                            this.alertService.alert({
                                title: `Web Service error`,
                                body: `Error with  '${req.urlWithParams}`,
                                type: 'error',
                            });
                        }
                    }
                    this.handleHttpError(err);
                    return throwError(err);
                }
            }),
        );
    }

    private handleHttpError(error: Error | HttpErrorResponse | Error) {
        let message;
        let stackTrace;
        if (error instanceof HttpErrorResponse) {
            // Server Error
            message = this.errorService.getServerMessage(error);
            stackTrace = error;
        } else {

            // Client Error
            message = this.errorService.getClientMessage(error);
            stackTrace = this.errorService.getClientStack(error);
        }

        // this.logger.error(message, stackTrace);
    }

}

