import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Reader} from '../../entity/reader';
import {StartCallResponse} from '../../entity/start-call-response';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {UserService} from '../../../../services/user.service';
import {SubSink} from 'subsink';
import {AccountService} from '../../../consultant/services/account.service';
import {Subscriber} from 'rxjs';
// import * as Sentry from '@sentry/angular';
@Component({
    selector: 'shared-gift-shade',
    templateUrl: './gift-shade.component.html',
    styleUrls: ['./gift-shade.component.scss'],
})
export class GiftShadeComponent implements OnInit, OnDestroy {
    loginModalRef: BsModalRef;
    registerModalRef: BsModalRef;
    @Input()
    reader: Reader;
    role = '';
    configCall: StartCallResponse;
    doRegisterSubscriber: Subscriber<any>;
    doSignInSubscriber: Subscriber<any>;
    defaultPhone: String;
    private subs = new SubSink();

    chatModal;
    open = false;
    value: any = '';

    constructor(
        private accountService: AccountService,
        public userService: UserService) {
    }

    ngOnInit() {
        try {
            this.value = this.reader.giftCard.value;
        } catch (e) {

        }
    }

    doClose() {
        this.open = false;
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
