import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterModule, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {AllEmiterService} from '../services/all-emiter.service';
import {map} from 'rxjs/operators';
import {UserService} from '../services/user.service';
import {ReaderService} from '../modules/shared/services/reader-service';

@Injectable({
    providedIn: 'root',
})
export class GeneralResolver implements Resolve<Observable<any>> {


    url: string;
    constructor(
        private userService: UserService,
        private readerService: ReaderService,
        private route: ActivatedRoute,
        private router: RouterModule,
        private allEmiterService: AllEmiterService,
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
        this.allEmiterService.onWaitChange(true);
        const self = this;
        self.url = state.url;
        return this.userService.ping().pipe(
            map((userDetails) => {
                this.allEmiterService.onWaitChange(false);
                return userDetails;
            })
        );
    }

}
