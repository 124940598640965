<ng-template [ngIf]="!list">
    <div *ngIf="!onlyAvatar" [ngClass]="{'vertical':vertical}" class="reader-snippet">
        <shared-gift-shade [reader]="reader" *ngIf="reader.giftCard"></shared-gift-shade>
        <div [ngClass]="vertical?'col-sm-12 ava-part':'ava-part-hor col-sm-4 col-md-3'" class="padding-zero">
            <div (click)="doOpenSade()" *ngIf="reader.giftCard" class="gift pointer">
                <div class="fa fa-gift"></div>
                <div class="message">Click to see your Gift!</div>
            </div>
            <div class="visible-xs mini-status" [ngClass]="reader.giftCard? 'title-gift-card': ''">
                <div class="head-shadow head-section">
                    <div class="left">
                        <div class="share-status-1 sor{{sor}}">
                        <shared-online [type]="1" [data]="reader" [short]="true"></shared-online>
                        <shared-premier [type]="1" [data]="reader" [full]="false" style="display: inline-block"></shared-premier>
                        <a class="name" routerLink={{navigateLink}}>{{name}}</a>
                        </div>
                    </div>
                    <div class="right">
                        <div class="share-status-2 sor{{sor}}">
                            <shared-favourite [type]="1" [full]="false" [reader]="reader"></shared-favourite>
                        </div>
                    </div>
                </div>
            </div>

            <a routerLink={{navigateLink}}>
                <img *ngIf="reader" [src]="br.backendImageUrl + '/images/photo/' + reader.photoOrig"
                     alt="" class="img-responsive avatar-list-view" width="300" height="210">
                <img *ngIf="!reader" [src]="br.backendImageUrl + '/images/photo/noBody.jpg'" alt=""
                     class="img-responsive avatar-list-view" width="300" height="210">
                <div class="hidden-xs sign">{{reader.zodiac}}</div>
            </a>
            <div class="visible-xs sign major-actions-section">
                <div class="prices">
                <span>
                  {{reader.getCurrencySymbol()}}
                    {{reader.getCallRate()|currency:'symbol':''}}
                    /min*
                </span>
                    <span *ngIf="reader.freeChatMinutes>0 || !userIsLoggedIn">
                        Chat: 4 free min
                    </span>
                </div>
                <div class="buttons-section">
                    <ng-container *ngIf="reader.available">
                        <shared-call-button [reader]="reader" [role]="customerRole" [type]="'-readerCard1'"></shared-call-button>
                        <shared-book-button [reader]="reader" [role]="customerRole" [type]="'-readerCard1'"></shared-book-button>
                        <shared-chat-button [reader]="reader" [role]="customerRole" [type]="'-readerCard1'"></shared-chat-button>
                        <shared-busy-button [reader]="reader" [role]="customerRole" [type]="'-readerCard1'"></shared-busy-button>
                    </ng-container>
                    <ng-container *ngIf="!reader.available">
                        <shared-notify-button [reader]="reader" [role]="customerRole" [type]="'-readerCard1'"></shared-notify-button>
                    </ng-container>
                </div>
            </div>
        </div>
        <div [ngClass]="vertical?'col-sm-12 info-part':'info-part-hor col-sm-8 col-md-9'"
             class="padding-zero hidden-xs">
            <div class="top-info">
                <div class="status-bar">
                </div>
                <div class="hidden-xs block-under-photo">
                    <div class="header-left left-column col-sm-7 col-lg-7" [ngClass]="!vertical ? 'col-md-7 padding-zero': 'col-md-12'">
                        <div class="share-status-3 sor{{sor}}">
                            <shared-online [type]="2" *ngIf="vertical" [data]="reader"
                                           [short]="true"></shared-online>
                            <shared-premier [type]="2"  *ngIf="vertical" [data]="reader" [full]="false"></shared-premier>
                            <shared-favourite [type]="2" *ngIf="vertical" [full]="false" [reader]="reader"></shared-favourite>

                        </div>
                        <div class="share-status-4 sor{{sor}}">
                            <shared-favourite [type]="3" *ngIf="!vertical" [full]="false" [reader]="reader"
                                              class="hidden-sm hidden-md hidden-lg"></shared-favourite>
                            <shared-online [type]="3" *ngIf="!vertical" [data]="reader" [short]="true"
                                           class="visible-sm visible-md visible-xs"></shared-online>
                            <shared-premier [type]="3" *ngIf="!vertical" [data]="reader" [full]="false"
                                            class="visible-sm visible-md visible-xs"></shared-premier>
                        </div>

                        <div style="float:left;overflow: hidden" class="name-container{{sor}}">
                            <a class="name" routerLink={{navigateLink}}>{{name}}</a>
                        </div>

                    </div>
                    <div class="visible-xs visible-sm" >
                        <div class="share-status-5 sor{{sor}}">
                        <shared-online [type]="4" *ngIf="!vertical" [data]="reader" [short]="false"
                                       class="hidden-sm hidden-md"></shared-online>
                        <shared-premier [type]="4" *ngIf="!vertical" [data]="reader" [full]="true"
                                        class="hidden-sm hidden-md"></shared-premier>
                        <shared-favourite class="hidden-md hidden-lg" [type]="4" *ngIf="!vertical" [full]="true" [reader]="reader"></shared-favourite>

                        </div>
                    </div>
                    <div class="header-right right-column col-sm-5 col-lg-5 padding-zero hidden-xs hidden-sm" [ngClass]="!vertical ? 'col-md-5': 'col-md-0'">
                        <div class="share-status-6 sor{{sor}}">
                        <shared-online [type]="5" *ngIf="!vertical" [data]="reader" [short]="false"
                                       class="hidden-sm hidden-md"></shared-online>
                        <shared-premier [type]="5" *ngIf="!vertical" [data]="reader" [full]="true"
                                        class="hidden-sm hidden-md"></shared-premier>
                            <!--<shared-favourite [type]="2" *ngIf="vertical" [full]="false" [reader]="reader"-->
                                              <!--class="visible-md"-->
                            <!--&gt;</shared-favourite>-->
                        <shared-favourite [type]="5" *ngIf="vertical" [full]="true" [reader]="reader"
                                          class="visible-lg"></shared-favourite>
                        <div class="share-status-7 sor{{sor}}">
                            <shared-favourite [type]="11" *ngIf="!vertical" [full]="true"
                                              [reader]="reader"
                                              class="visible-sm visible-md hidden-xs hidden-sm visible-lg"></shared-favourite>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="hidden-xs major-info">
                <div [ngClass]="vertical?'col-sm-12':'col-sm-12 col-md-7 col-lg-7'" class="first-info padding-zero">
                    <div class="brief-info{{type}}">
                        {{getDesc()}}
                    </div>
                    <ul class="list-unstyled reader-tag-list">
                        <li *ngFor="let tag of reader.verbs" class="reader-tag">
                            {{tag}}
                        </li>
                    </ul>
                </div>
                <div [ngClass]="vertical?'col-sm-12':'col-sm-12 col-md-5 col-lg-5'" class="second-info padding-zero">
                    <div class="row">
                        <ul [ngClass]="vertical ? 'vertical-list-styled' : ''" class="list-unstyled info-list">
                            <li class="info-item col-sm-3">
                                <div class="info-title">Readings</div>
                                <div class="info-number">{{reader.readingsNumber | number:'1.0':'en-US'}}</div>
                            </li>
                            <li class="info-item col-sm-3">
                                <div class="info-title">Since</div>
                                <span class="info-number">{{reader.since|date:'yyyy'}}</span>
                            </li>
                            <li class="info-item col-sm-3">
                                <div class="info-title">Reviews</div>
                                <div class="info-number">{{reader.consultantRating.reviews}}</div>
                            </li>
                            <li class="info-item col-sm-3">
                                <div class="info-title">Positive</div>
                                <div class="info-number">{{reader.consultantRating.percentageRating}}%</div>
                            </li>
                        </ul>
                    </div>
                    <div [ngClass]="vertical?'text-right':'text-left'" class="major-actions">
                        <ng-container *ngIf="reader.available">
                            <shared-call-button [reader]="reader" [role]="customerRole" [type]="'-readerCard2'"></shared-call-button>
                            <shared-book-button [reader]="reader" [role]="customerRole" [type]="'-readerCard2'"></shared-book-button>
                            <shared-chat-button [reader]="reader" [role]="customerRole" [type]="'-readerCard2'"></shared-chat-button>
                            <shared-busy-button [reader]="reader" [role]="customerRole" [type]="'-readerCard2'"></shared-busy-button>
                        </ng-container>
                        <ng-container *ngIf="!reader.available">
                            <shared-notify-button [reader]="reader" [role]="customerRole" [type]="'-readerCard2'"></shared-notify-button>
                        </ng-container>
                    </div>
                    <div [ngClass]="vertical?'text-right':'text-left'" class="actions-description">
                        <div class="reading-price">
                            {{reader.getCurrencySymbol()}}
                            {{reader.getCallRate()|currency:'symbol':''}}
                            /min*
                        </div>
                        <span *ngIf="reader.freeChatMinutes>0 || !userIsLoggedIn" class="special-chat-offer">Chat: 4 free min</span>
<!--                        <span *ngIf="!reader.freeChatMinutes>0 || userIsLoggedIn" class="special-chat-offer no-visible">No </span>-->
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
    <div *ngIf="onlyAvatar" [ngClass]="{'vertical':vertical}" class="reader-snippet">
        <shared-gift-shade [reader]="reader" *ngIf="reader.giftCard"></shared-gift-shade>
        <div [ngClass]="vertical?'col-sm-12 ava-part':'ava-part-hor col-sm-4 col-md-3'" class="padding-zero">
            <div class="mini-status">
                <div class="head-shadow head-section">
                    <div class="share-status-8 sor{{sor}}">
                        <div class="left">
                            <shared-online [type]="6" [data]="reader"
                                           [short]="true"></shared-online>
                            <shared-premier [type]="6" [data]="reader" [full]="false" style="display: inline-block"></shared-premier>
                            <span class="name" routerLink={{navigateLink}}>{{name}}3</span>
                        </div>
                        <div class="right">
                            <shared-favourite [type]="6" [full]="false" [reader]="reader"></shared-favourite>
                        </div>
                    </div>
                </div>
            </div>
            <div (click)="doOpenSade()" *ngIf="reader.giftCard" class="gift pointer">
                <div class="fa fa-gift"></div>
                <div class="message">Click to see your Gift!</div>
            </div>
            <a routerLink={{navigateLink}}>
                <!--                <img  class="img-responsive" [src]="getAvatar()" alt="">-->
                <img *ngIf="reader" [src]="br.backendImageUrl + '/images/photo/' + reader.photoOrig"
                     alt="" class="img-responsive avatar-list-view" width="300" height="210">
                <img *ngIf="!reader" [src]="br.backendImageUrl + '/images/photo/noBody.jpg'" alt=""
                     class="img-responsive avatar-list-view" width="300" height="210">
                <!--<img [src]="getAvatar()" class="img-responsive" alt="">-->
            </a>
            <div class="sign major-actions-section">
                <div class="prices">
                <span>
                  {{reader.getCurrencySymbol()}}
                    {{reader.getCallRate()|currency:'symbol':''}}
                    /min*
                </span>
                    <span *ngIf="reader.freeChatMinutes>0 || !userIsLoggedIn">
                      Chat: 4 free min
                    </span>
<!--                    <span *ngIf="!reader.freeChatMinutes>0 || userIsLoggedIn" class="no-visible">-->
<!--                        No-->
<!--                    </span>-->
                </div>
                <div class="buttons-section">
                    <ng-container *ngIf="reader.available">
                        <shared-call-button [reader]="reader" [role]="customerRole" [type]="'-readerCard3'"></shared-call-button>
                        <shared-book-button [reader]="reader" [role]="customerRole" [type]="'-readerCard3'"></shared-book-button>
                        <shared-chat-button [reader]="reader" [role]="customerRole" [type]="'-readerCard3'"></shared-chat-button>
                        <shared-busy-button [reader]="reader" [role]="customerRole" [type]="'-readerCard3'"></shared-busy-button>
                    </ng-container>
                    <ng-container *ngIf="!reader.available">
                        <shared-notify-button [reader]="reader" [role]="customerRole" [type]="'-readerCard3'"></shared-notify-button>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
</ng-template>

<ng-template [ngIf]="list==='header'">

    <div class="reader-item-list">
        <div class="info-top-list">
            <div>
                <shared-gift-shade [reader]="reader" *ngIf="reader.giftCard"></shared-gift-shade>
            </div>
            <a routerLink={{navigateLink}}>
                <!--                <img class="img-responsive ava-part-list" [src]="getAvatar()" alt="">-->
                <img *ngIf="reader" [src]="br.backendImageUrl + '/images/photo/' + reader.photoOrig"
                     alt="" class="img-responsive avatar-list-view" width="300" height="210">
                <img *ngIf="!reader" [src]="br.backendImageUrl + '/images/photo/noBody.jpg'" alt=""
                     class="img-responsive avatar-list-view" width="300" height="210">
                <!--<img [src]="getAvatar()" class="img-responsive ava-part-list" alt="">-->
            </a>
            <div class="info-part">
                <div>
                    <div class="status-bar-list">
                        <div class="share-status-9 sor{{sor}}">
                        <shared-online [type]="7" [data]="reader" [short]="true"></shared-online>
                        <shared-premier [type]="7" [data]="reader" [full]="false" style="display: inline-block"></shared-premier>
                        <shared-favourite [type]="7" [full]="false" [reader]="reader"></shared-favourite>
                        </div>
                    </div>
                    <div class="reader-name">
                        <a class="name-list">{{name}}</a>
                    </div>
                    <div class="price-info-list">
                        <span>
                            {{reader.getCurrencySymbol()}}
                            {{reader.getCallRate()|currency:'symbol':''}}
                            /min*
                        </span>
                    </div>
                    <div class="chat-info-list">
                        <span *ngIf="reader.freeChatMinutes>0 || !userIsLoggedIn">
                            Chat: 4 free min
                        </span>
<!--                        <span *ngIf="!reader.freeChatMinutes>0 || userIsLoggedIn" class="no-visible">-->
<!--                            No-->
<!--                        </span>-->
                    </div>
                </div>
            </div>
            <div class="toggle-info-list">
                <div class="toggle-info-btn">
                    <span [style.display]="showInfo ? 'block':'none'" class="fa fa-angle-up arrow"></span>
                    <span [style.display]="!showInfo ? 'block':'none'" class="fa fa-angle-down arrow"></span>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template [ngIf]="list==='content'">
    <div>
        <div class="first-info">
            <div class="brief-info">
                {{getDesc()}}
            </div>
            <ul class="reader-tag-list">
                <li *ngFor="let tag of reader.verbs" class="reader-tag">
                    {{tag}}
                </li>
            </ul>
        </div>
        <div class="second-info">
            <div class="duplicate-action-btns">
                <a (click)="goToProfile()" class="action-btn">Reader Profile</a>
                <a (click)="addFav()" class="action-btn add-fav-button">Add to Favorites</a>
            </div>
            <ng-container *ngIf="!reader.available">
                <shared-notify-button [reader]="reader" [type]="'-readerCard4'"></shared-notify-button>
            </ng-container>
            <div class="major-action-btns">
                <ng-container *ngIf="reader.available">
                    <shared-call-button [reader]="reader" [role]="customerRole" [type]="'-readerCard4'"></shared-call-button>
                    <shared-book-button [reader]="reader" [role]="customerRole" [type]="'-readerCard4'"></shared-book-button>
                    <shared-chat-button [reader]="reader" [role]="customerRole" [type]="'-readerCard4'"></shared-chat-button>
                    <shared-busy-button [reader]="reader" [role]="customerRole" [type]="'-readerCard4'"></shared-busy-button>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>
