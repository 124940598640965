import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Reader} from '../../entity/reader';
// import {CallModalComponent} from '../../components/modals/call-modal/call-modal.component';
// import {RegistrationComponent} from '../../components/registration/registration.component';
import {UserService} from '../../../../services/user.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ReaderService} from '../../services/reader-service';
import {NGXLogger} from 'ngx-logger';
import {StartCallResponse} from '../../entity/start-call-response';
import {Subscriber} from 'rxjs';
// import {LoginFormComponent} from '../../components/login-form/login-form.component';
// import {PaymentsModalComponent} from '../../components/modals/payments-modal/payments-modal.component';
import {PromotiosResolverService} from '../../../../resolvers/promotios-resolver.service';
import {AccountService} from '../../../consultant/services/account.service';
import {Phone} from '../../../consultant/entity/phone';
import {SubSink} from 'subsink';
import {AlertService} from '../../../../services/alert.service';
// import * as Sentry from '@sentry/browser';
import {environment} from '../../../../../environments/environment';
import {GoogleAnalyticsEventsService} from '../../../../services/google-analytics-events.service';
import {UserDetails} from '../../entity/user-details';
import {ApplicationStateService} from '../../../../services/application.state.service';
import {UtilService} from '../../../../services/util.service';

@Component({
    selector: 'shared-call-button',
    templateUrl: './call-button.component.html',
    styleUrls: ['./call-button.component.scss'],
})
export class CallButtonComponent implements OnInit, OnDestroy {
    loginModalRef: BsModalRef;
    registerModalRef: BsModalRef;
    @Input()
    reader: Reader;
    /**
     * It is used to determinate where the component is used
     */
    @Input()
    type: any;

    @Input()
    role = '';
    configCall: StartCallResponse;
    doRegisterSubscriber: Subscriber<any>;
    doSignInSubscriber: Subscriber<any>;
    defaultPhone: String;
    private subs = new SubSink();

    constructor(
        private accountService: AccountService,
        private userService: UserService,
        private modalService: BsModalService,
        private readerService: ReaderService,
        private logger: NGXLogger,
        private promotiosService: PromotiosResolverService,
        private bsModalService: BsModalService,
        private alertService: AlertService,
        private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
        public applicationStateService: ApplicationStateService,
        private utilService: UtilService,
        private cd: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
    }

    async doCall() {
        if (!this.applicationStateService.isOnCall) {
            this.applicationStateService.isOnCall = true;
            this.cd.markForCheck();
            const any: any = await this.userService.checkRegistration();
            if (any !== true) {
                this.readerService.saveSelectedReaderLocalStorage(this.reader.id);
                this.subs.sink = this.doSignInSubscriber = any.content.doSignIn.subscribe(async () => {
                    await this.doLogin();
                });
                await this.googleAnalyticsEventsService.visitorReadingAttempt(this.reader.id, 'call');
                this.applicationStateService.isOnCall = false;
                this.cd.markForCheck();
                return;
            }
            const data: UserDetails = await this.userService.getDetails().toPromise();

            if (data && data.blocked === 'UNBLOCKED') {
                if (data.role === 'ROLE_CUSTOMER') {
                    await this.googleAnalyticsEventsService.customerReadingAttempt(this.reader.id, 'call');
                }
                const self = this;
                if (await this.userService.checkRegistration() !== true) {
                    this.applicationStateService.isOnCall = false;
                    this.cd.markForCheck();
                    return;
                }
                // Check if is first time user is going to add funds
                if (data.fullBalance < this.reader.chatRates.chatRate) {
                    if (await this.userService.firstTimeAddingFunds() === false) {
                        this.applicationStateService.isOnCall = false;
                        this.cd.markForCheck();
                        return;
                    }
                }
                const payg = data.paygOn;
                if (data.blocked === 'UNBLOCKED') {
                    if (data.fullBalance < this.reader.callRates.callRate && !data.paygOn) {
                        await this.userService.showAddFunds('Do Call');
                        this.applicationStateService.isOnCall = false;
                        this.cd.markForCheck();
                        return;
                    }

                    this.subs.sink = this.readerService.startCost({
                        readerId: this.reader.id,
                        readingType: 'Phone',
                    }).subscribe((res: StartCallResponse) => {
                        this.configCall = res;
                        this.applicationStateService.isOnCall = false;
                        this.cd.markForCheck();
                        // tslint:disable-next-line:no-shadowed-variable
                        this.subs.sink = this.accountService.getPhones().subscribe(async (data: Phone[]) => {
                            data.forEach((phone) => {
                                if (phone.defaultPhone === true) {
                                    this.defaultPhone = phone.number;
                                }
                            });
                            const component: any = await import('../../components/modals/call-modal/call-modal.component').then(cp => cp.CallModalComponent);
                            this.modalService.show(component, {
                                animated: false,
                                class: 'modal-sm',
                                initialState: {phone: this.defaultPhone, reader: this.reader, configCall: this.configCall, payg},
                                backdrop: true,
                                keyboard: true
                            });

                        });

                    }, (error1: any) => {
                        this.applicationStateService.isOnCall = false;
                        this.cd.markForCheck();
                        if (error1.error.code === '400106') {
                        }
                    });
                }
            } else {
                this.applicationStateService.isOnCall = false;
                this.cd.markForCheck();

                this.alertService.alert({
                    title: ``,
                    body: 'We are sorry but no readings are possible at this time. Please contact support.',
                    type: 'info',
                });
            }
        }



    }

    async doLogin() {

        // https://lifereader.atlassian.net/browse/LA-1021
        const component: any = await import('../../components/login-form/login-form.component').then(cp => cp.LoginFormComponent);
        this.loginModalRef = this.modalService.show(component, {
            animated: false,
            backdrop: true,
            keyboard: false
        });
        this.subs.sink = this.doRegisterSubscriber = this.loginModalRef.content.doRegister.subscribe(async () => {
            await this.doRegister();
        });
    }

    async doRegister() {
        const component: any = await import('../../components/registration/registration.component').then(cp => cp.RegistrationComponent);
        this.registerModalRef = this.modalService.show(component, {
            animated: false,
            class: 'modal-dialog-steps',
            backdrop: true,
            keyboard: true
        });
        this.subs.sink = this.doSignInSubscriber = this.registerModalRef.content.doSignIn.subscribe(async () => {
            await this.doLogin();
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
