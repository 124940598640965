import {Currency} from './currency';

export class Config {
    domainId: number;
    langCode: string;
    gdprComplianceRequired: boolean;
    currency: Currency;
    callSpecialRate: number;
    payPalMode: string;
}
