import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HoroscopeService} from '../../modules/horoscope/services/horoscope.service';
import {AccountService} from '../../modules/consultant/services/account.service';
import {AbstractControl, FormBuilder, Validators} from '@angular/forms';
import {ServerErrors} from '../../modules/shared/helpers/server-errors';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {FormHelper} from '../../modules/shared/helpers/form.helper';
import {NGXLogger} from 'ngx-logger';
import {AuthService} from '../../services/auth.service';
import {AlertService} from '../../services/alert.service';
import {SubSink} from 'subsink';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {ConsultantAvatarUpload} from '../../modules/shared/entity/consultant-avatar-upload';
import {DOCUMENT} from '@angular/common';
import {UtilService} from '../../services/util.service';
import {GoogleAnalyticsEventsService} from '../../services/google-analytics-events.service';

@Component({
    selector: 'app-reader-registration-step2',
    templateUrl: './reader-registration-step2.component.html',
    styleUrls: ['./reader-registration-step2.component.scss'],
})
export class ReaderRegistrationStep2Component extends ServerErrors implements OnInit, OnDestroy {
    signs;
    languages;
    countries;
    categories;
    rates;
    selectedRate = 0;
    filesUploaded;
    fileUploaded = false;
    finished = false;
    private subs = new SubSink();

    @ViewChild('autosize')
    autosize: CdkTextareaAutosize;

    constructor(
        private hs: HoroscopeService,
        private accountService: AccountService,
        private fb: FormBuilder,
        private authService: AuthService,
        private logger: NGXLogger,
        private alertService: AlertService,
        @Inject(DOCUMENT) private document: Document,
        private utilService: UtilService,
        private gAE: GoogleAnalyticsEventsService,
    ) {
        super();
    }

    ngOnInit() {
        const countries = this.accountService.getCountries();
        const languages = this.accountService.getLanguages();
        const categories = this.accountService.getCategories();
        const rates = this.accountService.getRates();

        this.subs.sink = forkJoin([countries, languages, categories, rates])
            .subscribe((res) => {
                [
                    this.countries,
                    this.languages,
                    this.categories,
                    this.rates,
                ] = res;
                this.buildForm();
            });

        this.subs.sink = this.hs.getSigns().subscribe((res) => {
            this.signs = res;
        });
    }

    buildForm() {
        this.form = this.fb.group({
            shortDescription: ['q', [Validators.required]],
            aboutMe: ['', [Validators.required]],
            experience: ['', [Validators.required]],
            primaryLanguageId: [1, [Validators.required]],
            zodiak: [1, [Validators.required]],
            otherLang: [],
            rateId: [this.selectedRate + 1],
            searchTags: [],
            callAvailable: [true, [Validators.required]],
            chatAvailable: [true, [Validators.required]],
            homePhone: ['', [Validators.required]],
            homePhoneCountry: [1, [Validators.required]],
            workPhone: [],
            workPhoneCountry: [''],
            mobilePhone: [],
            mobilePhoneCountry: [''],
        });
    }

    doSubmit() {
        const self = this;
        const categoriesCheckMap = {};
        this.categories.forEach((category) => {
            categoriesCheckMap[category.id] = [];
            category.subCategories.forEach((subCat) => {
                if (subCat.selected) {
                    categoriesCheckMap[category.id].push({id: subCat.id, checked: true});
                    // categoriesCheckMap[subCat.id] = true;
                }
            });
        });

        FormHelper.touchedForm(this.form);
        if (!this.form.valid) {
            console.error('Form not valid');
            console.error(this.form.controls);
            return;
        }

        const data = Object.assign({}, this.form.value);
        data.rateId = this.selectedRate + 1;
        data.categoriesCheckMap = categoriesCheckMap;
        // data.categoriesCheckMap = this.categories;

        this.subs.sink = this.authService.registrationReaderStep2(data).subscribe(async (res) => {
            self.utilService.localStorageRemoveItem('registration');
            self.utilService.localStorageRemoveItem('token-registration');
            this.finished = true;
            await this.gAE.readerRegistrationFinish(self.utilService.localStorageGetItem('uid'));
        }, (res) => {
            self.utilService.localStorageRemoveItem('registration');
            if (res.error) {
                if(res.error.additionalFields) {
                    Object.entries(res.error.additionalFields).forEach(([key, value]) => {
                        this.setServerError(key, value);
                    });
                }
                if (res.error.message) {
                    this.alertService.alert({
                        title: '',
                        body: res.error.message,
                        type: 'error',
                    });
                }
            }
        });
    }

    getPhoneCode(field) {
        if (!this.form.controls[field].value) {
            return;
        }
        return this.getCountry(this.form.controls[field].value).phoneCode;
    }

    getCountry(id) {
        return this.countries.find(el => el.id === id);
    }

    getRatesInCurrencies() {
        return this.rates.callRates[0].ratesInCurrencies.map(item => item.currencySign);
    }

    getRate(key) {
        const priceRate = ['Low', 'Medium', 'Normal', 'Premium', 'Ultimate'];
        return priceRate[key];
    }

    getRatesMenu() {
        return ['Aggressive', 'Competitive', 'Regular', 'Premium', 'Ultimate'];
    }

    selectRate(index) {
        this.selectedRate = index;
    }

    fileChange($event) {
        const self = this;
        const preview: any = self.document.getElementById('previewProfile');
        this.filesUploaded = $event.target.files[0];
        const reader = new FileReader();
        reader.addEventListener('load', function () {
            preview.src = reader.result;
        }, false);

        if (this.filesUploaded) {
            reader.readAsDataURL(this.filesUploaded);
        }
        const formData = new FormData();

        if (this.filesUploaded) {
            formData.append('file', this.filesUploaded);
            this.subs.sink = this.authService.registrationReaderAvatar(formData)
                .subscribe((res: ConsultantAvatarUpload) => {
                        self.fileUploaded = true;
                        self.utilService.localStorageRemoveItem('registration');
                        this.alertService.alert({
                            title: `Image upload successful`,
                            body: res.fileName + ` was successfully uploaded`,
                            type: 'info',
                        });

                        if (res.height !== res.width) {
                            this.alertService.alert({
                                title: `Image is non-square!`,
                                body: `Dimension of image are (WxH):` + res.width + `x` + res.height + `. The image shown is resized to 140x140 pixels and shows what your image will look like on the website`,
                                type: 'info',
                            });
                        }
                    },
                    (err) => {
                        this.alertService.alert({
                            title: `Error Avatar`,
                            body: `Please try later`,
                            type: 'error',
                        });
                    });
        }
    }

    validatePhoneNumber(countryCode: string, formControl: AbstractControl) {

        countryCode = countryCode.replace('+', '');
        let phoneNumber = formControl.value;
        if (phoneNumber) { // Remove + symbols
            phoneNumber = phoneNumber.replace('+', '');
            phoneNumber = phoneNumber.replace(' ', '');
            // If country code is at the begging of the phone number should be remove (64)
            if (phoneNumber.substr(0, countryCode.length) === countryCode) {
                phoneNumber = phoneNumber.substr(countryCode.length);
            }
            // Same previous validation but we add an 0 to the country code (064)
            countryCode = '0' + countryCode;
            if (phoneNumber.substr(0, countryCode.length) === countryCode) {
                phoneNumber = phoneNumber.substr(countryCode.length);
            }
            // Same previous validation but we add an 0 to the country code (0064)
            countryCode = '00' + countryCode;
            if (phoneNumber.substr(0, countryCode.length) === countryCode) {
                phoneNumber = phoneNumber.substr(countryCode.length);
            }
            formControl.setValue(phoneNumber);
        }

    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
