import {Rate} from '../../../entity/rate';
import {Type} from 'class-transformer';
import {ConsultantRating} from './consultant-rating';

export class Reader {
    id: number;
    nickname: string;
    photoOrig: string;
    available: boolean;
    busy: boolean;
    logged: boolean;
    readingsNumber: number;
    userPhoneOnlineState: number;
    userChatOnlineState: number;
    userEmailOnlineState: number;
    userPhoneOnlineStatusBusy: boolean;
    zodiac: string;
    alert: string;
    shortDescr: string;
    rateId: number;
    @Type(() => Rate)
    callRates: Rate;
    @Type(() => Rate)
    chatRates: Rate;
    @Type(() => ConsultantRating)
    consultantRating: ConsultantRating;
    since: number;
    verbs: string[];
    premier: boolean;
    favorite: boolean;
    giftCard: {
        endTime: number,
        id: number,
        value: number
    };
    freeChatMinutes: number;
    about: string;
    aboutHtml: any;
    qualificationHtml: string;


    constructor() {
    }

    getCurrencySymbol() {
        if (!this.callRates) {
            return;
        }
        return this.callRates.currency.asciiSymbol;
    }

    getCallRate() {
        if (!this.callRates) {
            return 0;
        }
        return this.callRates.callRate;
    }
}
