import {Rate} from './rate';
import {Type} from 'class-transformer';

export class Consultant {
    available: boolean; // true
    busy: boolean; // false

    @Type(() => Rate)
    callRates: Rate;

    @Type(() => Rate)
    chatRates: Rate;

    favorite: false;
    id: number;
    logged: false;
    nickname: string; // "TEST READER PLEASE DO NOT CONNECT";
    photoOrig: string; // "psychic_reader_TEST_READER_PLEASE_DO_NOT_CONNECT_orig.jpg"
    premier: false;
    rateId: number;
    userChatOnlineState: number;
    userEmailOnlineState: number;
    userPhoneOnlineState: number;
    userPhoneOnlineStatusBusy: false;
}

