<div class="premier-container premier-container-{{type}}">
<ng-container *ngIf="full && visible">
    <span class="fa fa-diamond premier-icon premier-icon-{{type}}"></span>
    <p class="premier-text hidden-xs premier-text-{{type}}">Premier Reader</p>
</ng-container>
<ng-container *ngIf="!full && visible">
        <span class="fa fa-diamond minimal premier-icon2 premier-icon-{{type}}"></span>
</ng-container>
<ng-container *ngIf="!visible">
    <div class="no-visible-premium-{{type}}"></div>
</ng-container>
</div>
