/* tslint:disable:triple-equals */
import {Inject, Pipe, PipeTransform, PLATFORM_ID} from '@angular/core';
// import * as _h from 'he';
import {BAD_REQUEST_ERR_MSG, DEEP_ARG} from './config';
import {isPlatformBrowser} from '@angular/common';
@Pipe({name: 'unescape', pure: false})
export class UnescapePipe implements PipeTransform {
    optionsObject: any = null;
    private isBrowser: boolean = isPlatformBrowser(this.platformId);
    constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    }

    /**
     * Pipe method to HTML escape. Use method it(string, number|string, object) instead.
     * @param {string} value
     * @param {string | number} arg
     * @return {string} HTML escaped value
     */
    transform(value: any, arg?: number | string): string {
        if (value === null || value === undefined) {
            throw new Error(`The input value to the pipe must be a string or a string convertible value like number, booleans, etc.`);
        }

        return this.it(value.toString(), arg);
    }

    /**
     * Unescapes a string of escaped html values.
     * @param {string} value
     * @param {boolean | number} arg
     * @return {string}
     */
    it(value: any, arg?: string | number, options?: any) {
        if (value === null || value === undefined) {
            throw BAD_REQUEST_ERR_MSG;
        }
        if (options !== undefined && typeof options !== 'object') {
            throw BAD_REQUEST_ERR_MSG;
        }
        value = value.toString();

        if (arg === undefined || arg === null) {
            return this.unescapeString(value);
        }
        if (typeof arg == 'number') {
            return this.uptoLevel(value, arg);
        }
        if (typeof arg == 'string') {
            if (arg !== DEEP_ARG) {
                throw BAD_REQUEST_ERR_MSG;
            }

            return this.inDeepMode(value);
        }
        if (typeof options == 'object' || (typeof arg == 'object' && typeof options === 'undefined')) {
            this.optionsObject = options || arg;

            return this.unescapeString(value);
        }

        throw BAD_REQUEST_ERR_MSG;
    }

    /**
     * Unescapes HTML entities from a string.
     * @param {string} rawInput
     * @return {string}
     */
    unescapeString(rawInput: string): string {
        return this.optionsObject !== undefined &&
        // this.optionsObject !== null ? _h.unescape(rawInput, this.optionsObject) : _h.unescape(rawInput);
        this.optionsObject !== null ? this.htmlDecode(rawInput, this.optionsObject) : this.htmlDecode(rawInput);
    }

    /**
     * Performs HTML unescaping, levels number of times.
     * @param {string} rawInput
     * @param {boolean | number} levels
     * @return {string}
     */
    uptoLevel(rawInput: string, levels: number): string {
        let unescapedString = rawInput;
        while (levels--) {
            unescapedString = this.unescapeString(unescapedString);
        }

        return unescapedString;
    }

    /**
     * Performs HTML unescaping untill the string is fully Unescaped.
     * @param {string} value
     * @return {string}
     */
    inDeepMode(rawInput: string): string {
        // let unescaped: string = _h.unescape(rawInput);
        let unescaped: string = this.htmlDecode(rawInput);

        while (unescaped.length !== rawInput.length) {
            rawInput = unescaped;
            // unescaped = _h.unescape(rawInput);
            unescaped = this.htmlDecode(rawInput);
        }

        return unescaped;
    }

    htmlDecode(input, opt = null) {
        if (this.isBrowser) {
            const doc = new DOMParser().parseFromString(input, 'text/html');
            return doc.documentElement.textContent;
        } else {
            return '';
        }

    }
}
