import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {plainToClass} from 'class-transformer';
import {HoroscopeSign} from '../entity/horoscope-sign';
import {ContentHoroscope} from '../entity/content-horoscope';
import {Observable} from 'rxjs/internal/Observable';
import {Content} from '../entity/content';
import {BackRouter} from '../../shared/services/back-router';
import {UtilService} from '../../../services/util.service';
import {ReplaySubject} from 'rxjs';

export const HOROSCOPE_SIGNS = 'horoscope-signs';

@Injectable({
    providedIn: 'root',
})
export class HoroscopeService {

    public onSigns = new EventEmitter<HoroscopeSign[]>();
    public onContent = new EventEmitter<ContentHoroscope>();
    private signs: HoroscopeSign[] = [];
    private contents = [];

    constructor(
        private httpClient: HttpClient,
        private br: BackRouter,
        private utilService: UtilService,
    ) {
    }

    private signsSubject = new ReplaySubject<HoroscopeSign[]>(1);

    getSigns(): Observable<HoroscopeSign[]> {
        if (this.loadFromLocalStorage()) {
            this.utilService.setTimeout(() => {
                this.signsSubject.next(this.signs);
            });
            return this.signsSubject.asObservable();
        }

        this.makeRequestToSigns();

        return this.signsSubject.asObservable();
    }

    makeRequestToSigns() {
        this.httpClient.get<HoroscopeSign[]>(`${this.br.getJavaWebServicesUrl()}/rest/horoscope/signs`, { headers: { ignoreLoadingBar: '' } })
            .pipe(
                map((objs: Object[]) => objs.map(obj => plainToClass(HoroscopeSign, obj)))
            )
            .toPromise()
            .then((data) => {
                this.signs = data;
                this.saveToLocalStorage();
                this.signsSubject.next(this.signs);
            })
            .catch(error => {
                this.signsSubject.error(error);
            });
    }

    makeRequestToHoroscope(type, sign) {
        this.httpClient.get(this.br.getJavaWebServicesUrl() + `/rest/horoscope/*`,
            {
                params: {
                    type: type, sign: sign
                }
                , headers: { ignoreLoadingBar: '' }
            })
            .pipe(
                map((obj: Object) => {
                        return plainToClass(ContentHoroscope, obj);
                    },
                ),
            ).toPromise()
            .then((data: ContentHoroscope) => {
                const item: Content = {
                    type: type,
                    sign: sign,
                    data: data,
                };
                this.addContent(item);
            });
    }

    addContent(item) {
        const foundIndex = this.contents.findIndex(el => el.type === item.type && el.sign === item.sign);
        if (foundIndex === -1) {
            this.contents.push(item);
            this.onContent.emit(item);
            return;
        }
        this.contents.splice(foundIndex, 1);
        this.contents.push(item);
        this.onContent.emit(item);
    }

    getHoroscope(type, sign): Observable<ContentHoroscope> {
        const self = this;
        const foundHoroscope = this.contents.find((el) => {
            return el.type === type && el.sign === sign;
        });
        if (foundHoroscope) {
            self.utilService.setTimeout(() => {
                this.onContent.emit(foundHoroscope);
            });
            return this.onContent;
        }

        this.makeRequestToHoroscope(type, sign);
        return this.onContent;
    }

    private saveToLocalStorage() {
        const self = this;
        self.utilService.localStorageSetItem(HOROSCOPE_SIGNS, JSON.stringify({
            timestamp: new Date().getTime(),
            data: this.signs,
        }));
    }

    private loadFromLocalStorage() {
        const self = this;
        const string = self.utilService.localStorageGetItem(HOROSCOPE_SIGNS);
        if (!string) {
            return false;
        }
        const obj = JSON.parse(string);
        if ((new Date().getTime() - obj.timestamp) > (24 * 60 * 60)) {
            this.makeRequestToSigns();
        }
        this.signs = obj.data.map(item => plainToClass(HoroscopeSign, item));
        return this.signs;
    }
}
