import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {FeedbackService} from '../../../../../services/feedback.service';
import {NGXLogger} from 'ngx-logger';
import {AlertService} from '../../../../../services/alert.service';
import {SubSink} from 'subsink';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from "@angular/router";

@Component({
    selector: 'app-feedback-after-call-modal',
    templateUrl: './feedback-after-call-modal.component.html',
    styleUrls: ['./feedback-after-call-modal.component.scss'],
})
export class FeedbackAfterCallModalComponent implements OnInit, OnDestroy {
    readingId;
    data = {
        readerRate: 5,
        feedback: '',
    };
    private subs = new SubSink();
    form: FormGroup;
    onChanged = new EventEmitter();
    maxLength = 400;

    constructor(
        public bsModalRef: BsModalRef,
        private feedbackService: FeedbackService,
        private logger: NGXLogger,
        private router: Router,
        private alertService: AlertService,
        public fb: FormBuilder
    ) {
    }

    ngOnInit() {
        this.form = this.fb.group({});
    }

    sendFeedback() {
        if (!this.data.readerRate) {
            return;
        }

        this.subs.sink = this.feedbackService.addFeedback(this.readingId, this.data).subscribe((data) => {
                this.alertService.alert({
                    title: `Feedback added`,
                    body: `Your feedback was sent`,
                    type: 'info',
                });
                this.onChanged.emit(this.form.value);
                this.bsModalRef.hide();
            },
            err => {
                this.alertService.alert({
                    title: `Error Feedback`,
                    body: `Please try later`,
                    type: 'error',
                });
                this.bsModalRef.hide();
            });

    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
    public contactClick () :void {
        this.bsModalRef.hide();
        this.router.navigate(['/contact']);
    }
}
