import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {UserService} from '../services/user.service';

@Injectable()
export class CanActivateUser implements CanActivate {

    bsModalRef: BsModalRef;

    constructor(
        private modalService: BsModalService,
        private userService: UserService,
        private router: Router
    ) {
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (await route.data.roles.indexOf(this.userService.getRole()) !== -1) {
            return true;
        }
        await this.router.navigateByUrl('/');
        return false;
    }
}
