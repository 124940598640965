<ng-template #default>
        <ng-container *ngTemplateOutlet="short_tmpl">
        </ng-container>
        <p class="status-widget desc hidden-xs online-text-{{type}}" [ngClass]="cssClass">{{getDescription()}}</p>
</ng-template>

<ng-template #short_tmpl>
    <span [ngClass]="cssClass" class="fa fa-circle online-icon-{{type}}"></span>
</ng-template>
<div class="online-container online-container-{{type}}">
    <ng-container *ngTemplateOutlet="short ? short_tmpl : default">
    </ng-container>
</div>

