import {Currency} from '../../../entity/currency';

export class UserDetails {
    userId: number;
    nickName: string;
    callbackNumber: number;
    newLifeMailNumber: number;
    currency: Currency;
    bonus: number;
    balance: number;
    fullBalance: number;
    blocked: string;
    olsonTimezone: number;
    defaultPhoneType: number;
    regStep: number;
    role: string;
    showPromo: boolean;
    paygOn: boolean;
    showPaygOnWelcome: boolean;
    showPaygOnAddFunds: boolean;
    defaultCountryCode: number;
    sessionId: string;
}
