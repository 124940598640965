<div class="modal-header">
    <button (click)="bsModalRef.hide()" aria-label="Close" class="close" type="button">
        <span>&times;</span>
    </button>
    <b>Your feedback is important!</b>
</div>
<div class="modal-body">
    <b>Thank you for rating this reading and giving constructive feedback</b>
    <div>
        <shared-rating (onChanged)="data.readerRate = $event" [rating]="5"></shared-rating>
    </div>
    <div class="form-group">
        <textarea maxlength="400" [(ngModel)]="data.feedback" (input)="maxLength = 400-data.feedback.length" class="form-control"></textarea>
        <small>{{maxLength}} characters remaining</small>
    </div>
    <button (click)="sendFeedback()" class="btn btn-success">Save feedback</button>
    <p>
        <small>If you want to give feedback about the technical quality of the reading experience, please <a (click)="contactClick();" class="link">contact
            Support</a>
        </small>
    </p>
</div>
