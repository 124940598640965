import {FormArray, FormGroup} from '@angular/forms';

export class FormHelper {
    static availableGroup = [
        FormGroup,
        FormArray,
    ];

    static touchedForm(formGroup) {
        Object.keys(formGroup.controls).forEach((key) => {
            formGroup.controls[key].markAsTouched();
            if (FormHelper.availableGroup.some(item => formGroup.controls[key] instanceof item)) {
                this.touchedForm(formGroup.controls[key]);
            }
        });
    }

    static untouchedForm(formGroup) {
        Object.keys(formGroup.controls).forEach((key) => {
            formGroup.controls[key].markAsUntouched();
            if (FormHelper.availableGroup.some(item => formGroup.controls[key] instanceof item)) {
                this.untouchedForm(formGroup.controls[key]);
            }
        });
    }
}
