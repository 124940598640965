import {EventEmitter, Injectable} from '@angular/core';
import {Subscription} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PhoneEmitterService {

    invokePhonesChage = new EventEmitter();
    subsVar: Subscription;

    constructor() {
    }

    phoneChanged() {
        this.invokePhonesChage.emit();
    }
}
