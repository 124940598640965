<div class="modal-body text-center">
    <h3><b>Notifications</b></h3>
    <p>
        It seems your browser is not enabled to receive ‘Notifications’, or you have blocked them.
        Please enable your Notifications to ensure you can receive Readings. Any questions please contact
        <a href="mailto:readersupport@lifereader.com">readersupport@lifereader.com</a>
    </p>
    <p>
        <button (click)="onClick()" class="btn btn-def">Try again</button>
    </p>
</div>
