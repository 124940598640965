export class PersonalDetailsResponse {
    address: string;
    city: string;
    countryId: number;
    defaultPhoneCountryId: number;
    defaultPhoneNumber: string;
    firstName: string;
    lastName: string;
    postCode: string;
    titleId: number;
}
