<div *ngIf="!finished" class="container">
    <h1><b>Reader Application</b></h1>
    <p><b>Part 2 - profile details</b></p>
    <p>Your Reader Profile details will be included in your LifeReader profile page and will be viewable by website
        Customers.</p>
    <div class="form-group form-inline">
        <label>Your Profile Image
            <small>(required)</small>
        </label>
        <div>
            <label class="custom-file-upload" for="file-upload">
                <i class="fa fa-cloud-upload"></i> Select & Upload Your Profile Image
            </label>
            <input (change)="fileChange($event)" id="file-upload" type="file">
            <img alt="Image preview..." class="img-responsive" id="previewProfile" src="" required="true">
        </div>
    </div>
    <p><b>Please note:</b></p>
    <p>For a faster processing of your application please ensure that:
        The profile image is of high quality (low compression).
        Max 1024KB (1Mb) file size in jpeg or png format.
        Max 1000 by 1000 pixels, and with a 1:1 (square) aspect ratio for best results
        if the preview images seem distorted, ensure you have uploaded an image that has equal side lengths.</p>

    <form (submit)="doSubmit()" *ngIf="form" [formGroup]="form" [hidden]="!fileUploaded">
        <div class="head-form">
            <div [ngClass]="{'has-error':hasError('zodiak')}" class="form-group form-inline">
                <label>Zodiac sign
                    <small>(required)</small>
                </label>
                <div>
                    <select class="form-control" formControlName="zodiak" required="required">
                        <option disabled value="">starsign</option>
                        <option *ngFor="let item of signs" [ngValue]="item.id">{{item.sign}}</option>
                    </select>
                    <div *ngIf="hasError('zodiak')" class="help-block">
                        {{getErrorMessage('zodiak', 'serverSide')}}
                    </div>
                </div>
            </div>
<!--            <div [ngClass]="{'has-error':hasError('primaryLanguageId')}" class="form-group form-inline">-->
<!--                <label>Primary language-->
<!--                    <small>(required)</small>-->
<!--                </label>-->
<!--                <div>-->
<!--                    <select class="form-control" formControlName="primaryLanguageId" required="required">-->
<!--                        <option *ngFor="let item of languages" [ngValue]="item.id">{{item.name}}</option>-->
<!--                    </select>-->
<!--                    <div *ngIf="hasError('primaryLanguageId')" class="help-block">-->
<!--                        {{getErrorMessage('primaryLanguageId', 'serverSide')}}-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div [ngClass]="{'has-error':hasError('otherLang')}" class="form-group form-inline">-->
<!--                <label>Other language(s)-->
<!--                    <small>(if applicable)</small>-->
<!--                </label>-->
<!--                <div>-->
<!--                    <input class="form-control" formControlName="otherLang" type="text">-->
<!--                </div>-->
<!--            </div>-->
        </div>
        <h3>Your Profile Description</h3>
        <small>Your profile will be reviewed by a reader manager and will be re-formatted and possibly edited to meet
            the
            LifeReader profile guidelines.
        </small>
<!--        <h4>Short Description-->
<!--            <small>(required)</small>-->
<!--        </h4>-->
<!--        <p>-->
<!--            <small>Please summarise your approach and skills. This short description is the first thing that customers-->
<!--                see on-->
<!--                your profile page. It is also the text that is used for the reader mini-profile on the reader listings.-->
<!--            </small>-->
<!--        </p>-->
<!--        <div [ngClass]="{'has-error':hasError('shortDescription')}" class="form-group">-->
<!--            <textarea class="form-control" formControlName="shortDescription" maxlength="240"-->
<!--                      required="required" #shortDescription cdkTextareaAutosize-->
<!--                      #autosize="cdkTextareaAutosize"-->
<!--                      cdkAutosizeMinRows="4"-->
<!--                      cdkAutosizeMaxRows="10"></textarea>-->
<!--            <div class="help-block">-->
<!--                <small>{{240 - shortDescription.value.length}} characters remaining</small>-->
<!--            </div>-->
<!--            <div *ngIf="hasError('shortDescription')" class="help-block">-->
<!--                {{getErrorMessage('shortDescription', 'serverSide')}}-->
<!--            </div>-->
<!--        </div>-->
        <h4>About Me
            <small>(required)</small>
        </h4>
        <p>
            <small>Please provide detailed information about yourself, your Reading approach and style, your
                experiences,
                stories and successes you've had. For more information and guidance on what to write here, please study
                our
                published reader profiles.
            </small>
        </p>
        <div [ngClass]="{'has-error':hasError('aboutMe')}" class="form-group">
            <textarea class="form-control" formControlName="aboutMe" maxlength="2000" required="required"
                      #aboutMe cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="4"
                      cdkAutosizeMaxRows="10"></textarea>
            <div class="help-block">
                <small>{{2000 - aboutMe.value.length}} characters remaining</small>
            </div>
            <div *ngIf="hasError('aboutMe')" class="help-block">
                {{getErrorMessage('aboutMe', 'serverSide')}}
            </div>
        </div>
        <h4>Experience
            <small>(required)</small>
        </h4>
        <p>
            <small>Please provide detailed information about your professional reading experience and qualifications if
                applicable to your profession. Please be advised that you will need to provide evidence of
                qualifications,
                degrees and such claims as "police support work" or "reader to famous people" and have permission to
                publicise
                these. For more information and guidance on what to write here, please study our published reader
                profiles.
            </small>
        </p>
        <div [ngClass]="{'has-error':hasError('experience')}" class="form-group">
            <textarea class="form-control" formControlName="experience" maxlength="2000" required="required"
                      #experience cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="4"
                      cdkAutosizeMaxRows="10"></textarea>
            <div class="help-block">
                <small>{{1500 - experience.value.length}} characters remaining</small>
            </div>
            <div *ngIf="hasError('experience')" class="help-block">
                {{getErrorMessage('experience', 'serverSide')}}
            </div>
        </div>
        <h3>Reading categories
            <small>(required)</small>
        </h3>
        <p>
            <small>Please select the categories you would like to be listed under. Please note that being listed in more
                than
                8 categories is not advisable. We recommend to select at most 4-8 SubCategories in around 3 Categories.
                Please
                select your categories carefully as your application will be assessed by how many and which categories
                you have
                selected, and you may be asked questions about the categories and your experience with them if you are
                selected
                for an interview. Your Application will also be reviewed on the fit between your selected categories and
                your
                profile description
            </small>
        </p>
        <!--<div class="category" *ngFor="let category of categories">-->
        <!--<h4>{{category.title}}</h4>-->
        <!--<div class="checkbox-section">-->
        <!--<div *ngFor="let subCategory of category.subCategories; index as i">-->
        <!--<label class="pointer">-->
        <!--<input [(ngModel)]="subCategory.selected" [ngModelOptions]="{standalone: true}" type="checkbox">-->
        <!--{{subCategory.title}}-->
        <!--</label>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <div *ngFor="let category of categories" class="category">
            <!--<div class="row">-->
            <h4>{{category.title}}</h4>
            <div class="checkbox-section">
                <div *ngFor="let subCategory of category.subCategories; index as i">
                    <div class="col-xs-6">
                        <label class="pointer">{{subCategory.title}}
                            <input [(ngModel)]="subCategory.selected" [ngModelOptions]="{standalone: true}"
                                   type="checkbox">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>
            <!--</div>-->
        </div>


<!--        <h4>Additional tags & key search words-->
<!--            <small>(optional)</small>-->
<!--        </h4>-->
<!--        <p>-->
<!--            <small>Any of the categories selected above will automatically be added as tags & search words-->
<!--            </small>-->
<!--        </p>-->
<!--        <div [ngClass]="{'has-error':hasError('searchTags')}" class="form-group">-->
<!--            <textarea class="form-control" formControlName="searchTags" maxlength="250" #searchTags cdkTextareaAutosize-->
<!--                      #autosize="cdkTextareaAutosize"-->
<!--                      cdkAutosizeMinRows="4"-->
<!--                      cdkAutosizeMaxRows="10"></textarea>-->
<!--            <div class="help-block">-->
<!--                <small>{{250 - searchTags.value.length}} characters remaining</small>-->
<!--            </div>-->
<!--            <div *ngIf="hasError('searchTags')" class="help-block">-->
<!--                {{getErrorMessage('searchTags', 'serverSide')}}-->
<!--            </div>-->
<!--        </div>-->
        <h4>Reading Methods
            <small>(required)</small>
        </h4>
        <p>
            <small>You must always offer at least Phone or Chat, your selection can be changed anytime when logged in to
                your
                account. This is just an indication of what services you intend to offer for the application. Your
                LifeReader
                eMail messaging service will always be available for customers.
            </small>
        </p>
        <div class="category">
            <h4></h4>
            <div class="checkbox-section">
                <div class="col-xs-6">
                    <label class="pointer">Phone
                        <input formControlName="callAvailable"
                               type="checkbox">
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="col-xs-6">
                    <label class="pointer">Chat
                        <input formControlName="chatAvailable"
                               type="checkbox">
                        <span class="checkmark"></span>
                    </label>
                </div>
            </div>
            <!--<label class="pointer">Phone-->
            <!--<input  formControlName="callAvailable"-->
            <!--type="checkbox">-->
            <!--<span class="checkmark"></span>-->
            <!--</label>-->
            <!--<label class="pointer">Chat-->
            <!--<input  formControlName="chatAvailable"-->
            <!--type="checkbox">-->
            <!--<span class="checkmark"></span>-->
            <!--</label>-->
            <!--<label>-->
            <!--<input formControlName="callAvailable" type="checkbox">-->
            <!--Phone-->
            <!--</label> &nbsp;-->
            <!--<label>-->
            <!--<input formControlName="chatAvailable" type="checkbox">-->
            <!--Chat-->
            <!--</label>-->
        </div>
        <h3>Phone Numbers</h3>
        <p>
            <small>Please enter the phone numbers you will take phone readings on and your usual contact number. Your
                phone
                numbers will not be viewed by or transmitted to any customers. Please select your country code from the
                drop
                down menu then enter your area code and phone number without any leading zero's, spaces or slashes
            </small>
        </p>
        <h4>Your main phone & contact number
            <small>(required)</small>
        </h4>
        <div [ngClass]="{'has-error':hasError('homePhoneCountry')}" class="form-group form-inline">
            <select class="form-control" formControlName="homePhoneCountry">
                <option value="">Please select the country predial</option>
                <option *ngFor="let item of countries" [ngValue]="item.id">{{item.name}}</option>
            </select>
            <div *ngIf="hasError('homePhoneCountry')" class="help-block">
                {{getErrorMessage('homePhoneCountry', 'serverSide')}}
            </div>
        </div>
        <div [ngClass]="{'has-error':hasError('homePhone')}" class="form-group form-inline">
            <div>
                <span *ngIf="!getPhoneCode('homePhoneCountry')">+XX</span>
                <span>{{getPhoneCode('homePhoneCountry')}}</span>
            </div>
            <input class="form-control" formControlName="homePhone" required="required" type="text"  (input)="validatePhoneNumber(getPhoneCode('homePhoneCountry'), form.get('homePhone'))">
            <div class="help-block">
                <small>Please enter only your area code and phone number without your country code and leave off the
                    country
                    pre-dial (like 0),
                </small>
            </div>
            <div *ngIf="hasError('homePhone')" class="help-block">
                {{getErrorMessage('homePhone', 'serverSide')}}
            </div>
        </div>
        <ul class="list-unstyled">
            <li><span class="fa fa-check"></span>Like this: 1238888888</li>
            <li><span class="fa fa-times"></span>NOT like this:
                <del>+1 123 8888888</del>
            </li>
        </ul>
        <p>
            <small>Your country code is set automatically from your choice of country above and write it in the little
                area to
                the left of the number field above. We also strip any leading 0 (zeros) from your number. The same
                applies for
                the other phone numbers on this page.
            </small>
        </p>
        <div class="row">
            <div class="col-sm-6">
                <h4>Your work phone number
                    <small>(optional)</small>
                </h4>
                <div [ngClass]="{'has-error':hasError('workPhoneCountry')}" class="form-group form-inline">
                    <select class="form-control" formControlName="workPhoneCountry">
                        <option value="">Please select the country predial</option>
                        <option *ngFor="let item of countries" [ngValue]="item.id">{{item.name}}</option>
                    </select>
                </div>
                <div [ngClass]="{'has-error':hasError('workPhoneCountry')}" class="form-group form-inline">
                    <div>
                        <span *ngIf="!getPhoneCode('workPhoneCountry')">+XX</span>
                        <span>{{getPhoneCode('workPhoneCountry')}}</span>
                    </div>
                    <input class="form-control" formControlName="workPhone" type="text"  (input)="validatePhoneNumber(getPhoneCode('workPhoneCountry'), form.get('workPhone'))">
                </div>
            </div>
            <div class="col-sm-6">
                <h4>Your mobile phone number
                    <small>(optional)</small>
                </h4>
                <div [ngClass]="{'has-error':hasError('mobilePhoneCountry')}" class="form-group form-inline">
                    <select class="form-control" formControlName="mobilePhoneCountry">
                        <option value="">Please select the country predial</option>
                        <option *ngFor="let item of countries" [ngValue]="item.id">{{item.name}}</option>
                    </select>
                </div>
                <div [ngClass]="{'has-error':hasError('mobilePhone')}" class="form-group form-inline">
                    <div>
                        <span *ngIf="!getPhoneCode('mobilePhoneCountry')">+XX</span>
                        <span>{{getPhoneCode('mobilePhoneCountry')}}</span>
                    </div>
                    <input class="form-control" formControlName="mobilePhone" type="text" (input)="validatePhoneNumber(getPhoneCode('mobilePhoneCountry'), form.get('mobilePhone'))">
                    <div class="help-block">
                        <small><b>Note:</b> Using your mobile phone for Readings will incur an additional per minute
                            surcharge
                            outside of the US
                        </small>
                    </div>
                </div>
            </div>
        </div>
        <h3>Intended price range
            <small>(required)</small>
        </h3>
        <p>
            <small>Please select your multi-currency price level, that you believe reflects the value of your reading.
                If you
                want to amend your pricing level please get in contact with your reader manager.
            </small>
        </p>
        <ul class="list-unstyled list-inline menu-price">
            <li (click)="selectRate(i)" *ngFor="let item of getRatesMenu(); let i=index"
                [ngClass]="{'active':i===selectedRate}"> {{item}}
            </li>
        </ul>
        <div class="row">
            <div class="col-sm-6">
                <h5>Phone Readings</h5>
                <table class="table">
                    <tr>
                        <th>Price Rate</th>
                        <th class="text-center" colspan="5">Advertised per minute rate</th>
                    </tr>
                    <tr>
                        <th></th>
                        <th *ngFor="let currency of getRatesInCurrencies()">{{currency}}</th>
                    </tr>
                    <tr *ngFor="let row of rates.callRates; let i = index" [ngClass]="{'active':i===selectedRate}">
                        <td>{{getRate(i)}}</td>
                        <td *ngFor="let item of row.ratesInCurrencies">{{item.rateValue|number:'.1-1'}}</td>
                    </tr>
                </table>
            </div>
            <div class="col-sm-6">
                <h5>Chat Readings</h5>
                <table class="table">
                    <tr>
                        <th>Price Rate</th>
                        <th class="text-center" colspan="5">Advertised per minute rate</th>
                    </tr>
                    <tr>
                        <th></th>
                        <th *ngFor="let currency of getRatesInCurrencies()">{{currency}}</th>
                    </tr>
                    <tr *ngFor="let row of rates.chatRates; let i = index" [ngClass]="{'active':i===selectedRate}">
                        <td>{{getRate(i)}}</td>
                        <td *ngFor="let item of row.ratesInCurrencies">{{item.rateValue|number:'.1-1'}}</td>
                    </tr>
                </table>
            </div>
        </div>


        <div class="form-group">
            <button class="btn btn-success" type="submit">Submit application</button>
        </div>
    </form>
    <br>
    <br>
    <br>
</div>
<div *ngIf="finished" class="container">

    <div class="registration-form col-xs-12">
        <h1><b>Reader Application</b></h1>
        <h3 class="purple pull-left" style="margin-bottom: 20px;width:100%;">Thank you for applying for a listing with
            LifeReader</h3>

        <p>We will now be reviewing your application over the next few days.
            If your application is successful we will be contacting you personally.
            Processing of applications can take 14 days or more.
            You can in the meantime access your account with your email and password
            but you will not be able to accept any readings as your profile will only be published if your application
            is approved.
            You may <a routerLink="/contact">contact</a> us at any time, but due to high demand responses may take up to
            48 hours during business days.</p>

        <p>Kind regards</p>

        <p>LifeReader</p>

        <a routerLink="/" title="go to homepage">Go to homepage</a>

    </div>
</div>
