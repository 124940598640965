import {FormGroup} from '@angular/forms';

export abstract class ServerErrors {
    form: FormGroup;

    hasError(field: string, omit = false) {
        if (omit) {
            return false;
        }
        if (!field) {
            return;
        }
        const valid = !this.getData(this.form.controls, this.groupToControl(field)).valid;
        const touched = Boolean(this.getData(this.form.controls, this.groupToControl(field)).touched);
        return valid && touched;
    }

    setServerError(field, msg) {
        if (!field) {
            return;
        }
        this.getData(this.form.controls, this.groupToControl(field)).setErrors({'serverSide': msg});
    }

    /**
     * @param field str with separator "."
     * @param key
     */
    getErrorMessage(field, key) {
        if (!field) {
            return;
        }

        return this.getData(this.form.controls, this.groupToControl(field)).getError(key);
    }

    checkError(field, key, omit = false) {
        if (omit) {
            return false;
        }
        if (!field) {
            return;
        }

        return this.getData(this.form.controls, this.groupToControl(field)).hasError(key);
    }

    groupToControl(field) {
        return field.split('.').join('.controls.');
    }

    getData(obj, str) {
        let data = obj;
        const arr = str.split('.');
        for (const key in arr) {
            if (data[arr[key]]) {
                data = data[arr[key]];
            }
        }
        return data;
    }
}
