import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'shared-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss'],
})
export class RatingComponent implements OnInit {

    @Input()
    rating;

    @Input()
    disabled = false;

    stars = [];

    @Output()
    onChanged = new EventEmitter();

    constructor() {
        for (let i = 0; i < 5; i++) {
            this.stars.push({
                active: false,
            });
        }
    }

    ngOnInit() {
        for (let i = 0; i < this.rating; i++) {
            this.stars[i].active = true;
        }
    }

    changeStars(index) {
        if (this.rating) {
            return;
        }
        for (let i = 0; i <= index; i++) {
            this.stars[i].active = true;
        }
    }

    clear() {
        for (let i = 0; i < 5; i++) {
            this.stars[i].active = false;
        }
    }

    setRating(index) {
        for (let i = 0; i < 5; i++) {
            this.stars[i].active = false;
        }
        this.rating = index;
        for (let i = 0; i <= index; i++) {
            this.stars[i].active = true;
        }
        this.onChanged.emit(this.rating + 1);
    }
}
