import {Component, Input, OnInit} from '@angular/core';
import {BookModalComponent} from '../../components/modals/book-modal/book-modal.component';
import {Reader} from '../../entity/reader';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {UserService} from '../../../../services/user.service';
import {ReaderService} from '../../services/reader-service';
import {NGXLogger} from 'ngx-logger';
import {SubSink} from 'subsink';
import {Subscriber} from 'rxjs';
import {LoginFormComponent} from '../../components/login-form/login-form.component';
import {RegistrationComponent} from '../../components/registration/registration.component';
import {GoogleAnalyticsEventsService} from '../../../../services/google-analytics-events.service';

@Component({
    selector: 'shared-book-button',
    templateUrl: './book-button.component.html',
    styleUrls: ['./book-button.component.scss'],
})
export class BookButtonComponent implements OnInit {
    loginModalRef: BsModalRef;
    registerModalRef: BsModalRef;
    @Input()
    reader: Reader;
    /**
     * It is used to determinate where the component is used
     */
    @Input()
    type: any;

    @Input()
    role = '';
    doRegisterSubscriber: Subscriber<any>;
    doSignInSubscriber: Subscriber<any>;
    private subs = new SubSink();

    constructor(
        private userService: UserService,
        private modalService: BsModalService,
        private readerService: ReaderService,
        private logger: NGXLogger,
        private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    ) {
    }

    ngOnInit() {
    }

    async doBook() {

        if (this.role === 'ROLE_CUSTOMER' || this.role === '') {
            const any: any = await this.userService.checkRegistration();
            if (any !== true) {
                await this.googleAnalyticsEventsService.visitorReadingAttempt(this.reader.id, 'callback');
                this.readerService.saveSelectedReaderLocalStorage(this.reader.id);
                this.subs.sink = this.doSignInSubscriber = any.content.doSignIn.subscribe(() => {
                    this.doLogin();
                });
                return;
            }
            await this.googleAnalyticsEventsService.customerReadingAttempt(this.reader.id, 'callback');
            const initialState = {
                reader: this.reader,
            };
            this.modalService.show(BookModalComponent, {
                initialState: initialState,
                backdrop: 'static',
                animated: false,
            });
        }
    }


    doLogin() {
        // https://lifereader.atlassian.net/browse/LA-1021
        this.loginModalRef = this.modalService.show(LoginFormComponent, {
            backdrop: true,
            keyboard: false,
            animated: false,
        });
        this.subs.sink = this.doRegisterSubscriber = this.loginModalRef.content.doRegister.subscribe(() => {
            this.doRegister();
        });
    }

    doRegister() {
        this.registerModalRef = this.modalService.show(RegistrationComponent, {
            class: 'modal-dialog-steps',
            backdrop: true,
            keyboard: true,
            animated: false,
        });
        this.subs.sink = this.doSignInSubscriber = this.registerModalRef.content.doSignIn.subscribe(() => {
            this.doLogin();
        });
    }

}
