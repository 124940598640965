<div class="modal-header">
    <button (click)="bsModalRef.hide()" aria-label="Close" class="close" type="button">
        <span>&times;</span>
    </button>
</div>
<div #modalBodyStep3 class="modal-body">
    <!-- size-detector.component.html -->
    <app-size-detector></app-size-detector>
    <div class="steps">
        <div class="step complete">Create Account</div>
        <div class="step complete">Personal Info</div>
        <div class="step active">Start Reading</div>
    </div>

    <br>
    <tabset #staticTabs [justified]="size > 0 ? true: null">
        <tab (selectTab)="tabSelected($event, 0)">
            <ng-template class="tabHeading" tabHeading>
                <img alt="" src="assets/visa-mastercard.png">
            </ng-template>
            <div class="text-center content-body">
                <ng-container *ngIf="currentStep==3"><br>
                    <p><b>Get your 4 free chat minutes by entering your billing info</b></p>
                    <p>Free Readings will <b class="underline">not</b> be charged</p>
                    <div class="row">
                        <div class="col-xs-offset-0 col-xs-12 col-sm-offset-1 col-sm-10">
                            <form [formGroup]="form"
                                  class="text-left master-card-form">
                            </form>
                        </div>
                    </div>
                    <!--</div>-->
                    <div class="row">
                        <div class="help-block col-xs-offset-0 col-xs-12 col-sm-offset-1 col-sm-10">
                            <div class="master-card-form">
                                <div class="form-group">
                                    <button (click)="doSubmit()" [disabled]="sendingData" class="btn btn-success">
                                        <span *ngIf="sendingData" class="fa fa-spinner fa-pulse"></span>
                                        Enter Credit Card
                                    </button>
                                </div>
                                <div class="help-block">
                                    <div class="info-text">Secured Card payments for LifeReader New Zealand are processed by Windcave Limited.</div>
                                    <div class="info-text">You will be redirected to a secure page when adding your Credit Card.</div>
                                </div>
                                <p>
                                    <img class="dps-logo" src="assets/images/wc-logo.png">
                                </p>

                            </div>
                        </div>
                    </div>
                </ng-container>
                <div *ngIf="currentStep==4" class="row">
                    <div class="col-xs-offset-1 col-xs-10 step4">
                        <br>
                        <p><b>Select your top-up offer and get 4 free chat minutes</b></p>
                        <p>First sessions usually take 15 to 25 minutes, depending on the topic. Unused credit will be
                            waiting
                            for your
                            next sessions or can be refunded at any time.</p>

                        <div>
                            <div class="col-xs-12">
                                <div *ngIf="promotionsCC && promotionsCC.length > 0" class="row">
                                    <form [formGroup]="ccForm">
                                        <div *ngIf="paygWelcome">
                                            <div class="form-group credits text-left offer-payg">
                                                <label class="normal-font pointer">
                                                    <div class="offer-container ">
                                                        <div class="inputRadio">
                                                            <input #paygRadio
                                                                    (change)="paygSelected($event, modalBodyStep3)"
                                                                   [value]="{id: -1}"

                                                                   formControlName="promotionCC"
                                                                   name="promotionCC"
                                                                   type="radio">
                                                            <span class="checkmark"></span>
                                                        </div>
                                                        <div class="inputRadioLabel">
                                                          <span>
                                                            Pay As You Go *
                                                          </span>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                            <p style="text-align: left">* ONLY if you do a paid reading you will be charged
                                                in
                                                10 minutes intervals</p>
                                        </div>
                                        <div *ngFor="let item of promotionsCC" class="form-group credits text-left" [ngClass]="!item.info.bonus>0 ? 'no-offer': ''">
                                            <label class="normal-font pointer">
                                                <div class="offer-container">
                                                    <div class="inputRadio">
                                                        <input [value]="item" formControlName="promotionCC"
                                                               name="promotionCC"
                                                               type="radio">
                                                        <span class="checkmark"></span>
                                                    </div>
                                                    <div class="inputRadioLabel">
                                                          <span>
                                                            {{item.info.currency.asciiSymbol}}{{item.info.amount + item.info.bonus}} credit value
                                                                <ng-container
                                                                        *ngIf="item.info.bonus>0">
                                                                    for {{item.info.currency.asciiSymbol}}{{item.info.amount}}
                                                                </ng-container>
                                                                <ng-container
                                                                        *ngIf="item.info.bonus>0">&nbsp;&nbsp;
                                                                    <b [ngClass]="item.info.bonus>0 ? 'bonus-offer': ''">
                                                                        You save {{item.info.currency.asciiSymbol}}{{item.info.bonus}}
                                                                    </b>
                                                                </ng-container>
                                                          </span>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                        <div class="form-group">
                                            <div class="form-group">
                                                <button (click)="addFundsCC()"
                                                        [disabled]="!ccForm.controls['promotionCC'].value"
                                                        class="btn btn-success"
                                                        id="step3-welcomeOffers">Proceed
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </tab>
        <tab (selectTab)="tabSelected($event, 1)">
            <ng-template tabHeading>
                <img alt="" src="assets/paypal_blue.png">
            </ng-template>
            <div class="text-center content-body">
                <div class="row">
                    <div class="col-xs-offset-1 col-xs-10 step4">
                        <br>
                        <p><b>Select your top-up offer and get 4 free chat minutes</b></p>
                        <p>First sessions usually take 15 to 25 minutes, depending on the topic. Unused credit will be
                            waiting
                            for your
                            next sessions or can be refunded at any time.</p>

                        <div>
                            <div class="col-xs-12">
                                <div *ngIf="promotions && promotions.length>0" class="row">
                                    <form [formGroup]="ccForm">
                                        <div *ngFor="let item of promotions" class="form-group credits text-left" [ngClass]="!item.info.bonus>0 ? 'no-offer': ''">
                                            <label class="normal-font pointer">
                                                <div class="offer-container">
                                                    <div class="inputRadio">
                                                        <input [value]="item" formControlName="promotionCC"
                                                               name="promotionCC"
                                                               type="radio">
                                                        <span class="checkmark"></span>
                                                    </div>
                                                    <div class="inputRadioLabel">
                                                      <span>
                                                        {{item.info.currency.asciiSymbol}}{{item.info.amount + item.info.bonus}} credit value
                                                          <ng-container
                                                                  *ngIf="item.info.bonus>0">for {{item.info.currency.asciiSymbol}}{{item.info.amount}}
                                                          </ng-container>
                                                          <ng-container *ngIf="item.info.bonus>0">&nbsp;&nbsp;
                                                              <b [ngClass]="item.info.bonus>0 ? 'bonus-offer': ''">
                                                                  You save {{item.info.currency.asciiSymbol}}{{item.info.bonus}}
                                                              </b>
                                                          </ng-container>
                                                    </span>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                        <div class="form-group text-center" align="center">
                                            <div style="width: 200px; margin: 0 auto" [className]="this.ccForm.valid?'paypalOn':'paypalOff'">
                                                <div id="paypalRegistration" #paypalRegistration [className]="this.ccForm.valid?'paypalOn':'paypalOff'"></div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </tab>
    </tabset>

    <div class="text-center footer-message visible-xs">
        <b>
            Or <a (click)="bsModalRef.hide()" routerLink="/all-psychic-readers-at-lifereader">Browse Readers</a>
            and choose offer later</b>
    </div>
    <div class="separation hidden-xs"></div>
    <div class="text-center footer-message hidden-xs">
        <b>
            <a (click)="bsModalRef.hide()" routerLink="/all-psychic-readers-at-lifereader">Browse Readers</a>
            and choose offer later</b>
    </div>
</div>

