<div class="container">
    <h1>Reader Application</h1>


    <form *ngIf="form" [formGroup]="form">

        <div formGroupName="step1" [collapse]="step1IsCollapsed">
            <p>These details will be used for LifeReader registration purposes only and will not be disclosed to the public.</p>
            <h3>Part 1 - personal details</h3>

            <div [ngClass]="{'has-error': isFieldInvalidStep1('titleId')}" class="form-group form-inline">
                <label>Title (required)</label>
                <div>
                    <select tabindex="1" class="form-control" formControlName="titleId">
                        <option *ngFor="let item of titles" [ngValue]="item.id">{{item.name}}</option>
                    </select>
                    <div *ngIf="isFieldInvalidStep1('titleId')" class="help-block">
                        <span *ngIf="form.get('step1.titleId').errors?.required">Title is required.</span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <div [ngClass]="{'has-error': isFieldInvalidStep1('firstName')}" class="form-group">
                        <label>First Name <small>(required)</small></label>
                        <input tabindex="2" class="form-control" formControlName="firstName" type="text">
                        <div *ngIf="isFieldInvalidStep1('firstName')" class="help-block">
                            <span *ngIf="form.get('step1.firstName').errors?.required">First Name is required.</span>
                            <span *ngIf="form.get('step1.firstName').errors?.maxlength">First Name cannot be more than 50 characters.</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div [ngClass]="{'has-error': isFieldInvalidStep1('lastName')}" class="form-group">
                        <label>Last Name <small>(required)</small></label>
                        <input tabindex="3" class="form-control" formControlName="lastName" type="text">
                        <div *ngIf="isFieldInvalidStep1('lastName')" class="help-block">
                            <span *ngIf="form.get('step1.lastName').errors?.required">Last Name is required.</span>
                            <span *ngIf="form.get('step1.lastName').errors?.maxlength">Last Name cannot be more than 50 characters.</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <div [ngClass]="{'has-error': isFieldInvalidStep1('birthdayDay') || isFieldInvalidStep1('birthdayMonth') || isFieldInvalidStep1('birthdayYear')}" class="form-group">
                        <label>Date of Birth <small>(required) You must be 18 or older to use LifeReader</small></label>
                        <div class="row">
                            <div class="col-sm-4">
                                <select tabindex="4" class="form-control" formControlName="birthdayDay">
                                    <option value="">Day</option>
                                    <option *ngFor="let day of getDays()" [ngValue]="day">{{day}}</option>
                                </select>
                                <div *ngIf="isFieldInvalidStep1('birthdayDay')" class="help-block">
                                    <span *ngIf="form.get('step1.birthdayDay').errors?.required">Day is required.</span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <select tabindex="5" class="form-control" formControlName="birthdayMonth">
                                    <option value="">Month</option>
                                    <option *ngFor="let month of getMonths(); let i = index" [ngValue]="i + 1">{{month}}</option>
                                </select>
                                <div *ngIf="isFieldInvalidStep1('birthdayMonth')" class="help-block">
                                    <span *ngIf="form.get('step1.birthdayMonth').errors?.required">Month is required.</span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <select tabindex="6" class="form-control" formControlName="birthdayYear">
                                    <option value="">Year</option>
                                    <option *ngFor="let year of getYears()" [ngValue]="year">{{year}}</option>
                                </select>
                                <div *ngIf="isFieldInvalidStep1('birthdayYear')" class="help-block">
                                    <span *ngIf="form.get('step1.birthdayYear').errors?.required">Year is required.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div [ngClass]="{'has-error': isFieldInvalidStep1('city')}" class="form-group">
                        <label>City <small>(required)</small></label>
                        <input tabindex="7" class="form-control" formControlName="city" type="text">
                        <div *ngIf="isFieldInvalidStep1('city')" class="help-block">
                            <span *ngIf="form.get('step1.city').errors?.required">City is required.</span>
                            <span *ngIf="form.get('step1.city').errors?.maxlength">City cannot be more than 50 characters.</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <div [ngClass]="{'has-error': isFieldInvalidStep1('address')}" class="form-group">
                        <label>Street Address <small>(required)</small></label>
                        <input tabindex="8" class="form-control" formControlName="address" type="text">
                        <div *ngIf="isFieldInvalidStep1('address')" class="help-block">
                            <span *ngIf="form.get('step1.address').errors?.required">Address is required.</span>
                            <span *ngIf="form.get('step1.address').errors?.maxlength">Address cannot be more than 100 characters.</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div [ngClass]="{'has-error': isFieldInvalidStep1('country')}" class="form-group">
                        <label>Country <small>(required)</small></label>
                        <select tabindex="9" class="form-control" formControlName="country" (change)="onSelectCountryChange($event)">
                            <option disabled selected value="0">Country of Residence</option>
                            <option *ngFor="let item of countries; trackBy: trackByCountries" [value]="item.id">{{item.name}}</option>
                        </select>
                        <div *ngIf="isFieldInvalidStep1('country')" class="help-block">
                            <span *ngIf="form.get('step1.country').errors?.required">Country is required.</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <div [ngClass]="{'has-error': isFieldInvalidStep1('postCode')}" class="form-group">
                        <label>Zip/Postcode <small>(required)</small></label>
                        <input tabindex="10" class="form-control" formControlName="postCode" type="text">
                        <div *ngIf="isFieldInvalidStep1('postCode')" class="help-block">
                            <span *ngIf="form.get('step1.postCode').errors?.required">Postcode is required.</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <div [ngClass]="{'has-error': isFieldInvalidStep1('phone')}" class="form-group">
                        <label>Your Main Phone Number <small>(required)</small></label>
                        <p>
                            <span>{{phoneCode}}</span>
                        </p>
                        <input tabindex="11"  class="form-control" formControlName="phone" type="text">
                        <div *ngIf="isFieldInvalidStep1('phone')" class="help-block">
                            <span *ngIf="form.get('step1.phone').errors?.required">Phone number is required.</span>
                            <span *ngIf="form.get('step1.phone').errors?.pattern">Phone number must be only digits</span>
                        </div>
                    </div>
                </div>
            </div>

            <p>
                <small class="fa fa-check">Like this: 1238888888</small>
                <br>
                <small class="fa fa-times">NOT like this: <del>+11238888888</del></small>
            </p>
            <p>Your country code is set automatically from your country of residence and write it in the little area to the left of the number field above. We also strip any leading 0 (zeros) from your number.</p>

            <div class="row">
                <div class="col-sm-6">
                    <div [ngClass]="{'has-error': isFieldInvalidStep1('email')}" class="form-group">
                        <label>Email Address <small>(required)</small></label>
                        <input tabindex="12" class="form-control" formControlName="email" type="text">
                        <div *ngIf="isFieldInvalidStep1('email')" class="help-block">
                            <span *ngIf="form.get('step1.email').errors?.required">Email is required.</span>
                            <span *ngIf="form.get('step1.email').errors?.email">Email must be valid.</span>
                        </div>
                    </div>
                    <div [ngClass]="{'has-error': isFieldInvalidStep1('userName')}" class="form-group">
                        <label>Username <small>(required)</small></label>
                        <input tabindex="14" class="form-control" formControlName="userName" type="text">
                        <div *ngIf="isFieldInvalidStep1('userName')" class="help-block">
                            <span *ngIf="form.get('step1.userName').errors?.required">Username is required.</span>
                            <span *ngIf="form.get('step1.userName').errors?.minlength">Username must be at least 5 characters.</span>
                            <span *ngIf="form.get('step1.userName').errors?.maxlength">Username cannot be more than 20 characters.</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div [ngClass]="{'has-error': isFieldInvalidStep1('password')}" class="form-group">
                        <label>Create Password <small>(required)</small></label>
                        <input tabindex="13" class="form-control" formControlName="password" type="password">
                        <div *ngIf="isFieldInvalidStep1('password')" class="help-block">
                            <span *ngIf="form.get('step1.password').errors?.required">Password is required.</span>
                            <span *ngIf="form.get('step1.password').errors?.minlength">Password must be at least 8 characters.</span>
                        </div>
                    </div>
                    <div [ngClass]="{'has-error': isFieldInvalidStep1('confirmPassword')}" class="form-group">
                        <label>Confirm Password <small>(required)</small></label>
                        <input tabindex="15" class="form-control" formControlName="confirmPassword" type="password">
                        <div *ngIf="isFieldInvalidStep1('confirmPassword')" class="help-block">
                            <span *ngIf="form.get('step1.confirmPassword').errors?.required">Confirm Password is required.</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group checkbox-group">
                        <input tabindex="16" formControlName="gdprAccepted" id="terms-conditions" type="checkbox">
                        <div *ngIf="isFieldInvalidStep1('gdprAccepted')" class="text-danger alert alert-danger">
                            <span *ngIf="form.get('step1.gdprAccepted').errors?.required">You must accept the terms & conditions.</span>
                        </div>
                        <div class="checkbox-label">
                            <p>
                                By checking this box I confirm that I have read, understood and agree to the LifeReaders
                                <a routerLink="/reader-terms" target="blank">Terms &amp; Conditions</a>
                                and
                                <a routerLink="/privacy" target="blank">Privacy Policy</a>. I am 18 years of age or older.
                                I will receive communications from LifeReader and can opt out from these at any time <span class="purple">(required)</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <button class="btn btn-success submit" (click)="validateStep1()">Continue application <span class="fa fa-chevron-right"></span></button>
                </div>
            </div>
        </div>


        <div formGroupName="step2" [collapse]="step2IsCollapsed">
            <h3>Part 2 - profile details</h3>
            <p>Your Reader Profile details will be included in your LifeReader profile page and will be viewable by website
                Customers.</p>
            <div class="form-group form-inline">
                <label>Your Profile Image
                    <small>(required)</small>
                </label>
                <div>
                    <label class="custom-file-upload" for="file-upload">
                        <i class="fa fa-cloud-upload"></i> Select & Upload Your Profile Image
                    </label>
                    <input (change)="fileChange($event)" id="file-upload" type="file">
                    <img alt="Image preview..." class="img-responsive" id="previewProfile" style="display: none">
                </div>
            </div>
            <p><b>Please note:</b></p>
            <p>For a faster processing of your application please ensure that:
                The profile image is of high quality (low compression).
                Max 1024KB (1Mb) file size in jpeg or png format.
                Max 1000 by 1000 pixels, and with a 1:1 (square) aspect ratio for best results
                if the preview images seem distorted, ensure you have uploaded an image that has equal side lengths.</p>
            <div class="head-form">
                <div [ngClass]="{'has-error': isFieldInvalidStep2('zodiak')}" class="form-group form-inline">
                    <label>Zodiac sign
                        <small>(required)</small>
                    </label>
                    <div>
                        <select class="form-control" formControlName="zodiak" required>
                            <option disabled value="">starsign</option>
                            <option *ngFor="let item of signs" [ngValue]="item.id">{{item.sign}}</option>
                        </select>
                        <div *ngIf="isFieldInvalidStep2('zodiak')" class="help-block">
                            <small *ngIf="form.get('step2.zodiak').hasError('required')">Zodiac sign is required.</small>
                        </div>
                    </div>
                </div>
                <h4>About Me
                    <small>(required)</small>
                </h4>
                <p>
                    <small>Please provide detailed information about yourself, your Reading approach and style, your
                        experiences,
                        stories and successes you've had. For more information and guidance on what to write here, please study
                        our
                        published reader profiles.
                    </small>
                </p>
                <div [ngClass]="{'has-error': isFieldInvalidStep2('aboutMe')}" class="form-group">
                    <textarea class="form-control" formControlName="aboutMe" maxlength="2000" required="required"
                      #aboutMe cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="4"
                      cdkAutosizeMaxRows="10"></textarea>
                    <div class="help-block">
                        <small>{{2000 - aboutMe.value.length}} characters remaining</small>
                    </div>
                    <div *ngIf="isFieldInvalidStep2('aboutMe')" class="help-block">
                        <small *ngIf="form.get('step2.aboutMe').hasError('required')">About Me is required.</small>
                        <small *ngIf="form.get('step2.aboutMe').hasError('maxlength')">Maximum length is 2000 characters.</small>
                    </div>
                </div>
                <h4>Experience
                    <small>(required)</small>
                </h4>
                <p>
                    <small>Please provide detailed information about your professional reading experience and qualifications if
                        applicable to your profession. Please be advised that you will need to provide evidence of
                        qualifications,
                        degrees and such claims as "police support work" or "reader to famous people" and have permission to
                        publicise
                        these. For more information and guidance on what to write here, please study our published reader
                        profiles.
                    </small>
                </p>
                <div [ngClass]="{'has-error': isFieldInvalidStep2('experience')}" class="form-group">
                    <textarea class="form-control" formControlName="experience" maxlength="2000" required="required"
                      #experience cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="4"
                      cdkAutosizeMaxRows="10"></textarea>
                    <div class="help-block">
                        <small>{{1500 - experience.value.length}} characters remaining</small>
                    </div>
                    <div *ngIf="isFieldInvalidStep2('experience')" class="help-block">
                        <small *ngIf="form.get('step2.experience').hasError('required')">Experience is required.</small>
                        <small *ngIf="form.get('step2.experience').hasError('maxlength')">Maximum length is 2000 characters.</small>
                    </div>
                </div>
                <h3>Reading categories
                    <small>(required)</small>
                </h3>
                <p>
                    <small>Please select the categories you would like to be listed under. Please note that being listed in more
                        than
                        8 categories is not advisable. We recommend to select at most 4-8 SubCategories in around 3 Categories.
                        Please
                        select your categories carefully as your application will be assessed by how many and which categories
                        you have
                        selected, and you may be asked questions about the categories and your experience with them if you are
                        selected
                        for an interview. Your Application will also be reviewed on the fit between your selected categories and
                        your
                        profile description
                    </small>
                </p>
                <div *ngFor="let category of categories" class="category">
                    <h4>{{category.title}}</h4>
                    <div class="checkbox-section">
                        <div *ngFor="let subCategory of category.subCategories; index as i">
                            <div class="col-xs-6">
                                <label class="pointer">{{subCategory.title}}
                                    <input [(ngModel)]="subCategory.selected" [ngModelOptions]="{standalone: true}"
                                           type="checkbox">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <h4>Reading Methods
                    <small>(required)</small>
                </h4>
                <p>
                    <small>You must always offer at least Phone or Chat, your selection can be changed anytime when logged in to
                        your
                        account. This is just an indication of what services you intend to offer for the application. Your
                        LifeReader
                        eMail messaging service will always be available for customers.
                    </small>
                </p>
                <div class="category">
                    <h4></h4>
                    <div class="checkbox-section">
                        <div class="col-xs-6">
                            <label class="pointer">Phone
                                <input formControlName="callAvailable"
                                       type="checkbox">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="col-xs-6">
                            <label class="pointer">Chat
                                <input formControlName="chatAvailable"
                                       type="checkbox">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <h3>Phone Numbers</h3>
                <p>
                    <small>Please enter the phone numbers you will take phone readings on and your usual contact number. Your
                        phone
                        numbers will not be viewed by or transmitted to any customers. Please select your country code from the
                        drop
                        down menu then enter your area code and phone number without any leading zero's, spaces or slashes
                    </small>
                </p>
                <h4>Your main phone & contact number
                    <small>(required)</small>
                </h4>
                <div >
                    <div [ngClass]="{'has-error': isFieldInvalidStep2('homePhoneCountry')}" class="form-group form-inline">
                        <select class="form-control" formControlName="homePhoneCountry" (change)="onSelectCountryHomeChange($event)">
                            <option value="">Please select the country</option>
                            <option *ngFor="let item of countries" [value]="item.id">{{ item.name }}</option>
                        </select>
                        <div *ngIf="isFieldInvalidStep2('homePhoneCountry')" class="help-block">
                            <small *ngIf="form.get('step2.homePhoneCountry').hasError('required')">Country is required.</small>
                        </div>
                    </div>
                    <div [ngClass]="{'has-error': isFieldInvalidStep2('homePhone')}" class="form-group form-inline">
                        <div>
                            <span>{{phoneCodeHome}}</span>
                        </div>
                        <input class="form-control" formControlName="homePhone" required="required" type="text">
                        <div class="help-block">
                            <small>Please enter only your area code and phone number without your country code and leave
                                off the
                                country
                                pre-dial (like 0),
                            </small>
                        </div>
                        <div *ngIf="isFieldInvalidStep2('homePhone')" class="help-block">
                            <small *ngIf="form.get('step2.homePhone').errors?.required">Phone number is required.</small>
                            <small *ngIf="form.get('step2.homePhone').errors?.pattern">Phone number must be only digits</small>
                        </div>
                    </div>
                </div>
                <ul class="list-unstyled">
                    <li><span class="fa fa-check"></span>Like this: 1238888888</li>
                    <li><span class="fa fa-times"></span>NOT like this:
                        <del>+1 123 8888888</del>
                    </li>
                </ul>
                <p>
                    <small>Your country code is set automatically from your choice of country above and write it in the little
                        area to
                        the left of the number field above. We also strip any leading 0 (zeros) from your number. The same
                        applies for
                        the other phone numbers on this page.
                    </small>
                </p>
                <div class="row" *ngIf="countries">
                    <div class="col-sm-6">
                        <h4>Your work phone number
                            <small>(optional)</small>
                        </h4>
                        <div [ngClass]="{'has-error': isFieldInvalidStep2('workPhoneCountry')}" class="form-group form-inline">
                            <select class="form-control" formControlName="workPhoneCountry" (change)="onSelectCountryWorkChange($event)">
                                <option value="">Please select the country</option>
                                <option *ngFor="let item of countries" [value]="item.id">{{item.name}}</option>
                            </select>
                        </div>
                        <div [ngClass]="{'has-error': isFieldInvalidStep2('workPhone')}" class="form-group form-inline"><div>
                                <span>{{phoneCodeWork}}</span>
                            </div>
                            <input class="form-control" formControlName="workPhone" type="text"
                                  >
                            <div *ngIf="isFieldInvalidStep2('workPhone')" class="help-block">
                                <small *ngIf="form.get('step2.workPhone').errors?.required">Phone number is required.</small>
                                <small *ngIf="form.get('step2.workPhone').errors?.pattern">Phone number must be only digits</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <h4>Your mobile phone number
                            <small>(optional)</small>
                        </h4>
                        <div [ngClass]="{'has-error': isFieldInvalidStep2('workPhone')}" class="form-group form-inline"><div>
                            <select class="form-control" formControlName="mobilePhoneCountry" (change)="onSelectCountryMobileChange($event)">
                                <option value="">Please select the country</option>
                                <option *ngFor="let item of countries" [value]="item.id">{{item.name}}</option>
                            </select>
                        </div>
                        <div [ngClass]="{'has-error':hasError('mobilePhone')}" class="form-group form-inline">
                            <div>
                                <span>{{phoneCodeMobile}}</span>
                            </div>
                            <input class="form-control" formControlName="mobilePhone" type="text">
                            <div *ngIf="isFieldInvalidStep2('mobilePhone')" class="help-block">
                                <small *ngIf="form.get('step2.mobilePhone').errors?.required">Phone number is required.</small>
                                <small *ngIf="form.get('step2.mobilePhone').errors?.pattern">Phone number must be only digits</small>
                            </div>
                            <div class="help-block">
                                <small><b>Note:</b> Using your mobile phone for Readings will incur an additional per minute
                                    surcharge
                                    outside of the US
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
                <h3>Intended price range
                    <small>(required)</small>
                </h3>
                <p>
                    <small>Please select your multi-currency price level, that you believe reflects the value of your reading.
                        If you
                        want to amend your pricing level please get in contact with your reader manager.
                    </small>
                </p>
                <ul class="list-unstyled list-inline menu-price">
                    <li (click)="selectRate(i)" *ngFor="let item of getRatesMenu(); let i=index"
                        [ngClass]="{'active':i===selectedRate}"> {{item}}
                    </li>
                </ul>
                <div class="row" *ngIf="this.rates">
                    <div class="col-sm-6">
                        <h5>Phone Readings</h5>
                        <table class="table">
                            <tr>
                                <th>Price Rate</th>
                                <th class="text-center" colspan="5">Advertised per minute rate</th>
                            </tr>
                            <tr>
                                <th></th>
                                <th *ngFor="let currency of getRatesInCurrencies()">{{currency}}</th>
                            </tr>
                            <tr *ngFor="let row of rates.callRates; let i = index" [ngClass]="{'active':i===selectedRate}">
                                <td>{{getRate(i)}}</td>
                                <td *ngFor="let item of row.ratesInCurrencies">{{item.rateValue|number:'.1-1'}}</td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-sm-6">
                        <h5>Chat Readings</h5>
                        <table class="table">
                            <tr>
                                <th>Price Rate</th>
                                <th class="text-center" colspan="5">Advertised per minute rate</th>
                            </tr>
                            <tr>
                                <th></th>
                                <th *ngFor="let currency of getRatesInCurrencies()">{{currency}}</th>
                            </tr>
                            <tr *ngFor="let row of rates.chatRates; let i = index" [ngClass]="{'active':i===selectedRate}">
                                <td>{{getRate(i)}}</td>
                                <td *ngFor="let item of row.ratesInCurrencies">{{item.rateValue|number:'.1-1'}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
        </div>
    <br>
    <br>
    <br>
</div>
            <div class="row">
                <div class="col-sm-6">
                    <button class="btn btn-success submit" (click)="validateStep2()">Continue application <span class="fa fa-chevron-right"></span>
                    </button>
                </div>
            </div>
        </div>

        <div [collapse]="step3IsCollapsed">

            <div class="registration-form col-xs-12">
                <h1><b>Reader Application</b></h1>
                <h3 class="purple pull-left" style="margin-bottom: 20px;width:100%;">Thank you for applying for a listing with
                    LifeReader</h3>

                <p>We will now be reviewing your application over the next few days.
                    If your application is successful we will be contacting you personally.
                    Processing of applications can take 14 days or more.
                    You can in the meantime access your account with your email and password
                    but you will not be able to accept any readings as your profile will only be published if your application
                    is approved.
                    You may <a routerLink="/contact">contact</a> us at any time, but due to high demand responses may take up to
                    48 hours during business days.</p>

                <p>Kind regards</p>

                <p>LifeReader</p>

                <a routerLink="/" title="go to homepage">Go to homepage</a>

            </div>
        </div>
    </form>
</div>
