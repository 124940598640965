import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterModule, RouterStateSnapshot} from '@angular/router';
import {EMPTY, forkJoin, Observable, of} from 'rxjs';
import {catchError, concatMap, map, tap} from 'rxjs/operators';
import {Category} from '../entity/category';
import {Reader} from '../modules/shared/entity/reader';
import {ReaderService} from '../modules/shared/services/reader-service';
import {AllEmiterService} from '../services/all-emiter.service';
// import {isScullyRunning} from '@scullyio/ng-lib';
import {UserService} from '../services/user.service';

@Injectable({
    providedIn: 'root'
})
export class ReadersCategoryResolverService implements Resolve<Observable<ReadersCategoryResolverData>> {
    public data: ReadersCategoryResolverData;
    config = { offset: 0,  max: 10, moveBusyReaders: false , categoryId: 1};
    // config2 = { offset: 30,  max: 30, moveBusyReaders: false , categoryId: 1};
    // config3 = { offset: 60,  max: 30, moveBusyReaders: false , categoryId: 1};

    url: string;

    constructor(
        private userService: UserService,
        private readerService: ReaderService,
        private route: ActivatedRoute,
        private router: RouterModule,
        private allEmiterService: AllEmiterService,
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
        this.allEmiterService.onWaitChange(true);
        const self = this;
        self.data = new ReadersCategoryResolverData();
        self.url = state.url;
        return this.readerService.getCategoryList().pipe(
            map((categories) => {
                self.data.categories = categories;
                this.allEmiterService.onWaitChange(false);
                return self.data;
            })
        );


    }

    findIdCategory(categories) {
        const path: string[] = this.getUrlPath();
        const foundParent = categories.find((item) => {
            return item.link === path[0];
        });
        if (foundParent) {
            const foundChild = foundParent.subCategories.find((item) => {
                return item.link === path.join('/');
            });
            return (foundChild) ? foundChild.id : foundParent.id;
        }
        return null;
    }

    getUrlPath() {
        return this.url.split('/').filter(item => item);
    }
}

export class ReadersCategoryResolverData {
    public categories: Category[];
    public readers: Reader[];
    public quantityReaders: number;

    constructor() {
    }
}
