import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {UserService} from '../services/user.service';

@Injectable()
export class UserFinishRegistration implements CanActivate {

    bsModalRef: BsModalRef;

    constructor(
        private userService: UserService,
        private router: Router
    ) {
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const data = await this.userService.getData();
        if (data.role === 'ROLE_CUSTOMER' && data.regStep < 4) {
            await this.router.navigate(['customer/credit-card']);
            return false;
        }
        return true;


    }
}
