import { NgModule } from '@angular/core';
import {FooterComponent} from './footer.component';
import {LoadableModule, matcher} from 'ngx-loadable';
import {InViewportModule} from 'ng-in-viewport';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../modules/shared/shared.module';
import {CommonModule} from '@angular/common';
import {SiteLinksModule} from '../site-links/site-links.module';
import {CookiesBannerModule} from '../../modules/shared/components/cookies-banner/cookies-banner.module';
@NgModule({
    declarations: [FooterComponent],
    bootstrap: [FooterComponent],
    exports: [
        FooterComponent
    ],
    imports: [
        InViewportModule,
        RouterModule,
        // SharedModule,
        CommonModule,
        SiteLinksModule,
        CookiesBannerModule
    ]
})
export class FooterModule { }
