import {Component, Input, OnInit} from '@angular/core';
import {Reader} from '../../entity/reader';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {UserService} from '../../../../services/user.service';
import {ReaderService} from '../../services/reader-service';
import {NGXLogger} from 'ngx-logger';
import {NotifyComponent} from '../../components/notify/notify.component';

@Component({
    selector: 'shared-notify-button',
    templateUrl: './notify-button.component.html',
    styleUrls: ['./notify-button.component.scss'],
})
export class NotifyButtonComponent implements OnInit {

    @Input()
    reader: Reader;

    @Input()
    role = '';

    bsModalRef: BsModalRef;

    /**
     * It is used to determinate where the component is used
     */
    @Input()
    type = '';

    constructor(
        private userService: UserService,
        private modalService: BsModalService,
        private readerService: ReaderService,
        private logger: NGXLogger,
    ) {
    }

    async ngOnInit() {
    }

    isDisabledNotify() {
        // return this.role !== 'ROLE_CUSTOMER';
        return false;
    }

    openNotifyModal() {
        const initialState = {
            readerId: this.reader.id,
        };
        this.bsModalRef = this.modalService.show(NotifyComponent, {
            animated: false,
            initialState: initialState,
            class: 'modal-md',
        });
    }
}
