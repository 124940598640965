import { Injectable, OnDestroy } from '@angular/core';
import {Api} from '../api/api';
import {HttpClient} from '@angular/common/http';
import {SubSink} from 'subsink';
import {CanShowAddFundsDto} from './dto/can-show-add-funds.dto';
@Injectable({
  providedIn: 'root'
})
export class UserNestService implements OnDestroy {

  private subs = new SubSink();

  constructor(private httpClient: HttpClient,
              private api: Api) {
  }

  async  showAddFunds(): Promise<CanShowAddFundsDto> {
    const data: CanShowAddFundsDto = await this.api.get('/users/show-add-funds', null);
    return data;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
