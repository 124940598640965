import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {RegistrationComponent} from '../modules/shared/components/registration/registration.component';
import {UserService} from '../services/user.service';
import {WINDOW} from '../providers/window.provider';

@Injectable()
export class CanActivateAdminUser implements CanActivate {

    bsModalRef: BsModalRef;

    constructor(
        private modalService: BsModalService,
        private userService: UserService,
        @Inject(WINDOW) private window: Window
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.userService.getRole() === 'ROLE_ADMIN') {
           this.window.location.replace('https://www.dev.lifereader.com/admin/livestats');
        }
        if (route.data.roles.indexOf(this.userService.getRole()) !== -1) {
            return true;
        }
        this.bsModalRef = this.modalService.show(RegistrationComponent, {
            class: 'modal-dialog-steps',
            backdrop: true,
            animated: false,
        });
        return false;
    }
}
