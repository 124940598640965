import {Component, OnDestroy, OnInit} from '@angular/core';
import {SubSink} from 'subsink';
import {ReaderService} from '../../../services/reader-service';
import {UserService} from '../../../../../services/user.service';
import {Reader} from '../../../entity/reader';
import {environment} from '../../../../../../environments/environment';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {BackRouter} from '../../../services/back-router';
import {UtilService} from '../../../../../services/util.service';

@Component({
  selector: 'app-feature-readers-modal',
  templateUrl: './feature-readers-modal.component.html',
  styleUrls: ['./feature-readers-modal.component.scss']
})
export class FeatureReadersModalComponent implements OnInit, OnDestroy {

  readers: Reader[];
  featuredReaderInterval: any;
  auxInterval: any;
  private subs = new SubSink();
  userIsLoggedIn = false;
  message: string;

  constructor(
      public br: BackRouter,
      public bsModalRef: BsModalRef,
      private readerService: ReaderService,
      private utilService: UtilService,
      private userService: UserService) {
    this.userIsLoggedIn = this.userService.isLoggedIn();
  }

  ngOnInit(): void {
    const self = this;
    self.utilService.setTimeout(function () {
      self.getReaders();
    }, 10);

    this.subs.sink = this.featuredReaderInterval = self.utilService.setInterval(() => {
      self.subs.sink = self.readerService.getFeaturedReaders().subscribe((data) => {
        // self.splitReaders(data.entities);
        self.readers = data.entities;
      });
    }, environment.reloadReaders);
    self.subs.sink = self.readerService.onUpdateReader.subscribe((reader) => {
      if (self.readers) {
        const index = self.readers.findIndex(r => r.id === reader.id);
        self.readers[index] = reader;
      }
    });
  }


  ngOnDestroy() {
    // clearInterval(this.featuredReaderInterval);
    // clearInterval(this.auxInterval);
    this.subs.unsubscribe();
  }
  getReaders() {
    const self = this;
    this.subs.sink = self.readerService.getFeaturedReaders().subscribe((data) => {
      self.readers = data.entities;
    });
    this.subs.sink = self.readerService.onUpdateReader.subscribe((reader) => {
      if (self.readers) {
        const index = self.readers.findIndex(r => r.id === reader.id);
        self.readers[index] = reader;
      }
    });
  }

  splitReaders(newReaders) {
    const self = this;
    if (newReaders) {
      this.readers = newReaders.slice(0, 1);
      let index = 1;
      this.subs.sink = this.auxInterval = self.utilService.setInterval(function () {

        if (index === newReaders.length) {
          self.utilService.clearInterval(self.auxInterval);
          // clearInterval(self.auxInterval);
          return;
        }
        self.readers.push(newReaders[index]);
        index++;
      }, 10);
    } else {
      this.readers = newReaders;
    }
  }

}
