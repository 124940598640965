<div class="modal-header">
    <button (click)="bsModalRef.hide()" aria-label="Close" class="close" type="button">
        <span>&times;</span>
    </button>
    <b>LifeReader Message</b>
</div>
<div class="modal-body">
    <div *ngFor="let item of messages" class="message">
        <ng-container *ngIf="item.title !== 'showAddFunds'">
            <div class="title-message">
                <span (click)="closeMessage(item)" class="close"><span>&times;</span></span>
                <b>{{item.title}}</b>
            </div>
            <div [innerHTML]="item.message" class="text"></div>
        </ng-container>
        <ng-container *ngIf="item.title === 'showAddFunds'">
            <div class="title-message">
                <span (click)="closeMessage(item)" class="close"><span>&times;</span></span>
                <b></b>
            </div>
            <div class="text">
                This reading ended since your funds are depleted or your credit card refill failed. <br/>
                Please add funds here: <p class='link' (click)='onAddFund();'>Add Funds & Balance</p>
            </div>
        </ng-container>
    </div>
</div>
