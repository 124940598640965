<div *ngIf="isVisible" id="cookies-banner">
    <div>
    <span>
      We use cookies to understand how people come to and use our site and share content through it so we can improve
      everyone’s experience. See our Privacy Policy to learn more.
    <a routerLink="privacy">Learn more</a>
    </span>
    </div>
    <div>
        <button (click)="gotIt()" class="btn">OK, GOT IT</button>
    </div>
</div>
