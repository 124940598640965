import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {BackRouter} from '../modules/shared/services/back-router';
import {Injectable} from '@angular/core';
import {Config} from '../entity/config';

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    constructor(
        private httpClient: HttpClient,
        private br: BackRouter,
    ) {
    }

    getConfig() {
        return this.httpClient.get(this.br.getJavaWebServicesUrl() + '/rest/application/domain/settings', {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((obj: Object) => {
                        return plainToClass(Config, obj);
                    },
                ),
                // ).toPromise();
            );
    }
}
