import {EventEmitter, Injectable} from '@angular/core';
import {Subscription} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AddFundsEmitterService {
    invokeAddFunds = new EventEmitter();
    subsVar: Subscription;

    constructor() {
    }

    onAddFunds(update) {
        this.invokeAddFunds.emit(update);
    }
}
