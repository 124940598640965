<div class="modal-header">
    <button (click)="bsModalRef.hide()" aria-label="Close" class="close" type="button">
        <span>&times;</span>
    </button>
    Reset & recover password
</div>
<div class="modal-body">
    <div *ngIf="success" class="alert alert-info">
        <small><b>Recover password email sent</b> Thank you for recovering your password. Instructions how to reset and
            recover
            your password have been sent to your email.</small>
    </div>
    <form *ngIf="!success" (submit)="doSubmit()" [formGroup]="form">
        <div [ngClass]="{'has-error':hasError('email')}" class="form-group">
            <label>Enter email:</label>
            <input class="form-control" formControlName="email" type="email">
            <small *ngIf="hasError('email')" class="help-block text-danger"><b>Email can not be empty</b> E-Mail: Please
                enter
                a valid email address
            </small>
        </div>
        <div class="form-group">
            <button class="btn btn-def">Recover password</button>
        </div>
    </form>
</div>
