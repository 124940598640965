import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AlertService {

    data: EventEmitter<{ title: string, body: string, type: string }> = new EventEmitter<{ title: string, body: string, type: string }>();

    alert(data: {
        title: string,
        body: string,
        type: string
    }) {
        this.data.emit(data);
    }
}
