import {EventEmitter, Injectable} from '@angular/core';
import {Subscription} from 'rxjs';
import {Currency} from '../entity/currency';
import {UtilService} from './util.service';

@Injectable({
    providedIn: 'root'
})
export class AllEmiterService {
    invokeNewChat = new EventEmitter();
    invokeEndChat = new EventEmitter();
    invokeDeclineChat = new EventEmitter();
    invokeFundsChange = new EventEmitter();
    invokeWait = new EventEmitter();
    invokeLoading = new EventEmitter();
    invokeAddFundsFirstTime = new EventEmitter();
    invokeHideDialog = new EventEmitter();
    invokeLoggedIn = new EventEmitter();
    invokeCloseMenu = new EventEmitter();
    invokeUserLogOut = new EventEmitter();
    invokeReaderWantsChangeStatus = new EventEmitter();
    invokePaygSwitch = new EventEmitter();
    invokeCurrencyChange = new EventEmitter();
    invokeRegistrationStep2 = new EventEmitter();
    invokeCallToReaderFailed = new EventEmitter();
    invokeChangeAvailability = new EventEmitter();
    invokeNewIMail = new EventEmitter();
    invokeFeedBack = new EventEmitter();
    invokeLoadFooter = new EventEmitter();
    invokeConnectToSocketIo = new EventEmitter();


    subsNewChat: Subscription;
    subsEndChat: Subscription;
    subsDeclineChat: Subscription;
    subsFundsChange: Subscription;
    subsWait: Subscription;
    subsLoading: Subscription;
    subsAddFundsFirstTime: Subscription;
    subsHideDialog: Subscription;
    subsLoggedIn: Subscription;
    subsCloseMenu: Subscription;
    subsUserLogOut: Subscription;
    subsReaderWantsChangeStatus: Subscription;
    subsPaygSwitch: Subscription;
    subsCurrencyChange: Subscription;
    subsRegistrationStep2: Subscription;
    subsCallToReaderFailed: Subscription;
    subsChangeAvailability: Subscription;
    subsNewImail: Subscription;
    subsFeedBack: Subscription;
    subsLoadFooter: Subscription;
    subsConnectToSocketIo: Subscription;

    constructor(private utilService: UtilService) {
    }

    onNewChat(chatId) {
        this.invokeNewChat.emit(chatId);
    }

    onEndChat(chatId) {
        this.invokeEndChat.emit(chatId);
    }

    onDeclineChat() {
        this.invokeDeclineChat.emit();
    }

    onFundChange(amount) {
        if (typeof amount === 'string') {
            amount = parseFloat(amount);
        }
        this.invokeFundsChange.emit(amount);
    }

    onWaitChange(data) {
        this.invokeWait.emit(data);
    }

    onLoadingChange(data: boolean) {
        this.invokeLoading.emit(data);
    }

    onAddFundsFirstTime(data: boolean) {
        this.invokeAddFundsFirstTime.emit(data);
    }

    onHideDialog() {
        this.invokeHideDialog.emit();
    }

    onLoggedIn() {
        this.invokeLoggedIn.emit();
    }

    onCloseMenu() {
        this.invokeCloseMenu.emit();
    }

    onUserLogOut() {
        const self = this;
        self.utilService.localStorageRemoveItem('role');
        self.utilService.localStorageRemoveItem('testSound');
        self.utilService.localStorageRemoveItem('currency');
        this.invokeUserLogOut.emit();
    }
    onReaderWantsToChangeStatus(data: string) {
        this.invokeReaderWantsChangeStatus.emit(data);
    }
    onPaygSwitch(state: boolean) {
        this.invokePaygSwitch.emit(state);
    }
    onCurrencyChance(currency: Currency) {
        this.invokeCurrencyChange.emit(currency);
    }
    onStep2Registration() {
        this.invokeRegistrationStep2.emit();
    }
    onCallToReaderFailed() {
        this.invokeCallToReaderFailed.emit();
    }
    onChangeAvailability(availability: any) {
        this.invokeChangeAvailability.emit(availability);
    }
    onNewIMail(threadId) {
        this.invokeNewIMail.emit(threadId);
    }

    onFeedBack() {
        this.invokeFeedBack.emit();
    }

    onLoadFooter() {
        this.invokeLoadFooter.emit();
    }

    onConnectedTosocketIo(){
        this.invokeConnectToSocketIo.emit();
    }

}
