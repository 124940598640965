import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Reader} from '../../../entity/reader';
import {CustomerService} from '../../../../customer/services/customer.service';
import {NGXLogger} from 'ngx-logger';
import {AllEmiterService} from '../../../../../services/all-emiter.service';
import {UserService} from '../../../../../services/user.service';
import {SubSink} from 'subsink';
import {ChatService} from '../../../../../services/nestjs/chat/chat.service';
import {BackRouter} from '../../../services/back-router';
import {CookieService} from 'ng2-cookies';
import {UtilService} from '../../../../../services/util.service';
import {WINDOW} from '../../../../../providers/window.provider';
import {Router} from '@angular/router';
import {AlertService} from '../../../../../services/alert.service';
import {SocketIoService} from '../../../../../services/socket-io.service';
// import * as Sentry from '@sentry/browser';
// import {Severity} from '@sentry/browser';

@Component({
    selector: 'app-chat-modal',
    templateUrl: './chat-modal.component.html',
    styleUrls: ['./chat-modal.component.scss'],
})
export class ChatModalComponent implements OnInit, OnDestroy {
    reader: Reader;
    reading: RequestReadingDTO;
    free: false;
    maxDuration: 0;
    pay: false;
    started = false;
    rejected = false;
    title = 'Chat Confirmation';
    private subs = new SubSink();
    private newWindow = null;
    // Var to do not hide modal too quick when chat is declined
    private chatDeclined = false;

    // public channel = new BroadcastChannel('lifeReader');
    constructor(
        public bsModalRef: BsModalRef,
        private customerService: CustomerService,
        private logger: NGXLogger,
        private allEmiterService: AllEmiterService,
        private userService: UserService,
        private chatService: ChatService,
        private br: BackRouter,
        private cookie: CookieService,
        private utilService: UtilService,
        @Inject(WINDOW) private window: Window,
        private router: Router,
        private alertService: AlertService,
        private socketIoService: SocketIoService
    ) {
    }

    ngOnInit() {
        this.utilService.localStorageSetItem('onChatPage', 'false');
    }

    async doConfirmChat() {
        const userDate = this.userService.getData();
        // Sentry.setUser({ id: userDate.userId + '', username: userDate.nickName});
        // Sentry.addBreadcrumb({message: 'Customer clicks on confirm chat'});
        const self = this;
        this.started = true;
        try {
            // Sentry.addBreadcrumb({message: 'Confirming chat', level: Severity.Debug});
            const res: any = await this.customerService.confirmChat(this.reading.id).toPromise();
            // Sentry.addBreadcrumb({message: 'Chat confirmed', level: Severity.Debug});
            // Save readingId on local storage so we can rejoin the chat in case the user refresh browser
            // Sentry.addBreadcrumb({message: 'Emitting new chat', level: Severity.Debug});
            this.allEmiterService.onNewChat(res.chatId);
            // Sentry.addBreadcrumb({message: 'Setting chat and reading id', level: Severity.Debug, data: {chatId: res.chatId, readingId: this.reading.id}});
            localStorage.setItem('chatId', res.chatId);
            localStorage.setItem('readingId', this.reading.id + '');
            // Sentry.addBreadcrumb({message: 'Checking chat and reading id', level: Severity.Debug, data: {chatId: localStorage.getItem('chatId'), readingId: localStorage.getItem('readingId')}});
            await this.socketIoService.createChatAttemptEvent('chat confirmed');
            // Sentry.addBreadcrumb({message: 'Hiding dialog', level: Severity.Debug});
            this.allEmiterService.onHideDialog();
            // Save current path
            // Sentry.addBreadcrumb({message: 'Setting prev url', level: Severity.Debug, data: {prevUrl: this.router.url}});
            this.utilService.localStorageSetItem('prevUrl', this.router.url);
            // Sentry.addBreadcrumb({message: 'Getting prev url', level: Severity.Debug, data: {prevUrl: localStorage.getItem('prevUrl')}});
            /**
             * There is a problem where some customers are not redirected. Checking the logs for the readingId b
             * we notice that we get the message  ROLE_CUSTOMER (184072) - chat confirmed.
             * If after a while the user is not redirect we can refresh the page
             * */
            this.utilService.setTimeout(() => {
                try {
                    this.socketIoService.sendChatAttemptEvent('TimeOut to redirect customer');
                    // Sentry.addBreadcrumb({message: 'TimeOut to redirect customer', level: Severity.Debug});
                    // If after 5 seconds user is not on the chat page, we refresh the page, that might solve the problem
                    const onChatPage = this.utilService.localStorageGetItem('onChatPage');
                    // Sentry.addBreadcrumb({message: 'Customer on ChatPage', level: Severity.Debug, data: {onChatPage}});
                    if (onChatPage && onChatPage !== 'true') {
                        // Sentry.addBreadcrumb({message: 'Customer was not redirected to chat page', level: Severity.Error, data: {onChatPage}});
                        // Sentry.addBreadcrumb({message: 'Setting prev url again', level: Severity.Debug, data: {prevUrl: this.router.url}});
                        this.utilService.localStorageSetItem('prevUrl', this.router.url);
                        // Sentry.addBreadcrumb({message: 'Getting prev url again', level: Severity.Debug, data: {prevUrl: localStorage.getItem('prevUrl')}});
                        this.window.location.replace('chat');
                    }
                } catch (e) {
                    // Sentry.captureException(e);
                }
            }, 5000);

            /**
             * For an unknown reason, navigateByUrl return an error when is call for the first time after a release.
             * To avoid the error, we can it with a timeout
             */
            this.socketIoService.sendChatAttemptEvent('Navigate customer to chat page');
            // Sentry.addBreadcrumb({message: 'Navigate customer to chat page', level: Severity.Debug});
            setTimeout(() => {
                try {
                    // Sentry.addBreadcrumb({message: 'Setting prev url first try: ', level: Severity.Debug, data: {prevUrl: self.router.url}});
                    self.utilService.localStorageSetItem('prevUrl', self.router.url);
                    // Sentry.addBreadcrumb({message: 'Getting prev url first try', level: Severity.Debug, data: {prevUrl: localStorage.getItem('prevUrl')}});
                    // Sentry.addBreadcrumb({
                    //     message: 'Redirecting customer', level: Severity.Debug, data: {
                    //         currentChat: null,
                    //         showHeader: false,
                    //         showFooter: false,
                    //         chatRunning: true
                    //     }
                    // });
                    // Redirect user to the chat module
                    self.router.navigateByUrl('chat', {
                        state: {
                            currentChat: null,
                            showHeader: false,
                            showFooter: false,
                            chatRunning: true
                        }
                    }).then(() => {
                        self.socketIoService.sendChatAttemptEvent('Already navigate');
                        // Sentry.addBreadcrumb({message: 'Customer was redirected', level: Severity.Debug});
                    }).catch((e) => {
                        // Sentry.addBreadcrumb({message: 'Error redirecting customer', level: Severity.Error});
                        // Sentry.captureException(e);
                        self.logger.error('Error navigating customer');
                        self.socketIoService.sendChatAttemptEvent('Error navigating customer to chat page');
                        try {
                            self.socketIoService.sendChatAttemptEvent(e.message);
                        } catch (e) {
                        }
                        try {
                            self.socketIoService.sendChatAttemptEvent(JSON.parse(e));
                        } catch (e) {
                        }
                        self.logger.error(e);
                    });
                } catch (e) {
                    // Sentry.addBreadcrumb({message: 'Error directing customer to chat', level: Severity.Error});
                    // Sentry.captureException(e);
                }
            });
        } catch (e) {
            // console.og(e);
            this.socketIoService.sendChatAttemptEvent('Error accepting chat');
            await this.socketIoService.sendChatEvent(this.reading.id, this.userService.getData().userId, `CHAT_NO_CONFIRM - ${this.reading.id} - ${e.error}`, 'Customer');
            try {
                this.alertService.alert({
                    title: ``,
                    body: e.error.message,
                    type: 'error',
                });
            } catch (e) {
                // Sentry.captureException(e);
                this.alertService.alert({
                    title: ``,
                    body: 'We could not start te chat, please contact support',
                    type: 'error',
                });
            }
            try {
                await this.socketIoService.sendChatEvent(
                    this.reading.id,
                    this.userService.getData().userId,
                    'ERROR ACCEPTING CHAT ' + e.toString(),
                    'Reader');
            } catch (e) {
                // Sentry.captureException(e);
                this.logger.error('Error sending event');
            }
        }
        await self.bsModalRef.hide();
    }


    reFocus() {
        this.newWindow.focus();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

}
