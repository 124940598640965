import {Injectable} from '@angular/core';

@Injectable()
export class ApplicationStateService {
    get isOnCall(): boolean {
        return this._isOnCall;
    }

    set isOnCall(value: boolean) {
        this._isOnCall = value;
    }

    private isMobileResolution: boolean;
    private _isOnChat = false;
    private _isOnCall = false;

    constructor() {
        if (window.innerWidth < 768) {
            this.isMobileResolution = true;
        } else {
            this.isMobileResolution = false;
        }
    }

    public getIsMobileResolution(): boolean {
        return this.isMobileResolution;
    }


    get isOnChat(): boolean {
        return this._isOnChat;
    }

    set isOnChat(value: boolean) {
        this._isOnChat = value;
    }
}
