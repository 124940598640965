import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {UserService} from '../services/user.service';
import {Reader} from '../modules/shared/entity/reader';
import {Recent} from '../entity/recent';
import {forkJoin, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {FeedbackService} from '../services/feedback.service';
import {ReaderService} from '../modules/shared/services/reader-service';
import {AllEmiterService} from '../services/all-emiter.service';
import {UtilService} from '../services/util.service';

@Injectable({
    providedIn: 'root',
})
export class AdminResolver implements Resolve<any> {

    config = {
        href: '',
        offset: 3,
        max: 6,
    };
    public data: AdminResolverData;

    constructor(
        private userService: UserService,
        private router: Router,
        private feedbackService: FeedbackService,
        private readerService: ReaderService,
        private allEmiterService: AllEmiterService,
        private utilService: UtilService,
    ) {
        // Set reconnecting to false, to avoid issues in case system is trying to reconnect and user refresh the page
        this.utilService.localStorageSetItem('reconnecting', 'false');
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        const self = this;
        self.data = new AdminResolverData();
        const values = forkJoin([
                this.feedbackService.getRecent(),
                this.readerService.getReadersPopular(this.config)
            ]
        ).pipe(
            map(([first, second]) => {
                self.data.feedbackData = first;
                self.data.featuredReaders = second.entities.slice(0, 2);
                self.data.ourReaders = second.entities.slice(2);
                self.data.quantityReaders = second.count;
                this.allEmiterService.onWaitChange(false);
                return self.data;
            })
        );
        return values;
    }
}

export class AdminResolverData {
    public quantityReaders: number;
    public featuredReaders: Reader[];
    public ourReaders: Reader[];
    public feedbackData: Recent[];


    constructor() {
    }
}
