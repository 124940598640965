import { Injectable } from '@angular/core';
import {SubSink} from 'subsink';
import {HttpClient} from '@angular/common/http';
import {Api} from '../api/api';
import {CanShowAddFundsDto} from '../user/dto/can-show-add-funds.dto';
import {UserConsultantSignUpDto} from './dto/user-consultant.sign-up.dto';

@Injectable({
  providedIn: 'root'
})
export class UserConsultantService {

  private subs = new SubSink();

  constructor(private httpClient: HttpClient,
              private api: Api) {
  }

  async  signUp(dto: any): Promise<boolean> {
    try {
      // console.og('Sending form');
      await this.api.postWithFiles('/user-consultant/sign-up', dto);
      return true;
    } catch (e) {
      throw e;
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
