import {Component, OnInit} from '@angular/core';
import {AlertService} from '../../../../../services/alert.service';
import {DynamicScriptLoaderService} from '../../../../../services/dynamic-script-loader.service';
import {ConsultantService} from '../../../../consultant/services/consultant.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
    selector: 'app-notes-modal',
    templateUrl: './notes-modal.component.html',
    styleUrls: ['./notes-modal.component.scss'],
})
export class NotesModalComponent implements OnInit {

    public customerName: string;
    public customerId: number;
    public notes = '';
    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: 'auto',
        minHeight: '0',
        maxHeight: '300px',
        width: 'auto',
        minWidth: '100px',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Enter text here...',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '',
        fonts: [
            {class: 'arial', name: 'Arial'},
            {class: 'times-new-roman', name: 'Times New Roman'},
            {class: 'calibri', name: 'Calibri'},
            {class: 'comic-sans-ms', name: 'Comic Sans MS'}
        ],
        customClasses: [
            {
                name: 'quote',
                class: 'quote',
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: 'titleText',
                class: 'titleText',
                tag: 'h1',
            },
        ],
        sanitize: true,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
            ['undo', 'redo', 'subscript', 'superscript', 'indent', 'outdent', 'insertUnorderedList', 'insertOrderedList'],
            ['customClasses',
                'link',
                'unlink',
                'insertImage',
                'insertVideo',
                'insertHorizontalRule',
                'removeFormat',
                'toggleEditorMode']
        ]
    };


    lock = true;

    constructor(
        public bsModalRef: BsModalRef,
        public alertService: AlertService,
        private dynamicScriptLoader: DynamicScriptLoaderService,
        // public ref: BsModalRef,
        private consultantService: ConsultantService,
    ) {
    }

    ngOnInit() {
        // this.customerName = this.ref.data.customerName;
        // this.customerId = this.ref.data.customerId;
        // this.data = this.ref.data.notes;
    }

    async doSubmit() {
        // this.ref.close();
        this.bsModalRef.hide();
        await this.consultantService.updateNotes(this.customerId, this.notes).toPromise();
        this.alertService.alert({
            type: 'info',
            title: 'Customer notes saved',
            body: 'Notes for customer ' + this.customerName + ' saved',
        });
    }


    close() {
        this.bsModalRef.hide();
    }

    doChange() {
        this.lock = false;
    }
}
