export enum CCState {
    NO_FINISHED = 'NO_FINISHED',
    ADDED = 'ADDED',
    REJECTED = 'REJECTED',
    ERROR = 'ERROR',
    CANCEL = 'CANCEL',
    OFFERS_SHOWN = 'OFFERS_SHOWN',
    OFFERS_NO_SHOWN = 'OFFERS_NO_SHOWN',
    NO_MATCH_STORAGE = 'NO_MATCH_STORAGE'
}
