import {Component, Inject, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {WINDOW} from '../../../../../providers/window.provider';

@Component({
    selector: 'app-notification-modal',
    templateUrl: './notification-modal.component.html',
    styleUrls: ['./notification-modal.component.scss'],
})
export class NotificationModalComponent implements OnInit {

    constructor(
        public bsModalRef: BsModalRef,
        private bsModalService: BsModalService,
        @Inject(WINDOW) private window: Window,
    ) {
    }

    ngOnInit() {
    }

    onClick() {
        if (!this.checkNotifications()) {
            this.window.location.reload();
        } else {
            this.bsModalRef.hide();
        }
    }

    checkNotifications() {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (!isMobile) {
            if (!window.Notification) {
                return false;
            } else {
                // check if permission is already granted
                if (Notification.permission === 'granted') {
                    return true;
                } else {
                    // request permission from user
                    Notification.requestPermission().then(function (p) {
                        if (p === 'granted') {
                            return true;
                        } else {
                            return false;
                        }
                    }).catch(function (e) {
                        console.error(e);
                        return false;
                    });
                }
            }
        } else {
            return true;
        }
    }


}
