import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {UserService} from '../../../../services/user.service';
import {ServerErrors} from '../../helpers/server-errors';
import {FormBuilder, Validators} from '@angular/forms';
import {FormHelper} from '../../helpers/form.helper';
import {AlertService} from '../../../../services/alert.service';
import {finalize} from 'rxjs/operators';
import {MailService} from '../../../consultant/services/mail-service';
import {NGXLogger} from 'ngx-logger';
import {RegistrationComponent} from '../registration/registration.component';
import {SubSink} from 'subsink';

@Component({
    selector: 'app-notify',
    templateUrl: './notify.component.html',
    styleUrls: ['./notify.component.scss'],
})
export class NotifyComponent extends ServerErrors implements OnInit, AfterViewInit,  OnDestroy {
    readerId: number;
    registerModalRef: BsModalRef;
    private subs = new SubSink();

    constructor(
        public bsModalRef: BsModalRef,
        private userService: UserService,
        private fb: FormBuilder,
        private alertService: AlertService,
        private mailService: MailService,
        private logger: NGXLogger,
        private modalService: BsModalService,
    ) {
        super();
    }

    ngOnInit() {
        if (this.isLogged()) {
            this.buildForm();
            return;
        }
        this.buildFormAnonymous();
    }

    ngAfterViewInit() {
        // if (this.isLogged()) {
        //     this.buildForm();
        //     return;
        // }
        // this.buildFormAnonymous();
    }

    buildFormAnonymous() {
        this.form = this.fb.group({
            email: ['', Validators.compose([
                Validators.required,
                Validators.email,
            ])],
            gdprConfirm: [true],
        });
    }

    buildForm() {
        this.form = this.fb.group({
            onGoing: [false, Validators.required],
        });
    }

    isLogged() {
        return this.userService.isLoggedIn();
    }

    notify() {
        const data = {
            readerId: this.readerId,
            onGoing: this.form.controls['onGoing'].value,
        };
        this.subs.sink = this.mailService.addNotification(data)
            .pipe(finalize(() => {
                this.bsModalRef.hide();
            }))
            .subscribe((res) => {
                this.alertService.alert({
                    title: 'Notification created',
                    body: 'An email be sent when this Reader is next online',
                    type: 'info',
                });
            }, (err) => {
                this.alertService.alert({
                    title: err.error.message,
                    body: '',
                    type: 'info',
                });
            });
    }

    notifyAnonymous() {
        FormHelper.touchedForm(this.form);
        if (!this.form.valid) {
            return;
        }

        const req = Object.assign({readerId: this.readerId}, this.form.value);
        this.userService.notifyForAnonymous(req).pipe(finalize(() => {
            this.bsModalRef.hide();
        })).subscribe((res) => {
            this.alertService.alert({
                title: 'Notification created',
                body: 'An email be sent when this Reader is next online',
                type: 'info',
            });
        }, (err) => {
            this.alertService.alert({
                title: err.error.message,
                body: '',
                type: 'info',
            });
        });
    }

    doRegistration() {
        this.bsModalRef.hide();
        this.registerModalRef = this.modalService.show(RegistrationComponent, {
            class: 'modal-dialog-steps',
            backdrop: true,
            animated: false,
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
