import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BackRouter} from '../../../../modules/shared/services/back-router';
import {UserDetails} from '../../../../modules/shared/entity/user-details';
import {SubSink} from 'subsink';
import {EndChatRequestDto} from '../../../../modules/chat/dto/end-chat-request.dto';
import {ChatMessages} from '../../../../entity/chat-messages';
import {Api} from '../../api/api';
import {ManualTopUpDto} from '../../request/manual-top-up.dto';
import {CreateCreditCardLogsDto} from '../cc-attempts/dto/create-credit-card-logs.dto';
import {AddEventCreditCardLogsDto} from '../cc-attempts/dto/add-event-credit-card-logs.dto';
import {NgxLoggerLevel} from 'ngx-logger';
import {UtilService} from '../../../util.service';
import {CCState} from '../cc-attempts/enum/cc-state.enum';
import {CreateAddFundsLogsDto} from './dto/create-add-funds-logs.dto';
import {AddFundCcState} from './enum/add-fund-cc-state.enum';
import {AddFundPaypalState} from './enum/add-fund-paypal-state.enum';
import {AddFundState} from './enum/add-fund-state.enum';
import {UserService} from '../../../user.service';

@Injectable({
    providedIn: 'root',
})
export class AddFundsAttemptsService {

    constructor(
        private utilService: UtilService,
        // private userService: UserService,
                private api: Api) {
    }



    create(message: string) {
        const attempt = this.utilService.localStorageGetItem('addFundsAttempt');
        const dto: CreateAddFundsLogsDto = {
            userId: 0,
            message,
            attempt
        };
        try {
            return this.api.post(
                `/logs/add-funds/`,
                dto
            );
        } catch (e) {
        }
    }

    addEvent(state: AddFundState, extra: any) {
        if(localStorage.getItem('uid') === null) {
            return;
        }
        const attempt = this.utilService.localStorageGetItem('addFundsAttempt');
        const dto: CreateAddFundsLogsDto = {
            userId: 0,
            attempt,
            state:  state,
            extra,
            message: ''
        };
        try {
            return this.api.post(`/logs/add-funds/state`,
                dto
            );
        } catch (e) {
        }
    }


    addEventCC(state: AddFundCcState, extra: any) {
        const attempt = this.utilService.localStorageGetItem('addFundsAttempt');
        const dto: CreateAddFundsLogsDto = {
            userId: 0,
            attempt,
            creditCard:  state,
            extra,
            message: ''
        };
        try {
            return this.api.post(`/logs/add-funds/cc`,
                dto
            );
        } catch (e) {
        }
    }

    addEventPaypal(state: AddFundPaypalState, extra: any) {
        const attempt = this.utilService.localStorageGetItem('addFundsAttempt');
        const dto: CreateAddFundsLogsDto = {
            userId: 0,
            attempt,
            paypal:  state,
            extra,
            message: ''
        };
        try {
            return this.api.post(`/logs/add-funds/paypal`,
                dto
            );
        } catch (e) {
        }
    }
}
