<div *ngIf="open" class="gift-shade heartBeat">
    <span (click)="doClose()" class="close">&times;</span>
    <div class="shade">
        <div class="shade-content" >
            {{reader.nickname}} gifted you {{value}} Free minutes Reading time!
            <div class="text-center">
                <shared-call-button [reader]="reader" [role]="userService.getRole()" [type]="'-gift'"></shared-call-button>
                <shared-book-button [reader]="reader" [role]="userService.getRole()" [type]="'-gift'"></shared-book-button>
                <shared-chat-button [reader]="reader" [role]="userService.getRole()" [type]="'-gift'"></shared-chat-button>
                <shared-busy-button [reader]="reader" [role]="userService.getRole()" [type]="'-gift'"></shared-busy-button>
            </div>
        </div>
    </div>
</div>
