import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BackRouter} from '../modules/shared/services/back-router';
import {map} from 'rxjs/operators';
import {RegistrationStepOneRequest} from '../entity/registration-step-one-request';
import {RegistrationResponse} from '../entity/registration-response';
import {RegistrationStepTwoRequest} from '../entity/registration-step-two-request';
import {plainToClass} from 'class-transformer';
import {RegistrationStepThreeRequest} from '../entity/registration-step-three-request';
import {ConsultantAvatarUpload} from '../modules/shared/entity/consultant-avatar-upload';
import {UtilService} from './util.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        private httpClient: HttpClient,
        private br: BackRouter,
        private utilService: UtilService,
    ) {
    }

    registrationReaderStep1(data) {
        const self = this;
        self.utilService.localStorageSetItem('registration', 'step1');
        return this.httpClient.post(this.br.makeBackUrl(`/rest/user/consultant/registration/step1`),
            data, {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((obj: Object) => {
                        return obj;
                    },
                ),
            );
    }

    registrationReaderStep2(data) {
        return this.httpClient.post(this.br.makeBackUrl(`/rest/user/consultant/registration/step2`),
            data, {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((obj: Object) => {
                        return obj;
                    },
                ),
            );
    }

    registrationReaderAvatar(data) {
        return this.httpClient.post(this.br.makeBackUrl('/rest/user/consultant/registration/step2/avatar'),
            data, {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((obj: Object) => {
                        if (!obj) {
                            return obj;
                        }
                        return plainToClass(ConsultantAvatarUpload, obj);
                    },
                ),
            );
    }

    registrationReaderStep3(data) {
        return this.httpClient.post(this.br.makeBackUrl(`/rest/user/registration/step3`), data, {headers: { ignoreLoadingBar: '' }})
            .pipe(map((obj: { url: string }) => {
                return obj;
            }));
    }

    getRegistrationDataReaderStep3() {
        return this.httpClient.get(this.br.makeBackUrl('/rest/user/registration/step3'), {headers: { ignoreLoadingBar: '' }})
            .pipe(map((obj: { url: string }) => {
                return obj;
            }));
    }

    registrationReaderStep3Stripe(data) {
        return this.httpClient.post(this.br.makeBackUrl('/rest/user/registration/step3/stripe'), data, {headers: { ignoreLoadingBar: '' }})
            .pipe(map((obj: { url: string }) => {
                return obj;
            }));
    }

    doRegistrationStep1(data: RegistrationStepOneRequest) {
        const self = this;
        self.utilService.localStorageSetItem('registration', 'step1');
        return this.httpClient.post(this.br.getJavaWebServicesUrl() + '/rest/user/registration/step1',
            data, {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((obj: Object) => {
                        if (!obj) {
                            return obj;
                        }
                        return plainToClass(RegistrationResponse, obj);
                    },
                ),
            );
    }

    doRegistrationStep2(data: RegistrationStepTwoRequest) {
        return this.httpClient.post(this.br.getJavaWebServicesUrl() + '/rest/user/registration/step2',
            data, {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((obj: Object) => {
                        if (!obj) {
                            return obj;
                        }
                        return plainToClass(RegistrationResponse, obj);
                    },
                ),
            );
        // return null;
    }

    doRegistrationStep3(data: RegistrationStepThreeRequest) {
        return this.httpClient.post(this.br.getJavaWebServicesUrl() + '/rest/user/registration/step3/callback',
            data, {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((obj: Object) => {
                        return obj;
                    },
                ),
            );
    }

    doRegistrationStep3Success(lrToken: string, utm_nooverride: string, result: string, userid: string) {
        return this.httpClient.get(this.br.getJavaWebServicesUrl() +
            `/rest/v1/credit_card/update/success?lrToken=${lrToken}&utm_nooverride=${utm_nooverride}&result=${result}&userid=${userid}`,
            {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((obj: Object) => {
                        return obj;
                    },
                ),
            );
    }

    processCardRegistrationFail(lrToken: string, utm_nooverride: string, result: string, userid: string) {
        // console.og('processCardRegistrationFail', this.br.getJavaWebServicesUrl() + `/rest/v1/credit_card/update/fail?lrToken=${lrToken}&utm_nooverride=${utm_nooverride}&result=${result}&userid=${userid}`);
        return this.httpClient.get(this.br.getJavaWebServicesUrl() +
            `/rest/v1/credit_card/update/fail?lrToken=${lrToken}&utm_nooverride=${utm_nooverride}&result=${result}&userid=${userid}`,
            {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((obj: Object) => {
                        return obj;
                    },
                ),
            ).toPromise();
    }
}
