import {Component, Input, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {Router} from '@angular/router';
import {Reader} from '../../entity/reader';
import {BsModalService} from 'ngx-bootstrap/modal';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {GiftShadeComponent} from '../gift-shade/gift-shade.component';
import {UserService} from '../../../../services/user.service';
import {NGXLogger} from 'ngx-logger';
import {RegistrationComponent} from '../registration/registration.component';
import {CustomerService} from '../../../customer/services/customer.service';
import {AllEmiterService} from '../../../../services/all-emiter.service';
import {SubSink} from 'subsink';
import {BackRouter} from '../../services/back-router';
import {ReaderService} from '../../services/reader-service';

@Component({
    selector: 'shared-reader-card',
    templateUrl: './reader-card.component.html',
    styleUrls: ['./reader-card.component.scss'],
})
export class ReaderCardComponent implements OnInit, OnDestroy {
    @Input()
    vertical = false;
    @Input()
    reader: Reader;
    @Input()
    onlyAvatar = false;
    @Input()
    list = '';
    @Input()
    showInfo = false;
    /**
     * It is used to determinate where the componente is used
     */
    @Input()
    type = '';
    bsModalRef: BsModalRef;
    @ViewChildren(GiftShadeComponent)
    sharedComponents: QueryList<GiftShadeComponent>;
    widthXs = 769;
    currentWidth: number;
    name: string;
    private subs = new SubSink();
    @Input()
    userIsLoggedIn = false;

    customerRole = '';

    /**
     * Var to know from where in Share our readers this component is loaded
     */
    @Input()
    sor = '';

    navigateLink = '';

    constructor(
        private logger: NGXLogger,
        private customerService: CustomerService,
        private bsModalService: BsModalService,
        private userService: UserService,
        private allEmiterService: AllEmiterService,
        private router: Router,
        public br: BackRouter,
        private readerService: ReaderService,
    ) {

        this.subs.sink = this.allEmiterService.subsLoggedIn = this.allEmiterService.invokeLoggedIn.subscribe((data) => {
            this.customerRole = this.userService.getRole();
        });

    }

    ngOnInit() {
        const self = this;
        this.getNavigateLink();
        this.name = this.reader.nickname;
        this.customerRole = this.userService.getRole();
        this.subs.sink = this.readerService.onUpdateReader.subscribe((reader) => {
            if (self.reader.id === reader.id) {
                self.reader = reader;
            }

        });
    }

    // used for the route link
    getNavigateLink() {
        this.navigateLink = '/psychic-reader/' + this.reader.nickname.toLowerCase()
            .replace(/ /g, '-')
            .replace(/\b\w/g, l => l.toUpperCase());
        // return '/psychic-reader/' + this.reader.nickname.toLowerCase()
        //     .replace(/ /g, '-')
        //     .replace(/\b\w/g, l => l.toUpperCase());
    }

    goToProfile() {
        // We must hide the welcome dialog if user clic on reader profile in that dialog
        this.allEmiterService.onHideDialog();
        this.router.navigate(['/psychic-reader/' + this.reader.nickname.toLowerCase()
            .replace(/ /g, '-')
            .replace(/\b\w/g, l => l.toUpperCase())]);
    }


    getDesc() {
        return this.reader.alert
            .replace(/<\/?[^>]+>/ig, ' ')
            .replace(/&nbsp;/ig, ' ')
            .replace(/&quot;/ig, '"')
            .replace(/&amp;/ig, '&');
    }

    // getName() {
    //     this.name = this.reader.nickname;
    //     if ((window).innerWidth < this.widthXs) {
    //         return this.name;
    //     }
    //
    //     return this.name.toUpperCase();
    // }

    doOpenSade() {
        this.sharedComponents.map((item) => {
            item.open = true;
        });
    }

    // @HostListener('window:resize', ['$event'])
    // onResize(event) {
    //     this.name = (event.target.innerWidth < this.widthXs) ? this.reader.nickname : this.reader.nickname.toUpperCase();
    // }


    addFav() {
        if (!this.userService.isLoggedIn()) {
            this.bsModalService.show(RegistrationComponent, {
                class: 'modal-dialog-steps',
                backdrop: true,
                keyboard: true,
                animated: false,
            });
            return;
        }

        if (this.userService.getRole() !== 'ROLE_CUSTOMER') {
            return;
        }

        this.subs.sink = this.customerService.addReaderFavorite({readerId: this.reader.id}).subscribe((res) => {
            this.reader.favorite = res.favourite;
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
