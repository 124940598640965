import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BackRouter} from '../../../../modules/shared/services/back-router';
import {UserDetails} from '../../../../modules/shared/entity/user-details';
import {SubSink} from 'subsink';
import {EndChatRequestDto} from '../../../../modules/chat/dto/end-chat-request.dto';
import {ChatMessages} from '../../../../entity/chat-messages';
import {Api} from '../../api/api';
import {ManualTopUpDto} from '../../request/manual-top-up.dto';
import {CreateCreditCardLogsDto} from './dto/create-credit-card-logs.dto';
import {AddEventCreditCardLogsDto} from './dto/add-event-credit-card-logs.dto';
import {NgxLoggerLevel} from 'ngx-logger';
import {UtilService} from '../../../util.service';
import {CCState} from './enum/cc-state.enum';

@Injectable({
    providedIn: 'root',
})
export class CcAttemptsService{

    constructor(private utilService: UtilService,
                private api: Api) {
    }



    createLogCC(message: string, level: NgxLoggerLevel) {
        const attempt = this.utilService.localStorageGetItem('ccAttempt');
        const dto: CreateCreditCardLogsDto = {
            userId: 0,
            message,
            additional: [],
            level: level,
            attempt
        };
        return this.api.postWithoutPromise(
            `/cc-attempts/credit-card/`,
            dto
        );
    }


    addEventCC(message: string, level: NgxLoggerLevel, error?) {
        const attempt = this.utilService.localStorageGetItem('ccAttempt');
        const dto: CreateCreditCardLogsDto = {
            userId: 0,
            message,
            additional: error,
            level: level,
            attempt
        };
        return this.api.postWithoutPromise(`/cc-attempts/add-event-cc/`,
            dto
        );
    }

    resultCC(state: CCState) {
        const attempt = this.utilService.localStorageGetItem('ccAttempt');
        const dto: CreateCreditCardLogsDto = {
            userId: 0,
            message: '',
            additional: [],
            level: 0,
            attempt,
            state
        };
        this.utilService.localStorageRemoveItem('creditCard');
        return this.api.postWithoutPromise(`/cc-attempts/result-cc/`,
            dto
        );
    }

}
