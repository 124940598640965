import 'reflect-metadata';
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';



if (environment.production) {
    Sentry.init({
        dsn: 'https://808860d3fcd8034cff56e7ff67206569@o382013.ingest.sentry.io/4505626565017600',
        integrations: [],
        // Performance Monitoring
        tracesSampleRate: 1, // Capture 100% of the transactions, reduce in production!
        environment: environment.name,
        release: 'javascript-angular@' + environment.appVersion,
        ignoreErrors: [
            'Non-Error exception captured',
            'Non-Error promise rejection captured',
            'audioVolumeChange',
            'fireChangeEvent',
            'fireReadyEvent',
            'setCurrentPosition',
            'fireEvent',
            'Chartboost',
            'setIsViewable',
            /^Unexpected token.*/,
            'domInteractive',
            'setDefaultPosition',
            'setMaxSize',
            'setScreenSize',
        ]
    });
    enableProdMode();
}


platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(success => {
        // console.og(`Bootstrap success`);
    })
    .catch(err => console.error(err));
