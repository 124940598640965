import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {environment} from '../../environments/environment';
import { NGXLogger } from 'ngx-logger';
interface Scripts {
  id: string;
  name: string;
  src: string;
}
interface Styles {
  id: string;
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [
  { id: 'ze-snippet', name: 'zdesk', src: 'https://static.zdassets.com/ekr/snippet.js?key=4a4ee41f-7cca-4596-9e90-26f03a3fb035' },
];
export const StyleStore: Styles[] = [
  { id: 'bootstrap-switch', name: 'bootstrapSwitch', src: 'assets/css/bootstrap-switch.css' },
  // { id: 'fonts-google', name: 'fontsGoogleApi', src: 'https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,300i,700|Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&amp;display=swap' },
  { id: 'fonts-icomoon', name: 'fontsIcomoon', src: 'assets/icomoon.ttf?e9emqf' },
];

// declare var document: any;

@Injectable({
  providedIn: 'root'
})
export class DynamicScriptLoaderService {

  private scripts: any = {};
  private styles: any = {};
  constructor(@Inject(DOCUMENT) private document: Document, private logger: NGXLogger) {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
        id: script.id,
      };
    });
    StyleStore.forEach((style: any) => {
      this.styles[style.name] = {
        loaded: false,
        src: style.src,
        id: style.id,
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }
  loadCss(...styles: string[]) {
    const promises: any[] = [];
    styles.forEach((style) => promises.push(this.loadStyle(style)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[name].loaded) {
        // load script
        const script = this.document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = this.scripts[name].src;
        script.id = this.scripts[name].id;
        // if (script.readyState) {  // IE
        //   script.onreadystatechange = () => {
        //     if (script.readyState === 'loaded' || script.readyState === 'complete') {
        //       script.onreadystatechange = null;
        //       this.scripts[name].loaded = true;
        //       resolve({script: name, loaded: true, status: 'Loaded'});
        //     }
        //   };
        // } else {  // Others
        //   script.onload = () => {
        //     this.scripts[name].loaded = true;
        //     resolve({script: name, loaded: true, status: 'Loaded'});
        //   };
        // }
        script.onload = () => {
          this.scripts[name].loaded = true;
          resolve({script: name, loaded: true, status: 'Loaded'});
        };
        script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
        this.document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
    });
  }

  loadStyle(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.styles[name].loaded) {
        // load script
        const style = this.document.createElement('link');
        style.href = this.styles[name].src;
        style.id = this.styles[name].id;
        style.rel = 'stylesheet';
        // @ts-ignore
        style.async = '';
        style.onload = () => {
          this.styles[name].loaded = true;
          resolve({style: name, loaded: true, status: 'Loaded'});
        };
        style.onerror = (error: any) => resolve({style: name, loaded: false, status: 'Loaded'});
        this.document.getElementsByTagName('head')[0].appendChild(style);
      } else {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
    });
  }
}
