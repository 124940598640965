import { Injectable } from '@angular/core';
import {Api} from '../api/api';
import {HttpClient} from '@angular/common/http';
import {CreateOrderDto} from './dto/create-order.dto';
import {CaptureOrderDto} from './dto/capture-order.dto';
@Injectable({
  providedIn: 'root'
})
export class PaypalNestService {


  constructor(private httpClient: HttpClient,
              private api: Api) {
  }

  async createOrder(promotionId: number): Promise<CreateOrderDto> {
    const data: CreateOrderDto = await this.api.get('/paypal/order/create/' + promotionId, null);
    return data;
  }

  async captureOrder(orderId: number, payerId: string, facilitatorAccessToken: string): Promise<CaptureOrderDto> {
    const url = `/paypal/order/capture/${orderId}/${payerId}/${facilitatorAccessToken}`;
    const data: CaptureOrderDto = await this.api.get(url, null);
    return data;
  }


}
