import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Reader} from '../../entity/reader';
import {ChatModalComponent} from '../../components/modals/chat-modal/chat-modal.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {UserService} from '../../../../services/user.service';
import {ReaderService} from '../../services/reader-service';
import {NGXLogger} from 'ngx-logger';
import {PaymentsModalComponent} from '../../components/modals/payments-modal/payments-modal.component';
import {PromotiosResolverService} from '../../../../resolvers/promotios-resolver.service';
import {AlertService} from '../../../../services/alert.service';
import {SubSink} from 'subsink';
import {AllEmiterService} from '../../../../services/all-emiter.service';
// import * as Sentry from '@sentry/browser';
import {environment} from '../../../../../environments/environment';
import {Subscriber} from 'rxjs';
import {GoogleAnalyticsEventsService} from '../../../../services/google-analytics-events.service';
import {ApplicationStateService} from '../../../../services/application.state.service';
import {UtilService} from '../../../../services/util.service';

@Component({
    selector: 'shared-chat-button',
    templateUrl: './chat-button.component.html',
    styleUrls: ['./chat-button.component.scss'],
})
export class ChatButtonComponent implements OnInit, OnDestroy {

    loginModalRef: BsModalRef;
    registerModalRef: BsModalRef;
    @Input()
    reader: Reader;
    /**
     * It is used to determinate where the component is used
     */
    @Input()
    type: any;
    @Input()
    role = '';
    /**
     * Modal that ask for confirmation.
     * This modal musst be close once the chat is finished
     */
    chatModal;
    private subs = new SubSink();
    doSignInSubscriber: Subscriber<any>;
    doRegisterSubscriber: Subscriber<any>;
    constructor(
        private userService: UserService,
        private readerService: ReaderService,
        private logger: NGXLogger,
        private promotiosService: PromotiosResolverService,
        private bsModalService: BsModalService,
        private alertService: AlertService,
        private allEmiterService: AllEmiterService,
        private modalService: BsModalService,
        private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
        public applicationStateService: ApplicationStateService,
        private utilService: UtilService,
        private cd: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
    }


    async doChat() {
        if (!this.applicationStateService.isOnChat) {
            this.applicationStateService.isOnChat = true;
            this.cd.markForCheck();
            const any: any = await this.userService.checkRegistration();
            if (any !== true) {
                await this.googleAnalyticsEventsService.visitorReadingAttempt(this.reader.id, 'chat');
                this.readerService.saveSelectedReaderLocalStorage(this.reader.id);
                this.subs.sink = this.doSignInSubscriber = any.content.doSignIn.subscribe(async () => {
                    await this.doLogin();
                });
                this.applicationStateService.isOnChat = false;
                this.cd.markForCheck();
                return;
            }
            const data = await this.userService.getDetails().toPromise();
            if (data && data.blocked === 'UNBLOCKED') {
                await this.googleAnalyticsEventsService.customerReadingAttempt(this.reader.id, 'chat');
                const self = this;
                if (await this.userService.checkRegistration() !== true) {
                    this.applicationStateService.isOnChat = false;
                    return;
                }
                // Check if is first time user is going to add funds
                if (data.fullBalance < this.reader.chatRates.chatRate) {
                    if (await this.userService.firstTimeAddingFunds() === false) {
                        this.applicationStateService.isOnChat = false;
                        this.cd.markForCheck();
                        return;
                    }
                }

                try {
                    if (data.fullBalance < this.reader.chatRates.chatRate && !data.paygOn) {
                        await this.userService.showAddFunds('Do Chat');
                        this.applicationStateService.isOnChat = false;
                        this.cd.markForCheck();
                        return;
                    }
                    if (this.userService.getRole() === 'ROLE_CUSTOMER') {

                        this.subs.sink = this.readerService.startReading({
                            readerId: this.reader.id,
                            readingType: 'Chat',
                        }).subscribe((res: RequestReadingDTO) => {
                            const initialState = {
                                reader: this.reader,
                                reading: res
                            };
                            self.chatModal = this.bsModalService.show(ChatModalComponent, {
                                class: 'modal-sm',
                                initialState: initialState,
                                backdrop: 'static',
                                animated: false,
                            });
                            this.applicationStateService.isOnChat = false;
                            this.cd.markForCheck();
                        }, (err: any) => {
                            this.applicationStateService.isOnChat = false;
                            this.cd.markForCheck();
                            if (err.status === 400) {
                                this.alertService.alert({
                                    title: ``,
                                    body: err.error.message,
                                    type: 'info',
                                });
                            }
                        });
                    } else {
                        this.applicationStateService.isOnChat = false;
                        this.cd.markForCheck();
                    }
                } catch (e) {
                    this.applicationStateService.isOnChat = false;
                    this.cd.markForCheck();
                    console.error(e);
                }
            } else {
                this.applicationStateService.isOnChat = false;
                this.cd.markForCheck();

                this.alertService.alert({
                    title: ``,
                    body: 'We are sorry but no readings are possible at this time. Please contact support.',
                    type: 'info',
                });
            }
        }




    }

    async doLogin() {
        // https://lifereader.atlassian.net/browse/LA-1021
        const component: any = await import('../../components/login-form/login-form.component').then(cp => cp.LoginFormComponent);
        this.loginModalRef = this.modalService.show(component, {
            animated: false,
            backdrop: true,
            keyboard: false
        });
        this.subs.sink = this.doRegisterSubscriber = this.loginModalRef.content.doRegister.subscribe(async () => {
            await this.doRegister();
        });
    }

    async doRegister() {
        const component: any = await import('../../components/registration/registration.component').then(cp => cp.RegistrationComponent);
        this.registerModalRef = this.modalService.show(component, {
            animated: false,
            class: 'modal-dialog-steps',
            backdrop: true,
            keyboard: true
        });
        this.subs.sink = this.doSignInSubscriber = this.registerModalRef.content.doSignIn.subscribe(async () => {
            await this.doLogin();
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }


}
