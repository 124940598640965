import {BrowserModule, BrowserTransferStateModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthInterceptor, DEFAULT_TIMEOUT} from './interceptors/auth-interceptor.service';
import {FeedbackService} from './services/feedback.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CookieService} from 'ng2-cookies';
import {AlertModule} from 'ngx-bootstrap/alert';
import {CollapseModule, } from 'ngx-bootstrap/collapse';
import {ModalModule} from 'ngx-bootstrap/modal';
import {CanActivateUser} from './resolvers/can-activate-user';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AlertComponent} from './components/alert/alert.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {ApplicationStateService} from './services/application.state.service';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {CanActivateAdminUser} from './resolvers/can-activate-admin-user';
import {WINDOW_PROVIDERS} from './providers/window.provider';
import {environment} from '../environments/environment';
import {ArticlesComponent} from './components/articles/articles.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {CheckForUpdateService} from './services/check-for-update.service';
import {LoadableModule, matcher} from 'ngx-loadable';
import {InViewportModule} from 'ng-in-viewport';
import {ReaderRegistrationStep2Component} from './components/reader-registration-step2/reader-registration-step2.component';
import {TextFieldModule} from '@angular/cdk/text-field';
import {ProtectedComponent} from './components/protected/protected.component';
import {UserFinishRegistration} from './resolvers/user-finish-registration';


import {UtilService} from './services/util.service';
import {Api} from './services/nestjs/api/api';
import {FooterModule} from './components/footer/footer.module';
import { LoggerModule } from 'ngx-logger';
import {GlobalErrorHandler} from './providers/global-error-handler';
// import * as Sentry from "@sentry/angular-ivy";
import {RecaptchaLoaderService} from 'ng-recaptcha';
import {CanActivateReader} from './resolvers/can-activate-reader';
@NgModule({
    declarations: [
        AppComponent,
        ArticlesComponent,
        ProtectedComponent,
        AlertComponent,
        ReaderRegistrationStep2Component,
    ],
    imports: [
        // SharedModule,
        BrowserModule.withServerTransition({appId: 'LifeReader'}),
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ModalModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        AlertModule.forRoot(),
        CollapseModule.forRoot(),
        LazyLoadImageModule,
        // for HttpClient use:
        LoadingBarHttpClientModule,
        // for Router use:
        LoadingBarRouterModule,
        // NgxGoogleAnalyticsModule.forRoot(environment.google.analytics.key),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            registrationStrategy: 'registerImmediately'
        }),
        LoadableModule.forRoot({
            moduleConfigs: [
                {
                    name: 'header',
                    loadChildren: () => import('./components/header/header.module').then(m => m.HeaderModule),
                    matcher
                },
            ]
        }),
        InViewportModule,
        TextFieldModule,
        BrowserTransferStateModule,
        FooterModule,
        LoggerModule.forRoot({
            level: environment.logger.level
        }),

    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: DEFAULT_TIMEOUT, useValue: 15000},
        {provide: ErrorHandler, useClass: GlobalErrorHandler},
        FeedbackService,
        CookieService,
        CanActivateUser,
        CanActivateReader,
        UserFinishRegistration,
        CanActivateAdminUser,
        ApplicationStateService,
        WINDOW_PROVIDERS,
        {provide: 'googleTagManagerId', useValue: environment.google.tagManager.key},
        CheckForUpdateService,
        // LogUpdateService,
        // PromptUpdateService,
        UtilService,
        Api,
        RecaptchaLoaderService,
        // {
        //     provide: Sentry.TraceService,
        //     deps: [Router],
        // },
    ],
    bootstrap: [
        AppComponent,
    ],
    exports: [
    ]
})
export class AppModule {
    // constructor(trace: Sentry.TraceService) {}
}
