import {Component, OnDestroy, OnInit} from '@angular/core';
import {CustomerService} from '../../../../customer/services/customer.service';
import {Reader} from '../../../entity/reader';
import {NGXLogger} from 'ngx-logger';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {AlertService} from '../../../../../services/alert.service';
import {SubSink} from 'subsink';
import {Phone, PhoneTypes} from '../../../../consultant/entity/phone';
import {AccountService} from '../../../../consultant/services/account.service';
import {finalize} from 'rxjs/operators';
import {SCREEN_SIZE} from '../../../../../screen-size.enum';
import {ResizeService} from '../../../../../services/resize.service';
import {PhoneEmitterService} from '../../../../../services/phone-emitter.service';
import {UtilService} from '../../../../../services/util.service';

@Component({
    selector: 'app-book-modal',
    templateUrl: './book-modal.component.html',
    styleUrls: ['./book-modal.component.scss'],
})
export class BookModalComponent implements OnInit, OnDestroy {
    reader: Reader;
    data;
    selectedItem;
    private subs = new SubSink();
    defaultPhone: String;
    countries: any = [];
    newPhoneNumber: string;
    countryCode: number;
    size: SCREEN_SIZE;
    booking = false;
    constructor(
        public bsModalRef: BsModalRef,
        private customerService: CustomerService,
        private alertService: AlertService,
        private logger: NGXLogger,
        private accountService: AccountService,
        private resizeSvc: ResizeService,
        private phoneEmitterService: PhoneEmitterService,
        private utilService: UtilService,
    ) {
    }

    ngOnInit() {
        const self = this;
        this.getData();
        this.subs.sink = this.resizeSvc.onResize$.subscribe(x => {
            self.size = x;
        });
    }

    getData() {
        this.subs.sink = this.customerService.getIntervals().subscribe((res) => {
            this.data = res;

            this.selectedItem = this.data[0];
        });
        this.subs.sink = this.accountService.getPhones().subscribe(async (data: Phone[]) => {
            data.forEach((phone) => {
                if (phone.defaultPhone === true) {
                    this.defaultPhone = phone.number;
                }
            });

        });
        this.subs.sink = this.accountService.getCountries().subscribe((data) => {
            this.countries = data;
        });
    }

    doSubmit() {
        this.booking = true;
        this.subs.sink = this.customerService.bookCallback({
            readerId: this.reader.id,
            interval: this.selectedItem.totalMinutes,
        }).subscribe((res) => {
            this.booking = false;
            this.bsModalRef.hide();
            this.alertService.alert({
                title: 'Callback Booked',
                body: `Your callback booking with ${this.reader.nickname} has been registered. We have sent ${this.reader.nickname} a message for this booking.`,
                type: 'info',
            });
        }, (err) => {
            let message = 'You already have a pending Callback lodged with this Reader.';
            if (err.error.message) {
                message = err.error.message;
            }
            this.booking = false;
            this.alertService.alert({
                title: 'Callback',
                body: message,
                type: 'info',
            });
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    getCountryCode() {
        const self = this;
        this.countryCode = Number(self.utilService.localStorageGetItem('countryId'));
        return this.countries.find(item => item.id === this.countryCode).phoneCode;
    }

    validatePhoneNumber(countryCode: string) {

        countryCode = countryCode.replace('+', '');
        let phoneNumber = this.newPhoneNumber;
        // Remove + symbols
        phoneNumber = phoneNumber.replace('+', '');
        phoneNumber = phoneNumber.replace(' ', '');
        // If country code is at the begging of the phone number should be remove (64)
        // log(phoneNumber.substr(0, countryCode.length), countryCode);
        if (phoneNumber.substr(0, countryCode.length) === countryCode) {
            phoneNumber = phoneNumber.substr(countryCode.length);
        }
        // Same previous validation but we add an 0 to the country code (064)
        countryCode = '0' + countryCode;
        if (phoneNumber.substr(0, countryCode.length) === countryCode) {
            phoneNumber = phoneNumber.substr(countryCode.length);
        }
        // Same previous validation but we add an 0 to the country code (0064)
        countryCode = '00' + countryCode;
        if (phoneNumber.substr(0, countryCode.length) === countryCode) {
            phoneNumber = phoneNumber.substr(countryCode.length);
        }
        this.newPhoneNumber = phoneNumber;
    }
    doSave() {
        const self = this;
        this.countryCode = Number(self.utilService.localStorageGetItem('countryId'));
        const data = {
            phones: [
                {
                    number: this.newPhoneNumber,
                    countryCode: this.countryCode,
                    phoneType: PhoneTypes.home,
                    defaultPhone: true
                }
            ],
        };

        this.subs.sink = this.accountService.updatePhones(data).pipe(finalize(() => {
        }))
            .subscribe((res) => {
                    this.data = res;
                    this.phoneChanged();
                    this.defaultPhone = this.newPhoneNumber;
                    this.alertService.alert({
                        title: `Account Details Updated`,
                        body: `Your phone details were updated`,
                        type: 'info',
                    });

                }, err => {
                    this.alertService.alert({
                        title: `Error Updating`,
                        body: `Please try updating your phone details later`,
                        type: 'error',
                    });
                }
            );
    }

    phoneChanged() {
        this.phoneEmitterService.phoneChanged();
    }
}
