import {Component, OnDestroy, OnInit} from '@angular/core';
import {AlertService} from '../../services/alert.service';
import {SubSink} from 'subsink';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit, OnDestroy {
    defaultAlerts: { body: string, title: string, type: string }[] = [];
    alerts = this.defaultAlerts;
    private subs = new SubSink();

    constructor(
        private alertService: AlertService,
    ) {
    }

    ngOnInit() {
        this.subs.sink = this.alertService.data.subscribe((data) => {
            // console.og('New alert', data);
            this.alerts = [data];
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
