import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ServerErrors} from '../../modules/shared/helpers/server-errors';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NGXLogger} from 'ngx-logger';
import {FormHelper} from '../../modules/shared/helpers/form.helper';
import {AccountService} from '../../modules/consultant/services/account.service';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {SubSink} from 'subsink';
import {GoogleAnalyticsEventsService} from '../../services/google-analytics-events.service';
import {UtilService} from '../../services/util.service';
import {AllEmiterService} from '../../services/all-emiter.service';
import {AlertService} from '../../services/alert.service';
import {environment} from '../../../environments/environment';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {HoroscopeService} from '../../modules/horoscope/services/horoscope.service';
import {DOCUMENT, ViewportScroller} from '@angular/common';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {ConsultantAvatarUpload} from '../../modules/shared/entity/consultant-avatar-upload';
import {catchError, tap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {CountryResponse} from '../../modules/consultant/entity/country-response';
import {UserConsultantService} from '../../services/nestjs/user-consultant/user-consultant.service';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'app-reader-application',
    templateUrl: './reader-application.component.html',
    styleUrls: ['./reader-application.component.scss'],
})
export class ReaderApplicationComponent extends ServerErrors implements OnInit, OnDestroy, AfterViewInit {

    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private logger: NGXLogger,
        private accountService: AccountService,
        private router: Router,
        private gAE: GoogleAnalyticsEventsService,
        private utilService: UtilService,
        private allEmiterService: AllEmiterService,
        private alertService: AlertService,
        private hs: HoroscopeService,
        private userConsultantService: UserConsultantService,
        private viewportScroller: ViewportScroller,
        @Inject(DOCUMENT) private document: Document,
    ) {
        super();
    }
    months = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'];
    titles = [];
    countries: CountryResponse[];
    signs;
    languages;
    categories;
    rates;
    selectedRate = 0;
    finished = false;
    phoneCode = '+XX';
    phoneCodeHome = '+XX';
    phoneCodeWork = '+XX';
    phoneCodeMobile = '+XX';
    @ViewChild('autosize')
    autosize: CdkTextareaAutosize;
    private subs = new SubSink();
    selectedFile: File | null = null;
    fileError: string | null = null;
    //endregion
    step1IsCollapsed = false;
    step2IsCollapsed = true;
    step3IsCollapsed = true;

    ngOnInit() {
        this.logger.debug('ng on init reader aapplication');
        this.gAE.readerRegistrationStart();


        this.subs.sink = forkJoin({

            titles: this.accountService.getTitleList().pipe(
                catchError(error => {
                    console.error('Error fetching titles', error);
                    return of([]);  // Return an empty array on error
                })
            ),
            countries: this.accountService.getCountries().pipe(
                catchError(error => {
                    console.error('Error fetching countries', error);
                    return of([]);  // Return an empty array on error
                })
            ),
            languages: this.accountService.getLanguages().pipe(
                catchError(error => {
                    console.error('Error fetching languages', error);
                    return of([]);  // Return an empty array on error
                })
            ),
            categories: this.accountService.getCategories().pipe(
                catchError(error => {
                    console.error('Error fetching categories', error);
                    return of([]);  // Return an empty array on error
                })
            ),
            rates: this.accountService.getRates().pipe(
                catchError(error => {
                    console.error('Error fetching rates', error);
                    return of([]);  // Return an empty array on error
                })
            )
        }).subscribe({
            next: (res: {titles, countries, languages, categories, rates}) => {
                // console.og('All observables emitted:', res);
                this.titles = res.titles;
                this.countries = res.countries;
                this.languages = res.languages;
                this.categories = res.categories;
                this.rates = res.rates;
                this.buildForm();
            },
            error: (err) => {
                console.error('Error in forkJoin', err);
            },
            complete: () => {
                // console.og('forkJoin completed');
            }
        });

        this.subs.sink = this.hs.getSigns().subscribe((res) => {
            this.signs = res;
        });
    }

    buildForm() {
        this.logger.debug('Building form');
        let random = null;
        let randomNumber = null;
        let randomPass = null;
        let randomEmail = null;
        if (environment.test.randomData) {
            random = Math.random().toString(36).slice(6, 12);
            randomEmail = Math.random().toString(36).slice(6, 12) + '@lr.com';
            randomPass = Math.random().toString(36).slice(0, 8);
            randomNumber = '';
        }
        this.form = this.fb.group({
            step1: this.fb.group({
                firstName: [random, [Validators.required, Validators.maxLength(50)]],
                lastName: [random, [Validators.required, Validators.maxLength(50)]],
                // tslint:disable-next-line:max-line-length
                phone: [randomNumber, [Validators.required, Validators.pattern(/^[0-9]+$/)]], // Example pattern for 10-digit phone numbers
                address: [random, [Validators.required, Validators.maxLength(100)]],
                birthdayDay: ['', [Validators.required]], // Day validation (1-31)
                birthdayMonth: ['', [Validators.required]], // Month validation (1-12)
                birthdayYear: ['', [Validators.required]], // Year validation (YYYY)
                city: [random, [Validators.required, Validators.maxLength(50)]],
                confirmPassword: [randomPass, [Validators.required]],
                country: ['', [Validators.required]],
                email: [randomEmail, [Validators.required, Validators.email]],
                password: [randomPass, [Validators.required, Validators.minLength(8)]], // Minimum length for password
                postCode: [random, [Validators.required]], // Example pattern for 5-digit post code
                titleId: ['', [Validators.required]],
                userName: [random, [Validators.required, Validators.minLength(5), Validators.maxLength(20)]], // Username length validation
                gdprAccepted: [, [Validators.requiredTrue]], // Checkbox should be true to be valid
            }),
            step2: this.fb.group({
                shortDescription: [random, [ Validators.maxLength(200)]],
                aboutMe: [random, [Validators.required, Validators.maxLength(2000)]],
                experience: [random, [Validators.required]],
                primaryLanguageId: [1],
                zodiak: [1, [Validators.required]],
                otherLang: [''], // Optional field
                rateId: [1, [Validators.required]],
                searchTags: [''], // Optional field
                callAvailable: [true],
                chatAvailable: [true],
                homePhone: [randomNumber, [Validators.pattern(/^[0-9]+$/)]], // Allow only numbers
                homePhoneCountry: ['', [Validators.required]],
                workPhone: ['', [Validators.pattern(/^[0-9]+$/)]], // Optional field with number validation
                workPhoneCountry: [''],
                mobilePhone: ['', [Validators.pattern(/^[0-9]+$/)]], // Optional field with number validation
                mobilePhoneCountry: [''],
                categoriesCheckMap: ['']
            })
        }, { updateOn: 'blur' }); // Update form validation on blur event
        this.form.get('step1.phone').disable();
        this.form.get('step2.homePhone').disable();
        this.form.get('step2.workPhone').disable();
        this.form.get('step2.mobilePhone').disable();
    }

    doSubmit() {
        const self = this;
        FormHelper.touchedForm(this.form);
        if (!this.form.valid) {
            return;
        }

        this.subs.sink = this.authService.registrationReaderStep1(this.form.value).subscribe((res: any) => {
            /**
             * It means it worked, and now we need this token to be able to do step 2
             */

            if (res.token) {
                localStorage.setItem('token-registration', res.token);
            }
            self.utilService.localStorageRemoveItem('registration');
            this.router.navigate(['apply/reader-step2']);
        }, (res: any) => {
            if (res.error) {
                if (res.error.additionalFields) {
                    Object.entries(res.error.additionalFields).forEach(([key, value]) => {
                        this.setServerError(key, value);
                    });
                }

                if (res.error.message) {
                    this.alertService.alert({
                        title: '',
                        body: res.error.message,
                        type: 'error',
                    });
                }
            }


        });
    }

    //region Step 1
    getDays() {
        const days: number[] = [];
        for (let i = 1; i <= 31; i++) {
            days.push(i);
        }
        return days;
    }

    getYears() {
        const years = [];
        for (let i = 1920; i <= 2001; i++) {
            years.push(i);
        }
        return years.sort((a, b) => {
            return b - a;
        });
    }

    getMonths() {
        return this.months.map(item => item.slice(0, 3));
    }


    getCountry(id): CountryResponse {
        return this.countries.find(el => el.id == id);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    ngAfterViewInit() {
        this.allEmiterService.onLoadFooter();
    }

    validatePhoneNumber(countryCode: string, formControl: AbstractControl) {

        countryCode = countryCode.replace('+', '');
        let phoneNumber = formControl.value + '';
        if (phoneNumber) {
            this.logger.debug(`Validating phone number [${countryCode}][${phoneNumber}]`);
            // Remove + symbols
            phoneNumber = phoneNumber.replace('+', '');
            phoneNumber = phoneNumber.replace(' ', '');
            // If country code is at the begging of the phone number should be remove (64)
            if (phoneNumber.substr(0, countryCode.length) === countryCode) {
                phoneNumber = phoneNumber.substr(countryCode.length);
            }
            // Same previous validation but we add an 0 to the country code (064)
            countryCode = '0' + countryCode;
            if (phoneNumber.substr(0, countryCode.length) === countryCode) {
                phoneNumber = phoneNumber.substr(countryCode.length);
            }
            // Same previous validation but we add an 0 to the country code (0064)
            countryCode = '00' + countryCode;
            if (phoneNumber.substr(0, countryCode.length) === countryCode) {
                phoneNumber = phoneNumber.substr(countryCode.length);
            }
            formControl.setValue(phoneNumber);
        }

    }

    onSelectCountryChange(event: Event) {
        const selectElement = event.target as HTMLSelectElement;
        if (selectElement.value) {
            this.form.get('step1.phone').enable();
            const country = this.getCountry(selectElement.value);
            if (country && country.phoneCode) {
                this.phoneCode = country.phoneCode;
            }
        }
    }



    isFieldInvalidStep1(fieldName: string): boolean {
        const control = this.form.get(`step1.${fieldName}`);
        return control && control.invalid && (control.dirty || control.touched);
    }



    trackByCountries(index, item) {
        return item.id;
    }
    //endregion

    //region  Step 2

    isFieldInvalidStep2(fieldName: string): boolean {
        const control = this.form.get(`step2.${fieldName}`);
        return control && control.invalid && (control.dirty || control.touched);
    }
    getRatesInCurrencies() {
        return this.rates.callRates[0].ratesInCurrencies.map(item => item.currencySign);
    }

    getRate(key) {
        const priceRate = ['Low', 'Medium', 'Normal', 'Premium', 'Ultimate'];
        return priceRate[key];
    }

    getRatesMenu() {
        return ['Aggressive', 'Competitive', 'Regular', 'Premium', 'Ultimate'];
    }

    selectRate(index) {
        this.selectedRate = index;
    }
    async fileChange(event: Event) {
        const self = this;
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            const file = input.files[0];

            // Reset file error
            this.fileError = null;

            // File validation
            if (await this.validateFile(file)) {
                this.selectedFile = file;
                await this.previewFile();
            } else {
                this.selectedFile = null;
                const preview: any = self.document.getElementById('previewProfile');
                preview.style.display = 'none';
                this.alertService.alert({
                    title: `Image not valid`,
                    body: this.fileError,
                    type: 'error',
                });
            }
        }

    }

    async previewFile() {
        const preview: any = self.document.getElementById('previewProfile');
        const fileReader = new FileReader();
        fileReader.addEventListener('load', function () {
            preview.src = fileReader.result;
        }, false);
        fileReader.readAsDataURL(this.selectedFile);
        const formData = new FormData();
        preview.style.display = 'block';
    }

    async validateFile(file: File): Promise<boolean> {
        const maxSize = 1024 * 1024 * 10; // 1MB
        const allowedTypes = ['image/jpeg', 'image/png'];

        if (file.size > maxSize) {
            this.fileError = 'File size should not exceed 10MB.';
            return false;
        }

        if (!allowedTypes.includes(file.type)) {
            this.fileError = 'Invalid file type. Only JPEG and PNG formats are allowed.';
            return false;
        }

        return await new Promise<boolean>((resolve) => {
            const img = new Image();
            img.onload = () => {
                if (img.width > 4000 || img.height > 4000) {
                    this.fileError = `Image dimensions should not exceed 4000x4000 pixels (${img.width}, ${img.height}).`;
                    resolve(false);
                }  else {
                    resolve(true);
                }
            };
            img.src = URL.createObjectURL(file);
        }).then(isValid => {
            if (!isValid) {
                this.selectedFile = null;
            }
            return isValid;
        });
    }


    onSelectCountryHomeChange(event: Event) {

        const selectElement = event.target as HTMLSelectElement;
        this.logger.debug('Country home changed', selectElement.value);
        if (selectElement.value) {
            this.form.get('step2.homePhone').enable();
            const country = this.getCountry(selectElement.value);

            if (country && country.phoneCode) {
                this.phoneCodeHome = country.phoneCode;
            }
        }
    }
    onSelectCountryWorkChange(event: Event) {
        const selectElement = event.target as HTMLSelectElement;
        if (selectElement.value) {
            this.form.get('step2.workPhone').enable();
            const country = this.getCountry(selectElement.value);
            if (country && country.phoneCode) {
                this.phoneCodeWork = country.phoneCode;
            }
        }
    }
    onSelectCountryMobileChange(event: Event) {
        const selectElement = event.target as HTMLSelectElement;
        if (selectElement.value) {
            this.form.get('step2.mobilePhone').enable();
            const country = this.getCountry(selectElement.value);
            if (country && country.phoneCode) {
                this.phoneCodeMobile = country.phoneCode;
            }
        }
    }

    validateStep1() {
        const step1 = this.form.get('step1');
        if (step1) {
            step1.markAllAsTouched();
            if (step1.valid) {
                this.step1IsCollapsed = true;
                this.step2IsCollapsed = false;
            } else {
                this.alertService.alert({
                    title: 'Reader application',
                    body: 'Please review that all the field are valid',
                    type: 'error'
                });
                this.scrollToFirstInvalidControl();
            }
        }
    }


    async validateStep2() {
        const step2 = this.form.get('step2');
        if (step2) {
            step2.markAllAsTouched();
            if (step2.valid && this.selectedFile) {
                this.step1IsCollapsed = true;
                this.step2IsCollapsed = true;
                await this.submitForm();
            } else {
                this.alertService.alert({
                    title: 'Reader application',
                    body: 'Please review that all the field are valid',
                    type: 'error'
                });
                this.scrollToFirstInvalidControl();
            }
        }
    }
    scrollToFirstInvalidControl() {
        const firstInvalidControl: HTMLElement = document.querySelector('form .ng-invalid');
        if (firstInvalidControl) {
            firstInvalidControl.focus();
            firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }



    async submitForm() {
        if (this.form.valid) {

            // Group categories
            const categoriesCheckMap = {};
            this.categories.forEach((category) => {
                categoriesCheckMap[category.id] = [];
                category.subCategories.forEach((subCat) => {
                    if (subCat.selected) {
                        categoriesCheckMap[category.id].push({id: subCat.id, checked: true});
                        // categoriesCheckMap[subCat.id] = true;
                    }
                });
            });
            this.form.get('step2.rateId').setValue(this.selectedRate );
            this.form.get('step2.categoriesCheckMap').setValue(categoriesCheckMap);
            const formData = new FormData();
            formData.append('step1', JSON.stringify(this.form.value.step1));
            formData.append('step2', JSON.stringify(this.form.value.step2));

            // Append the file
            if (this.selectedFile) {
                formData.append('avatar', this.selectedFile);
            }

            try {
                const result = await this.userConsultantService.signUp(formData);
                this.step3IsCollapsed = false;
            } catch (e) {
                if (e instanceof HttpErrorResponse) {
                    const message = e.error.message;
                    this.alertService.alert({
                        title: 'Reader application',
                        body: message,
                        type: 'error'
                    });
                } else{
                    this.alertService.alert({
                        title: 'Reader application',
                        body: 'We could not finish the process, please check the fields again',
                        type: 'error'
                    });
                }
                this.viewportScroller.scrollToPosition([0, 0]);
                this.step1IsCollapsed = false;
                this.step2IsCollapsed = true;
                this.step3IsCollapsed = true;
            }
        }
    }


}
