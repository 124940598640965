
<div class="modal-header">
    <button (click)="bsModalRef.hide()" aria-label="Close" class="close" type="button">
        <span>&times;</span>
    </button>
</div>
<div class="modal-body ">
    <!--<div class="container">-->
        <div class="row">
            <div class="col-lg-12">
                <div [innerHTML]="message" class="text"></div>
                <div class="row">
                    <ng-container *ngIf="readers && readers.length > 0">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-10 col-xs-offset-1 col-sm-offset-0 col-md-offset-0 col-lg-offset-0 reader-container text-center">
                            <app-small-reader-card [reader]="readers[0]" [type]="'-feature-readers'"
                                                   [userIsLoggedIn]="userIsLoggedIn"></app-small-reader-card>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-10 col-xs-offset-1 col-sm-offset-0 col-md-offset-0 col-lg-offset-0 reader-container">
                            <app-small-reader-card [reader]="readers[1]" [type]="'-feature-readers'"
                                                   [userIsLoggedIn]="userIsLoggedIn"></app-small-reader-card>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    <!--</div>-->


</div>
