import {Injectable, OnDestroy} from '@angular/core';
import {Api} from '../../api/api';
import {UtilService} from '../../../util.service';

@Injectable({
    providedIn: 'root',
})
export class SessionDieService {

    constructor(private utilService: UtilService,
                private api: Api) {
    }



    create() {
        try {
            return this.api.post(`/logs/session-died/`, {});
        } catch (e) {
            return null;
        }
    }

}
