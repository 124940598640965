import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BackRouter} from '../modules/shared/services/back-router';
import {plainToClass} from 'class-transformer';
import {catchError, map} from 'rxjs/operators';
import {Recent} from '../entity/recent';

@Injectable()
export class FeedbackService {
    constructor(private httpClient: HttpClient, private br: BackRouter) {
    }

    getRecent() {
        return this.httpClient.get(this.br.getJavaWebServicesUrl() + '/rest/feedback/recent', {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((objs: Object[]) => {
                        return objs.map(obj => plainToClass(Recent, obj));
                    },
                ),
                catchError((err => {
                    return [];
                }))
            );
    }

    getRecentByReader(reader_id: number) {
        return this.httpClient.get(this.br.getJavaWebServicesUrl() + `/rest/feedback/reader/${reader_id}/recent`, {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((objs: Object[]) => {
                        return objs.map(obj => plainToClass(Recent, obj));
                    },
                ),
                catchError((err => {
                    return [];
                }))
            ).toPromise();
    }

    getReaderTop(reader_id: number) {
        return this.httpClient.get(this.br.getJavaWebServicesUrl() + `/rest/feedback/reader/${reader_id}/top`, {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((objs: Object[]) => {
                        return objs.map(obj => plainToClass(Recent, obj));
                    },
                ),
            ).toPromise();
    }

    getReaderAll(reader_id: number, currentPage: number, itemsPerPage: number) {
        return this.httpClient.get(
            this.br.getJavaWebServicesUrl() + `/rest/feedback/reader/${reader_id}/all?currentPage=${currentPage}&itemsPerPage=${itemsPerPage}`, {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((result: any) => {
                        const objs = result.entries;
                        return objs.map(obj => plainToClass(Recent, obj));
                    },
                ),
            ).toPromise();
    }

    addFeedback(readingId, data: { readerRate: number, feedback: string }) {
        return this.httpClient.post(this.br.makeBackUrl(`/rest/feedback/reading/${readingId}`), data, {headers: { ignoreLoadingBar: '' }});
    }
}
