import {ApplicationRef, Inject, Injectable} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {concat, interval, of} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import {CookieService} from 'ng2-cookies';

@Injectable()
export class CheckForUpdateService {

    swUpdateObject : any;
    constructor(
        swUpdate: SwUpdate,
        @Inject(DOCUMENT) private document: Document,
        private cookieService: CookieService
    ) {
        this.swUpdateObject = swUpdate;
        // console.og('CheckForUpdateService');
        if (swUpdate.isEnabled) {
            // console.og('CheckForUpdateService 2');
            // Allow the app to stabilize first, before starting polling for updates with `interval()`.
            // const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
            const every1minute$ = interval(30 * 1000);
            // const every1minuteOnceAppIsStable$ = concat(appIsStable$, every1minute$);
            const every1minuteOnceAppIsStable$ = concat(of(1), every1minute$);

            every1minuteOnceAppIsStable$.subscribe(async () => {
                // console.og('CheckForUpdateService 3');
                await swUpdate.checkForUpdate();
            });
        }

    }

    public checkForUpdates(): void {
        const self = this;
        self.swUpdateObject.available.subscribe(event => {
            // console.og('PromptUpdateService 1 ');
            self.swUpdateObject.activateUpdate().then(() => {
                // console.og('PromptUpdateService 2 ');
                self.document.location.reload(true);
                self.cookieService.deleteAll('/');
            });
        });
    }
}
