<div class="modal-header modal-header-notify">
    <h3 class="modal-title pull-left">Notify Me When this Reader is Online</h3>
    <button (click)="bsModalRef.hide()" aria-label="Close" class="close" type="button">
        <span>&times;</span>
    </button>
</div>
<div class="modal-body">
    <form *ngIf="!isLogged()" [formGroup]="form">
        <div [ngClass]="{'has-error': hasError('email')}" class="form-group">
            <label class="email" for="notify_email">Please enter your Email:</label>
            <input class="form-control" formControlName="email" id="notify_email" placeholder="Email" type="email">
            <small *ngIf="hasError('email')" class="help-block">
                E-Mail: Please enter a valid email address
            </small>
        </div>
        <div class="form-group">
            <label class="pointer" for="agree">
        <span [ngClass]="{'fa-square-o':!form.controls['gdprConfirm'].value, 'fa-check-square-o':form.controls['gdprConfirm'].value}"
              class="fa"></span>
                <input class="hidden" formControlName="gdprConfirm" id="agree" type="checkbox">
                <span class="agree">I will receive communications from LifeReader. I am 18 years of age or older.</span>
            </label>
        </div>
        <div class="form-group">
            <button (click)="notifyAnonymous()" class="btn submit">Submit</button>
        </div>
        <div class="no-account">
            <span>No account yet?</span> <a (click)="doRegistration()" class="notify-registration-link">Register
            Free</a>
        </div>
    </form>
    <form (submit)="notify()" *ngIf="isLogged()" [formGroup]="form">
        <div class="form-group">
            <label class="pointer">
                <input [value]="false" checked="checked" id="onGoingFalse" formControlName="onGoing" name="onGoing" type="radio">
                One off email notification
            </label>
        </div>
        <div class="form-group">
            <label class="pointer">
                <input [value]="true" id="onGoingTrue" formControlName="onGoing" name="onGoing" type="radio">
                Ongoing - you can deactivate this option in your Email Notification page
            </label>
        </div>
        <div class="form-group">
            <button [disabled]="!form.valid" class="btn submit">Submit</button>
        </div>
    </form>
</div>
