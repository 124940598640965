<div class="modal-header">
    <button (click)="bsModalRef.hide()" aria-label="Close" class="close" type="button">
        <span>&times;</span>
    </button>
</div>
<div class="modal-body">
    <h1>Congratulations!</h1>
    <div>
        <h3>
            You have successfully created your account, please
            <a (click)="goToPage('/all-psychic-readers-at-lifereader')" class="congratulation-link">click&nbsp;here</a>
            to view all our Readers or select your preferred Reading type below.
        </h3>
    </div>
    <div *ngIf="reader" class="card avatar">
        <shared-reader-card [sor]="'-5'" [onlyAvatar]="true" [reader]="reader" [vertical]="true" [userIsLoggedIn]=userIsLoggedIn></shared-reader-card>
    </div>
    <div *ngIf="!reader">
        <ul class="tag-list">
            <li class="tag">
                <a (click)="goToPage('/all-psychic-readers-at-lifereader')" class="tag-link" routerLink="">All
                    Readers</a>
            </li>

            <li class="tag">
                <a (click)="goToPage('/psychics-clairvoyants')" class="tag-link">Psychic Readings</a>
            </li>

            <li class="tag">
                <a (click)="goToPage('/love-relationships/dating-relationships')" class="tag-link">Dating Advice</a>
            </li>

            <li class="tag">
                <a (click)="goToPage('/tarot-reading')" class="tag-link">Tarot Readings</a>
            </li>

            <li class="tag">
                <a (click)="goToPage('/love-relationships/old-flames')" class="tag-link">Breakup Advice</a>
            </li>

            <li class="tag">
                <a (click)="goToPage('/love-relationships/soulmate-connections')" class="tag-link">Soulmate Readings</a>
            </li>

            <li class="tag">
                <a (click)="goToPage('/love-relationships')" class="tag-link">Relationship Advice</a>
            </li>
        </ul>
    </div>

</div>
