import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BackRouter} from '../../shared/services/back-router';
import {NGXLogger} from 'ngx-logger';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {Reader} from '../../shared/entity/reader';
import {NgxPaginator} from '../../../entity/paginate';
import {BillingHistory} from '../entity/billing-history';
import {CustomerHistory} from '../../consultant/entity/customer-history';
import {CurrentChatDto} from '../../chat/dto/current-chat.dto';
import {ReadingHistoryListResponse} from '../../consultant/entity/reading-history-list-response';
import {LifeMailListResponse} from '../../consultant/entity/life-mail-list-response';

@Injectable({
    providedIn: 'root',
})
export class CustomerService {
    constructor(
        private httpClient: HttpClient,
        private br: BackRouter,
        private logger: NGXLogger,
    ) {
    }

    getGift() {
        return this.httpClient.get(this.br.makeBackUrl('/rest/customer/readers/gift'), {headers: { ignoreLoadingBar: '' }})
            .pipe(map((obj: { entities: Reader[] }) => {
                obj.entities = plainToClass(Reader, obj.entities);
                return obj;
            }));
    }

    getBilling(data) {
        let params = new HttpParams();

        Object.entries(data).forEach(([key, value]) => {
            params = params.append(String(key), String(value));
        });

        return this.httpClient.get(this.br.makeBackUrl('/rest/customer/billing'), {
            params: params,
            headers: { ignoreLoadingBar: '' }
        })
            .pipe(map((obj: NgxPaginator<any>) => {
                const res = plainToClass(NgxPaginator, obj);
                res.entries = plainToClass(BillingHistory, res.entries);
                return res;
            }));
    }

    getCallbackList() {
        // TODO create type
        // {
        //   "id": 3075,
        //   "customerName": "Siabroo",
        //   "consultantName": "TEST READER PLEASE DO NOT CONNECT",
        //   "startTime": 1554910107000,
        //   "timeLeftFormatted": "14:11",
        //   "callbackRequestStatus": null
        // },
        return this.httpClient.get(this.br.makeBackUrl('/rest/user/callback/list'), {headers: { ignoreLoadingBar: '' }});
    }

    callbackCancel(data) {
        return this.httpClient.post(this.br.makeBackUrl(`/rest/customer/callback/${data.callbackId}/cancel`), data, {headers: { ignoreLoadingBar: '' }});
    }

    getReadersFavorite() {
        return this.httpClient.get(this.br.makeBackUrl('/rest/customer/readers/favourite'), {headers: { ignoreLoadingBar: '' }})
            .pipe(map((objs: Reader[]) => {
                return plainToClass(Reader, objs);
            }));
    }

    addReaderFavorite(data) {
        return this.httpClient.post(this.br.makeBackUrl(`/rest/customer/readers/${data.readerId}/favourite`), data, {headers: { ignoreLoadingBar: '' }})
            .pipe(map((obj: { favourite: boolean }) => {
                return obj;
            }));
    }

    getReadersRecent() {
        return this.httpClient.get(this.br.makeBackUrl('/rest/customer/readers/recent'), {headers: { ignoreLoadingBar: '' }})
            .pipe(map((objs: Object[]) => {
                return objs.map(obj => plainToClass(Reader, obj));
            }));
    }

    getReadingHistory(data) {
        let params = new HttpParams();

        Object.entries(data).forEach(([key, value]) => {
            params = params.append(String(key), String(value));
        });

        return this.httpClient.get(this.br.makeBackUrl('/rest/customer/reading/history'), {
            params: params,
            headers: { ignoreLoadingBar: '' }
        })
            .pipe(map((obj: NgxPaginator<ReadingHistoryListResponse>) => {
                // const ngx = plainToClass(NgxPaginator, obj);
                // ngx.entries = plainToClass(CustomerHistory, ngx.entries);
                // return ngx;
                return plainToClass(ReadingHistoryListResponse, obj);
            }));
    }

    getIntervals() {
        return this.httpClient.get(this.br.makeBackUrl('/rest/customer/callback/intervals'), {headers: { ignoreLoadingBar: '' }});
    }

    bookCallback(data: {
        readerId: number,
        interval: number
    }) {
        return this.httpClient.post(
            this.br.makeBackUrl(`/rest/customer/reader/${data.readerId}/callback`),
            data,
            {headers: { ignoreLoadingBar: '' }}
        );
    }

    confirmChat(readingId) {
        return this.httpClient.post(this.br.makeBackUrl(`/rest/reading/${readingId}/chat/confirm`), {
            readingId: readingId,
        }, {headers: { ignoreLoadingBar: '' }});
    }

    async isBusy()  {
        const isBusy: any = await this.httpClient.get(
            this.br.makeBackUrl(`/rest/customer/isbusy`),
            {headers: { ignoreLoadingBar: '' }}
        ).toPromise();
        // Return false to see if this is cause of drop in revenue
        return isBusy.isBusy;
        // return false;

    }
    async currentActiveChat(userId: number): Promise<CurrentChatDto> {
        const promise: any = await this.httpClient.get(
            this.br.makeBackUrl(`/rest/reading/chat/active-chat/${userId}`),
            {headers: { ignoreLoadingBar: '' }}).toPromise();
        const activeChat: CurrentChatDto = promise;
        return activeChat;
    }
}
