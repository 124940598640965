import {Inject, Injectable, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';
// @ts-ignore
// import seo from '../_files/Seo.json';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {BackRouter} from '../modules/shared/services/back-router';
import {UtilService} from './util.service';
import {WINDOW} from '../providers/window.provider';

@Injectable({
    providedIn: 'root'
})
export class SEOService implements OnInit {

    public domain = '';
    public prevPage = '';
    private seo = null;

    domains = [
        {
            domain: 'https://www.lifereader.com',
            language: 'en-us',
        },
        {
            domain: 'https://www.lifereader.co.nz',
            language: 'en-nz',
        },
        {
            domain: 'https://www.lifereader.com.au',
            language: 'en-au',
        },
        {
            domain: 'https://www.lifereader.co.uk',
            language: 'en-gb',
        },
        {
            domain: 'https://www.lifereader.eu',
            language: 'en-gb',
        }
    ];

    constructor(
        private title: Title,
        @Inject(DOCUMENT) private doc,
        private meta: Meta,
        private httpClient: HttpClient,
        private utilService: UtilService,
        private br: BackRouter,
        @Inject(WINDOW) private window: Window
    ) {
    }

    ngOnInit() {
    }

    async loadData(domain, page) {
        try {
            const url = this.br.getJavaWebServicesUrl() + `/rest/sitemap/seo?page=${page}&domain=${domain}`;
            const seo: any = await this.httpClient.get(url, {headers: { ignoreLoadingBar: '' }}).toPromise();
            return seo;
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    //region Public functions
    /**
     * To set all facebook tags with just one function
     * @param title Title of the page
     * @param description Description of the page
     * @param image Image to show on facebook previews
     * @param secureImage Image to show on facebook previews
     * @param imageType Type of picture to show on facebook previews
     * @param imageWidth size of picture to show on facebook previews
     * @param imageHeight size of picture to show on facebook previews
     * @param siteName Name to show on facebook preview
     * @param type Type of website
     * @param url Url to show on facebook preview
     */
    public setAllFacebookTags(
        title: string,
        description: string,
        image: string = '/images/website/base/lrlogo_400.jpg',
        secureImage: string = '/images/website/base/lrlogo_400.jpg',
        imageType: string = 'image/jpeg',
        imageWidth: string = '300',
        imageHeight: string = '300',
        siteName: string = environment.siteName,
        type: string = 'web',
        url: string =this.window.location.href
    ) {
        this.setFacebookTitle(title);
        this.setFacebookDescription(description);
        this.setFacebookTags('og:image', 'http://' +this.window.location.hostname + image);
        this.setFacebookTags('og:image:secure_url', 'https://' +this.window.location.hostname + secureImage);
        this.setFacebookTags('og:image:type', imageType);
        this.setFacebookTags('og:image:width', imageWidth);
        this.setFacebookTags('og:image:height', imageHeight);
        this.setFacebookTags('og:site_name', siteName);
        this.setFacebookTags('og:type', type);
        this.setFacebookTags('og:url', url);
        this.setFacebookTags('fb:admins', '100000267662877');
    }

    /**
     * To set the tags using the json file
     */
    public async setTagsByPage() {
        const self = this;
        try {
            if (!environment.electron) {
                let page;
                if (self.domain === '') {
                    self.domain = this.window.location.hostname;
                    self.domain = self.domain.replace('www.', '');
                    self.domain = self.domain.replace('lifereader', '');
                    self.domain = self.domain.replace('dev.', '');
                    self.domain = self.domain.replace('stg.', '');
                }
                page = this.window.location.pathname;
                if (self.prevPage !== page) {
                    self.prevPage = page;
                    await self.loadData(self.domain, page);
                    try {
                        // const seoTags = self.seo[page][self.domain];
                        const seoTags: any = await self.loadData(self.domain, page);
                        if (seoTags) {
                            if (seoTags.general) {
                                self.setPageTitle(seoTags.general.title);
                                self.setKeywords(seoTags.general.keywords);
                            }
                            if (seoTags.facebook) {
                                self.setAllFacebookTags(
                                    seoTags.facebook['title'] !== null ? seoTags.facebook['title'] : undefined,
                                    seoTags.facebook['description'] !== null ? seoTags.facebook['description'] : undefined,
                                    seoTags.facebook.image !== null ? seoTags.facebook.image : undefined,
                                    seoTags.facebook['image:secure_url'] !== null ? seoTags.facebook['image:secure_url'] : undefined,
                                    seoTags.facebook['image:type'] !== null ? seoTags.facebook['image:type'] : undefined,
                                    seoTags.facebook['image:width'] !== null ? seoTags.facebook['image:width'] : undefined,
                                    seoTags.facebook['image:height'] !== null ? seoTags.facebook['image:height'] : undefined,
                                    seoTags.facebook['site_name'] !== null ? seoTags.facebook['site_name'] : undefined,
                                    seoTags.facebook['type'] !== null ? seoTags.facebook['type'] : undefined,
                                    seoTags.facebook['url'] !== null ? seoTags.facebook['url'] : undefined,
                                );
                            }
                        }
                    } catch (e) {
                    }
                }

            }

        } catch (e) {
            self.utilService.setTimeout(function () {
                self.setTagsByPage();
            }, 500);
        }


    }

    /**
     * To set the pageTitle in case is neccesarry
     * @param title
     */
    public setPageTitle(title: string) {
        this.title.setTitle(title);
    }

    public createLinkForCanonicalURL() {
        // Delete previous one to avoid duplicate
        for (const domain of this.domains) {
            const link = this.doc.getElementById(domain.domain);
            link?.remove();
        }
        const canonicalPrev = this.doc.getElementById('canonical');
        canonicalPrev?.remove();
        for (const domain of this.domains) {
            const link: HTMLLinkElement = this.doc.createElement('link');
            link.setAttribute('rel', 'alternate');
            link.setAttribute('id', domain.domain);
            link.setAttribute('hreflang', domain.language);
            link.setAttribute('href', domain.domain + this.window.location.pathname);
            this.doc.head.appendChild(link);
        }

        const canonical: HTMLLinkElement = this.doc.createElement('link');
        canonical.setAttribute('id', 'canonical');
        canonical.setAttribute('rel', 'canonical');
        canonical.setAttribute('href', this.window.location.href);
        this.doc.head.appendChild(canonical);
    }

    /**
     * Edit the keywords tags
     * @param content
     */
    public setKeywords(content: string) {
        this.setTags({property: 'keywords', content: content});

    }

    public clearTags() {
        // General Tags
        this.title.setTitle(null);
        this.meta.removeTag('property="keywords"');
        this.meta.removeTag('property="description"');
        this.meta.removeTag('name="description"');
        // Facebook Tags
        this.meta.removeTag('property=\'og:title\'');
        this.meta.removeTag('property=\'og:description\'');
        this.meta.removeTag('property=\'og:url\'');
        this.meta.removeTag('property=\'og:image\'');
        this.meta.removeTag('property=\'og:image:secure_url\'');
        this.meta.removeTag('property=\'og:image:type\'');
        this.meta.removeTag('property=\'og:image:width\'');
        this.meta.removeTag('property=\'og:image:height\'');
        this.meta.removeTag('property=\'og:site_name\'');
        this.meta.removeTag('property=\'og:type\'');
    }

    //endregion
    //region Private tags
    /**
     * Function that create or update a tag if exists
     * @param tag
     */
    private setTags(tag) {

        const auxName = this.meta.getTag('name=' + tag.name);
        const auxProperty = this.meta.getTag('property="' + tag.property + '"');
        if (auxName) {
            this.meta.updateTag(tag, 'name=' + tag.name);
            return;
        }
        if (auxProperty) {
            this.meta.updateTag(tag, 'property="' + tag.property + '"');
            return;
        }
        if (!auxName && !auxProperty) {
            try {
                this.meta.addTag(tag);
                return;
            } catch (e) {
                console.error(e);
            }
        }

    }

    private setFacebookTags(property: string, content: string) {
        const tag = {property: property, content: content};
        this.setTags(tag);
    }


    /**
     * To edit facebook description
     * @param content
     */
    private setFacebookDescription(content: string) {
        // console.debug('Facebook description', content);
        this.setTags({property: 'og:description', content: content});
        this.setTags({name: 'description', content: content});
    }

    /**
     * To edit facebook title
     * @param content
     */
    private setFacebookTitle(content: string) {
        this.setTags({property: 'og:title', content: content});
    }

    //endregion

}
