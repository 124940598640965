export const PhoneTypes = {
    home: 'PHONE_HOME',
    work: 'PHONE_WORK',
    mobile: 'PHONE_MOBILE',
};

export class Phone {
    number: string;
    countryCode: number;
    phoneType: string;
    defaultPhone: boolean;
}
