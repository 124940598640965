import {Currency} from './currency';
import {Type} from 'class-transformer';

export class Rate {
    actualDate: any; // 1465822883000;
    callRate: number;
    chatRate: number;

    @Type(() => Currency)
    currency: Currency;

    /** @deprecated duplicate **/
    currencyId: number;

    emailRate: number;
    id: number;
    rateId: number;
}
