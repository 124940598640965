import {User} from './user';
import {Type} from 'class-transformer';

export class LifeMailUsersResponse {
    count: number;

    @Type(() => User)
    entries: User[];

    page: number;
    perPage: number;
}
