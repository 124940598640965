<ng-container *ngIf="reader">
    <ng-container *ngIf="!full">
        <div (click)="addFav()"[ngClass]="{'active':reader.favorite}" class="favorite-container fav-container-{{type}}">
            <span
                  [ngClass]="{'fa-heart-o':!reader.favorite, 'fa-heart':reader.favorite}"
                  class="fa fav-icon-{{type}}">

            </span>
        </div>
    </ng-container>
    <ng-container *ngIf="full">
        <div (click)="addFav()" *ngIf="reader.favorite" class="favorite-container fav-container-{{type}}">
            <div  [ngClass]="{'active':true}" class="full">
                <span [ngClass]="'fa-heart'" class="fa fav-icon fav-icon-{{type}}"></span>
                <span class="fav-button fav-text hidden-xs fav-text-{{type}}">Favorite Reader</span>
            </div>
        </div>
        <div (click)="addFav()" *ngIf="!reader.favorite" class="favorite-container fav-container-{{type}}">
            <div  [ngClass]="{'active':false}" class="full">
                <span [ngClass]="'fa-heart-o'" class="fa fav-icon fav-icon-{{type}}"></span>
                <span class="fav-button fav-text hidden-xs fav-text-{{type}}">Add to Favorites</span>
            </div>
        </div>

    </ng-container>
</ng-container>
