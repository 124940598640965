import {Type} from 'class-transformer';

export class CategoryWebcontent {
    id: number;
    categoryId: number;
    htmlPreFeedbackSection: string;
    htmlPostFeedbackSection: string;
    header: string;
}

export class Category {
    id: number;
    parentCategoryId?: number;
    title: string;
    link: string;
    metaTitle: string;
    metaDescription: string;
    metaKeys: string;
    description: string;
    image: string;
    langId: number;
    domainId: number;
    seoText: string;
    @Type(() => CategoryWebcontent)
    categoryWebcontent: CategoryWebcontent;
    @Type(() => Category)
    subCategories: Category[];

    active: boolean;
}
