import {Directive, ElementRef, EventEmitter, HostListener, OnInit, Output,} from '@angular/core';

@Directive({
    selector: '[sharedHoverUnion]',
})
export class HoverUnionDirective implements OnInit {

    @Output()
    onHover = new EventEmitter();

    @Output()
    onOut = new EventEmitter();

    constructor(private el: ElementRef<any>) {
    }

    @HostListener('mouseover')
    onMouseOver() {
        this.onHover.emit(this.el.nativeElement.dataset.index);
    }

    @HostListener('mouseout')
    onMouseOut() {
        this.onOut.emit(this.el.nativeElement.dataset.index);
    }

    ngOnInit() {
    }
}
