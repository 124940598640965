import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UtilService} from '../../util.service';
import {BackRouter} from '../../../modules/shared/services/back-router';

/**
 * Api is a generic REST Api handler. Set your API url first.
 */
@Injectable()
export class Api {

  constructor(
    private http: HttpClient,
    public util: UtilService,
    private br: BackRouter

  ) {


  }

  post( endpoint: string, body: any): any {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'ignoreLoadingBar':  '',
      }),
      withCredentials: true
    };
    const token = this.util.localStorageGetItem('token');
    if (token) {
        httpOptions.headers =
            httpOptions.headers.set('Authorization', 'Bearer ' + token);
    }
    return this.http.post(this.br.getNestJsUrl() + endpoint, body, httpOptions).toPromise();
  }
  postWithoutPromise( endpoint: string, body: any): any {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'ignoreLoadingBar':  '',
      }),
      withCredentials: true
    };
    const token = this.util.localStorageGetItem('token');
    if (token) {
      httpOptions.headers =
          httpOptions.headers.set('Authorization', 'Bearer ' + token);
    }
    return this.http.post(this.br.getNestJsUrl() + endpoint, body, httpOptions);
  }
  postWithFiles( endpoint: string, body: FormData): any {

    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type':  'multipart/form-data'
      }),
      withCredentials: true
    };
    const token = this.util.localStorageGetItem('token');
    if (token) {
        httpOptions.headers =
            httpOptions.headers.set('Authorization', 'Bearer ' + token);
    }
    return this.http.post(this.br.getNestJsUrl()  + endpoint, body, httpOptions).toPromise();
  }

  get(endpoint: string, params: any): any {
    let fields = '';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      }),
      // withCredentials: true
    };
    const token = this.util.localStorageGetItem('token');
    if (token) {
        httpOptions.headers =
            httpOptions.headers.set('Authorization', 'Bearer ' + token);
    }

    //
    // // Support easy query params for GET requests
    if (params) {
      const p = new URLSearchParams();
      // tslint:disable-next-line:forin
      for (const k in params) {
        // noinspection JSUnfilteredForInLoop
        p.set(k, params[k]);
      }
      fields = '?' + p.toString();
      if (fields === '?') {
        fields = '';
      }
    }
    return this.http.get(this.br.getNestJsUrl()  + endpoint + fields, httpOptions).toPromise();
  }

  patch(endpoint: string, body: any): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      }),
      // withCredentials: true
    };
    const token = this.util.localStorageGetItem('token');
    if (token) {
        httpOptions.headers =
            httpOptions.headers.set('Authorization', 'Bearer ' + token);
    }
    return this.http.patch(this.br.getNestJsUrl()  + endpoint, body, httpOptions);
  }

  put( endpoint: string, body: any): any {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      }),
      // withCredentials: true
    };
    const token = this.util.localStorageGetItem('token');
    if (token) {
      httpOptions.headers =
        httpOptions.headers.set('Authorization', 'Bearer ' + token);
    }
    return this.http.put(this.br.getNestJsUrl()  + endpoint, body, httpOptions);
  }
  delete( endpoint: string, body: any): any {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      }),
      // withCredentials: true
    };
    const token = this.util.localStorageGetItem('token');
    if (token) {
      httpOptions.headers =
        httpOptions.headers.set('Authorization', 'Bearer ' + token);
    }
    return this.http.delete(this.br.getNestJsUrl()  + endpoint, httpOptions);
  }

}
