import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CookiesBannerComponent} from './cookies-banner.component';



@NgModule({
  declarations: [CookiesBannerComponent],
  exports: [CookiesBannerComponent],
  imports: [
    CommonModule
  ]
})
export class CookiesBannerModule { }
