import {DictionaryCurrency} from '../../entities/dictionary-currency';
import {PromotionDto} from '../../promotion/dto/promotion.dto';


export enum CanShowAddFundsStatus {
  IS_BLOCKED,
  STEP_1,
  STEP_2,
  STEP_3,
  SHOW_PROMO,
  IS_BUSY,
  CAN_SHOW_FUNDS,
}
export class CanShowAddFundsDto {
  result: CanShowAddFundsStatus;
  payGo: boolean;
  payGoInterval?: number;
  currency?: DictionaryCurrency;
  balance?: number;
  showPayGoOnAddFunds?: boolean;
  promotionList?: PromotionDto[];
  hasTransactions?: boolean;
  isUserHasFreeCall?: boolean;
}
