import {Component, Input, OnInit} from '@angular/core';
import {Reader} from '../../entity/reader';

@Component({
    selector: 'shared-online',
    templateUrl: './online.component.html',
    styleUrls: ['./online.component.scss'],
})
export class OnlineComponent implements OnInit {

    @Input()
    short = false;
    statuses = [
        {
            active: false,
            desc: 'Offline',
            class: 'offline',
        },
        {
            active: true,
            desc: 'Online',
            class: 'online',
        },
        {
            active: false,
            desc: 'Busy',
            class: 'busy',
        },
    ];

    cssClass = null;
    @Input()
    type = 0;

    constructor() {
        this.cssClass = this.getClass();
    }

    @Input()
    set data(reader: Reader) {
        if (!reader.available) {
            this.setActive('offline');
            return;
        }
        if (reader.busy) {
            this.setActive('busy');
            return;
        }
        this.setActive('online');
    }

    ngOnInit() {
        this.cssClass = this.getClass();
    }

    getDescription() {
        return this.getStatus().desc;
    }

    getStatus() {
        return this.statuses.find((item) => {
            return item.active;
        });
    }

    getClass() {
        return this.getStatus().class;
    }

    deactivate() {
        this.statuses.map(el => el.active = false);
    }

    setActive(cl: string) {
        this.deactivate();
        const foundEl = this.statuses.find(el => el.class === cl);
        foundEl.active = true;
        this.cssClass = this.getClass();
    }
}
