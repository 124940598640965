import {Injectable} from '@angular/core';
import {PaymentService} from '../modules/customer/services/payment.service';
import {UserService} from '../services/user.service';
import {Currency} from '../entity/currency';
import {forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PromotionList} from '../modules/customer/services/entities/promotion-list.entity';
import {Founds} from '../modules/customer/services/entities/founds.entity';

@Injectable({
    providedIn: 'root'
})
export class PromotiosResolverService {

    public payGo = true;
    public payGoInterval;
    public balance: number;
    public currency: Currency;
    public promotionList;
    public showPaygOnAddFunds: boolean;
    public data: PromotiosResolverData;

    constructor(
        private paymentService: PaymentService,
        public userService: UserService,
    ) {
    }


    getData(): Observable<any> {
        const self = this;
        self.data = new PromotiosResolverData();

        try {
            const values = forkJoin([
                    this.paymentService.getAutoRefill(),
                    this.userService.getDetails(),
                    this.paymentService.getFounds()
                ]
            ).pipe(
                map(([first, second, third]) => {

                    // @ts-ignore
                    self.data.payGo = first.auto;
                    // @ts-ignore
                    for (const interval of first.intervals) {
                        if (interval.value === true) {
                            self.data.payGoInterval = interval.label;
                        }
                    }

                    self.data.currency = second.currency;
                    self.data.balance = second.fullBalance;
                    self.data.showPayGoOnAddFunds = second.showPaygOnAddFunds;

                    self.data.promotionList = third.promotionList;
                    return self.data;
                })
            );
            return values;
        } catch (e) {
            console.error(e);
        }
    }

}

export class PromotiosResolverData {
    public payGo = true;
    public showPayGoOnAddFunds = true;
    public payGoInterval;
    public balance: number;
    public currency: Currency;
    public promotionList: Founds[];


    constructor() {
    }
}
