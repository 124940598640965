<ng-container *ngIf="data.confirmation">
    <div class="modal-header">
        Confirmation Required
    </div>
    <div class="modal-body">
        <p>
            You have requested to add {{data.asciiSymbol}}{{data.amount}} from your payment method to your LifeReader
            account.
            This will show as 'LifeReader' on your card statement.
        </p>
        <p>
            If at any time you have an unused balance which you would like refunded please simply email <b>support@lifereader.com</b>.
        </p>
        <p>
            Please click <b>'Yes'</b> to confirm.
        </p>
    </div>
    <div class="modal-footer">
        <button (click)="doConfirm()" class="btn btn-success">Yes</button>
        <button (click)="doCancel()" class="btn btn-default">No</button>
    </div>
</ng-container>

<ng-container *ngIf="!data.confirmation">
    <ng-container *ngIf="data.payg">
        <div class="modal-header">
            Pay As You Go ON
        </div>
        <div class="modal-body">
            <p>
                With Pay As You Go, if you decide to do a Paid Reading you will be charged in {{data.amount}} minute
                intervals.
            </p>
            <p>
                If at any time you have an unused balance which you would like refunded please simply email
                support@lifereader.com.
            </p>
            <p>
                Please click <b>'Yes'</b> to confirm.
            </p>
        </div>
        <div class="modal-footer">
            <button (click)="doCancel()" class="btn btn-link">Cancel</button>
            <button (click)="doConfirm()" class="btn btn-success">Yes</button>
        </div>
    </ng-container>
    <ng-container *ngIf="!data.payg">
        <div class="modal-header">
            Pay As You Go OFF
        </div>
        <div class="modal-body">
            <p>
                Turning Pay As You Go off will end sessions when your funds are depleted.
                Are you sure?
            </p>
        </div>
        <div class="modal-footer">
            <button (click)="doConfirm()" class="btn btn-success">OK</button>
            <button (click)="doCancel()" class="btn btn-default">Cancel</button>
        </div>
    </ng-container>
</ng-container>
