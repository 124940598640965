import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {SendMessageEvent} from '../../../../../entity/send-message-event';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {SubSink} from 'subsink';
import {AddFundsEmitterService} from '../../../../../services/add-funds-emitter.service';

@Component({
    selector: 'app-messages-modal',
    templateUrl: './messages-modal.component.html',
    styleUrls: ['./messages-modal.component.scss'],
})
export class MessagesModalComponent implements OnInit, OnDestroy {
    onMessage = new EventEmitter();
    messages: SendMessageEvent[] = [];
    private subs = new SubSink();

    constructor(public bsModalRef: BsModalRef,
    private addFundsService: AddFundsEmitterService) {
    }

    ngOnInit() {
        this.subs.sink = this.onMessage.subscribe((data: SendMessageEvent) => {
            this.messages.push(data);
        });
    }

    closeMessage(message) {
        const index = this.messages.findIndex((m) => m === message);
        if (index >= 0) {
            this.messages.splice(index, 1);
        }
        if (!this.messages.length) {
            this.bsModalRef.hide();
        }
    }

    onAddFund() {
        this.bsModalRef.hide();
        this.addFundsService.onAddFunds(true);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
