import {Component, Input, OnInit} from '@angular/core';
import {Reader} from '../../entity/reader';

@Component({
    selector: 'shared-premier',
    templateUrl: './premier.component.html',
    styleUrls: ['./premier.component.scss'],
})
export class PremierComponent implements OnInit {

    @Input()
    full = false;
    @Input()
    type = 0;
    visible = true;

    constructor() {
    }

    @Input()
    set data(reader: Reader) {
        this.visible = reader.premier;
    }

    ngOnInit() {
    }

}
