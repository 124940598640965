import { NgModule } from '@angular/core';
import {SiteLinksComponent} from './site-links.component';
import {RouterModule} from '@angular/router';
@NgModule({
    declarations: [SiteLinksComponent],
    bootstrap: [SiteLinksComponent],
    exports: [
        SiteLinksComponent
    ],
    imports: [
        RouterModule
    ]
})
export class SiteLinksModule { }
