import {AfterViewInit, Component, ElementRef, HostListener, Inject} from '@angular/core';
import {SCREEN_SIZE} from '../../../../screen-size.enum';
import {ResizeService} from '../../../../services/resize.service';
import {WINDOW} from '../../../../providers/window.provider';

@Component({
  selector: 'app-size-detector',
  templateUrl: './size-detector.component.html',
  styleUrls: ['./size-detector.component.css']
})
export class SizeDetectorComponent implements AfterViewInit {

  prefix = 'is-';
  sizes = [
    {
      id: SCREEN_SIZE.XS, name: 'xs', css: `visible-xs-block`
    },
    {
      id: SCREEN_SIZE.SM, name: 'sm', css: `visible-sm-block`
    },
    {
      id: SCREEN_SIZE.MD, name: 'md', css: `visible-md-block`
    },
    {
      id: SCREEN_SIZE.LG, name: 'lg', css: `visible-lg-block`
    }
  ];
  constructor(
      private resizeSvc: ResizeService,
      private elementRef: ElementRef,
      @Inject(WINDOW) private window: Window) { }

  ngAfterViewInit(): void {
    this.detectScreenSize();
  }

  @HostListener('window:resize', [])
  private onResize() {
    this.detectScreenSize();
  }
  private detectScreenSize() {

    const currentSize = this.sizes.find(x => {
      // get the HTML element
      const el = this.elementRef.nativeElement.querySelector(`.${this.prefix}${x.id}`);

      // check its display property value
      const isVisible =this.window.getComputedStyle(el).display !== 'none';

      return isVisible;
    });

    this.resizeSvc.onResize(currentSize.id);
  }

}
