import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {UserService} from '../services/user.service';

@Injectable()
export class CanActivateReader implements CanActivate {

    bsModalRef: BsModalRef;

    constructor(
        private userService: UserService,
        private router: Router
    ) {
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.userService.getRole() === 'ROLE_READER') {
            return true;
        }
        await this.router.navigateByUrl('/');
        return false;
    }
}
