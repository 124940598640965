export class StartCallResponse {
    id: number;
    callSpecialRate: number;
    callFreeMinutes: number;
    freeCall: boolean;
    currencySymbol: string;
    perMinuteFormatted: string;
    customerMobileRate: number;
    freeMinutes: number;
    method: number;
    maxDuration: number;
    autoRefill: boolean;
    giftCardFreeMinutes: number;
    newUser: boolean;
    freeCallWasDenied: any;
    freeCallWasDeniedWasShownAndClosed: any;
}
