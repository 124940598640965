import {plainToClass} from 'class-transformer';

export class Paginate {
    currentPage: number;
    itemsPerPage: number;
    totalItems?: number;
}

export class NgxPaginator<T> {
    id: string;
    itemsPerPage: number;
    totalItems?: number;
    currentPage: number;
    readerId: number;
    entries: T[];

    constructor(data, type) {
        this.id = Math.random().toString(36).substr(2, 9);
        this.entries = plainToClass(type, data);
    }
}
