import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import {ErrorService} from '../services/error.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    // Error handling is important and needs to be loaded first.
    // Because of this we should manually inject the services with Injector.
    constructor(private logger: NGXLogger, private injector: Injector) {
    }

    handleError(error: Error | HttpErrorResponse | Error) {

        const errorService = this.injector.get(ErrorService);
        let message;
        let stackTrace;
        if (error instanceof HttpErrorResponse) {
            // Server Error
            message = errorService.getServerMessage(error);
        } else {

            // Client Error
            message = errorService.getClientMessage(error);
            stackTrace = errorService.getClientStack(error);
        }

        this.logger.error(message, stackTrace);

        /**
         * This error occurs when there is an update
         */
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (chunkFailedMessage.test(error.message)) {
            this.logger.error('Reloading page for user');
                window.location.reload();
        }
    }
}
