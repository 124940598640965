<footer #footer [hidden]="!showFooter" class="main-footer" id="load-footer">
    <div class="container">
        <div class="pages-lists">
            <div class="pages-list-block">
                <h3>Site Links</h3>
                <app-site-links></app-site-links>
            </div>
            <div class="pages-list-block">
                <h3>Popular Content</h3>

                <ul class="list-unstyled">
                    <li><a [routerLink]="'/free-weekend-love-horoscope'">Weekend Love Forecast</a></li>
                    <li><a [routerLink]="'/love-horoscope'">Weekly Love Horoscopes</a></li>
                    <li><a [routerLink]="'/free-daily-horoscope'">Daily Horoscopes</a></li>
                    <li><a [routerLink]="'/free-monthly-horoscope'">Monthly Horoscopes</a></li>
                </ul>
            </div>
            <div class="pages-list-block">
                <h3>Categories</h3>
                <ul class="list-unstyled">
                    <li><a [routerLink]="'/'">Homepage</a></li>

                    <li><a [routerLink]="'/all-psychic-readers-at-lifereader'">All Readers</a></li>

                    <li><a [routerLink]="'/psychics-clairvoyants'">Psychic Readings</a></li>

                    <li><a [routerLink]="'/love-relationships/dating-relationships'">Dating Advice</a></li>

                    <li><a [routerLink]="'/tarot-reading'">Tarot Readings</a></li>

                    <li><a [routerLink]="'/love-relationships/old-flames'">Breakup Advice</a></li>

                    <li><a [routerLink]="'/love-relationships/soulmate-connections'">Soulmate Readings</a></li>

                    <li><a [routerLink]="'/love-relationships'">Relationship Advice</a></li>
                </ul>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="footer-info">* All rates shown are {{currency?.sign}} {{currency?.asciiSymbol}} per minute. Valid
            Visa/Mastercard Credit Card is required.
            PayPal, Pre-Paid, Gift and some Debit Cards are also accepted. 4 (Four) Free Chat minutes with every reader
            only
            apply for New Customers and do not apply to Call readings, and only to the first four minutes of your first
            chat
            reading with every reader, normal rates apply thereafter. Special Call rates apply for New
            Unique Customers and only to the first 10 minutes of the first Phone Call and normal rates apply thereafter,
            please check and confirm rates before you accept your Call Reading. All readings clearly show pricing and
            applicable charges and require you to confirm and accept these charges before starting a reading. Readings
            are
            charged in minute intervals.
            <div class="additional-link">
                <div class="pull-right social">
                    <a rel="nofollow" href="https://facebook.com/LifeReader.Answers.for.Life" target="_blank">
                        <i class="fa fa-facebook-square fa-3x footer-text-color"></i>
                    </a>
                    <a rel="nofollow" href="https://twitter.com/life_reader" target="_blank">
                        <i class="fa fa-twitter-square fa-3x footer-text-color"></i>
                    </a>
                    <a rel="nofollow" href="https://www.instagram.com/lifereader_psychics" target="_blank">
                        <i class="fa fa-instagram fa-3x footer-text-color"></i>
                    </a>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
    <div class="bottom-footer">
        <div class="container">
                <div class="footer-logo col-sm-3 col-md-2"><img alt="logo" src="assets/logo-white.png" width="146" height="33" loading="lazy"></div>
                <div class="copy col-sm-9">LifeReader© 2008-{{currentYear}} LifeReader and its services are for entertainment
                    purposes only
                </div>
        </div>
    </div>
</footer>
<shared-cookies-banner ></shared-cookies-banner>
