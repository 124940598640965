export class CustomerHistory {
    id: number;
    method: number;
    startTime: number;
    endTime: number;
    amount: number;
    readerEarning: number;
    customerName: string;
    hasChatLog: boolean;
    // To know if user can put a feed-back
    requiredFeedback: boolean;
    publish: boolean;
    refunded: boolean;
    currentStatus: string;
    paidMinutes: number;
    freeMinutes: number;

    feedback: {
        feedback: string,
        rating: number;
        published: boolean;
    };
    daysSinceReading?: string;

    getRating() {
        if (!this.feedback) {
            return 0;
        }
        return this.feedback.rating;
    }

    getFeedback() {
        if (!this.feedback) {
            return;
        }
        return this.feedback.feedback;
    }

    getAmount() {

        // if (this.amount === 0) {
        //     return 'Free';
        // }
        if (this.currentStatus === 'UNRECONCILED') {
            if (this.readerEarning > 0) {
                return 'Pending';
            } else {
                return 'Free';
            }
        }
        return this.amount;
    }
}
