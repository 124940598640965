<ul class="list-unstyled">
    <li><a routerLink="/">Home</a> </li>
    <li><a routerLink="/become-reader">Become a reader</a> </li>
    <li><a routerLink="/how-it-works">How it works</a> </li>
    <li><a routerLink="/review-guide">Feedback and Review Guidelines</a> </li>
    <li><a routerLink="/privacy">Privacy Policy</a> </li>
    <li><a routerLink="/contact">Contact us</a> </li>
    <li><a routerLink="/help">Help</a> </li>
    <li><a routerLink="/terms-and-conditions">Terms and Conditions</a> </li>
    <li><a routerLink="/sitemap">Sitemap</a> </li>
    <li><a routerLink="/cookies">Cookies Policy</a> </li>
    <li><a (click)="openModalForgotPassword()" class="pointer">Forgot your Password?</a></li>
    <li><a href="https://slice.digital/offer/lifereader-affiliate-program">Affiliate Program</a> </li>
</ul>
