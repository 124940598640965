<div class="modal-header">
    <button (click)="bsModalRef.hide()" aria-label="Close" class="close" type="button">
        <span>&times;</span>
    </button>
    <span class="fa fa-phone"></span> Book a Voice Call
</div>
<div class="modal-body text-center">
    <p>
        <b>{{reader.nickname}}</b> will try to call you if she is available in your chosen time from now.
    </p>
    <div>
        <p>
            <strong>❶ Choose the hours you want to be called back in from now</strong>
        </p>
        <div class="form-group form-inline">
            <select [(ngModel)]="selectedItem" class="form-control">
                <option *ngFor="let item of data" [ngValue]="item">{{item.hours}}</option>
            </select>
        </div>
    </div>

    <div>
        <strong>❷ Check your phone number</strong>
    </div>
    <ng-container *ngIf="defaultPhone">
        <div>Your current active phone number is:</div>
        <p >
            <b>{{defaultPhone}}</b>
        </p></ng-container>
    <div *ngIf="!defaultPhone" class="phone-block">
        <p>Please enter phone number to call you</p>
        <input
                (input)="validatePhoneNumber(getCountryCode())"
                [(ngModel)]="newPhoneNumber"
                [appAutoFocus]="size > 0 ? true: false"
                class="form-control"
                id="phone"
                type="text">

        <button (click)="doSave()" class="btn">
            <span class="fa fa-check" style="color: #9575cd"></span>
        </button>
        <button (click)="bsModalRef.hide()" aria-label="Close" class="btn">
            <span class="fa fa-times" style="color: #9575cd"></span>
        </button>
    </div>
    <p>
        <small>You can change this number in your Account later.</small>
    </p>
    <div>
        <button (click)="doSubmit()" class="btn btn-success" [disabled]="booking">
            ❸ Confirm Booking <span class="fa fa-check"></span>
        </button>
    </div>

    <p>
        <small>Please listen to the voice prompts and reading rates at the start of the call before you choose to accept
            the
            call. Placing a callback does not garantee the reader will be able to call you.
        </small>
    </p>
</div>
