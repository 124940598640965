export enum AddFundState {
    STARTED = 'STARTED',
    USER_BLOCKED = 'USER_BLOCKED',
    CHECKING_REGISTRATION = 'CHECKING_REGISTRATION',
    IS_NOT_LOGGED_IN = 'IS_NOT_LOGGED_IN',
    STEP_2 = 'STEP_2',
    STEP_3 = 'STEP_3',
    FIRST_TIME_ADDING_FUNDS = 'FIRST_TIME_ADDING_FUNDS',
    GETTING_LAST_PAYMENT = 'GETTING_LAST_PAYMENT',
    IS_BUSY = 'IS_BUSY',
    GETTING_OFFERS = 'GETTING_OFFERS',
    ADD_FUNDS_SHOWN = 'ADD_FUNDS_SHOWN',
    IS_NOT_CUSTOMER = 'IS_NOT_CUSTOMER',
    PROMOTIONS_LOADED = 'PROMOTIONS_LOADED',
    GETTING_USER_DATA = 'GETTING_USER_DATA',
    USER_DATA_LOADED = 'USER_DATA_LOADED',
    CHECKING_FIRST_TIME_ADDING_FUNDS = 'CHECKING_FIRST_TIME_ADDING_FUNDS',
    LAST_PAYMENT = 'LAST_PAYMENT',
    CHECKING_IS_BUSY = 'CHECKING_IS_BUSY',
    ERROR_GETTING_OFFERS = 'ERROR_GETTING_OFFERS',
    ERROR_GETTING_DATA = 'ERROR_GETTING_DATA',
    ERROR_SHOWING_MODAL= 'ERROR_SHOWING_MODAL',
}
