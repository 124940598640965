import {Injectable} from '@angular/core';
import {BackRouter} from '../../shared/services/back-router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {plainToClass} from 'class-transformer';
import {map} from 'rxjs/operators';
import {LifeMailUsersResponse} from '../entity/life-mail-users-response';
import {User} from '../entity/user';
import {CallbackRequest} from '../entity/callback-request';
import {CallbackResponse} from '../entity/callback-response';
import {ConsultatntLanguages} from '../entity/consultatnt-languages';
import {HistoryResponse} from '../entity/history-response';
import {ProfileDescription} from '../entity/profile-description';
import {CustomerHistory} from '../entity/customer-history';
import {Availability} from '../../shared/entity/availability';
import {CategoriesResponse} from '../../../entity/categories-response';
import {NGXLogger} from 'ngx-logger';

@Injectable({
    providedIn: 'root',
})
export class ConsultantService {
    constructor(
        private httpClient: HttpClient,
        private br: BackRouter,
        private logger: NGXLogger
    ) {
    }

    getUsers(data: { currentPage: any, itemsPerPage: any, sortField: string, nickname?: string }) {

        let params = new HttpParams();

        Object.entries(data).forEach(([key, value]) => {
            params = params.append(String(key), String(value));
        });

        return this.httpClient.get(this.br.getJavaWebServicesUrl() + '/rest/lifemail/users', {
            params: params,
            headers: { ignoreLoadingBar: '' }
        }).pipe(map((obj: Object) => {
                return plainToClass<LifeMailUsersResponse, Object>(LifeMailUsersResponse, obj);
            },
        ));
    }

    updateBlockUser(user: User) {
        return this.httpClient.post(this.br.getJavaWebServicesUrl() + `/rest/lifemail/user/${user.id}/block`, {
            blocked: user.blocked,
        }, {headers: { ignoreLoadingBar: '' }});
    }

    turnCustomerBlock(customerId) {
        return this.httpClient.post(this.br.getJavaWebServicesUrl() + `/rest/consultant/customer/${customerId}/block`, {
            customerId: customerId,
        }, {headers: { ignoreLoadingBar: '' }});
    }

    getNotification(): Observable<Object> {
        return this.httpClient.get(this.br.getJavaWebServicesUrl() + '/rest/lifemail/settings/notification', {headers: { ignoreLoadingBar: '' }});
    }

    updateNotification(data: { mailingNewMail: boolean }) {
        return this.httpClient.put(this.br.getJavaWebServicesUrl() + '/rest/lifemail/settings/notification', data, {headers: { ignoreLoadingBar: '' }});
    }

    getCallbacks(data: CallbackRequest) {
        let params = new HttpParams();

        Object.entries(data).forEach(([key, value]) => {
            params = params.append(String(key), String(value));
        });

        return this.httpClient.get(this.br.getJavaWebServicesUrl() + '/rest/consultant/callbacks', {
            params: params,
            headers: { ignoreLoadingBar: '' }
        }).pipe(map((obj: Object) => {
            return plainToClass<CallbackResponse, Object>(CallbackResponse, obj);
        }));
    }
    doCallBack(callbackId: number) {
        let params = new HttpParams();
        params = params.append('callbackId', String(callbackId));
        return this.httpClient.get(this.br.getJavaWebServicesUrl() + `/rest/consultant/callback`, {
            params,
            headers: { ignoreLoadingBar: '' }
        });
    }

    getDescription() {
        return this.httpClient.get(this.br.getJavaWebServicesUrl() + '/rest/consultant/profile/description', {headers: { ignoreLoadingBar: '' }})
            .pipe(map((obj) => {
                return plainToClass(ProfileDescription, obj);
            }));
    }

    getSign() {
        return this.httpClient.get(this.br.getJavaWebServicesUrl() + '/rest/consultant/profile/horoscope/sign', {headers: { ignoreLoadingBar: '' }});
    }

    updateSign(data) {
        return this.httpClient.put(this.br.getJavaWebServicesUrl() + '/rest/consultant/profile/horoscope/sign', data, {headers: { ignoreLoadingBar: '' }});
    }

    getLanguage(): Observable<ConsultatntLanguages> {
        return this.httpClient.get(this.br.getJavaWebServicesUrl() + '/rest/consultant/profile/language', {headers: { ignoreLoadingBar: '' }})
            .pipe(map((obj) => {
                return plainToClass(ConsultatntLanguages, obj);
            }));
    }

    updateLanguage(data: ConsultatntLanguages) {
        return this.httpClient.put(this.br.getJavaWebServicesUrl() + '/rest/consultant/profile/language', data, {headers: { ignoreLoadingBar: '' }})
            .pipe(map((obj) => {
                return plainToClass(ConsultatntLanguages, obj);
            }));
    }

    getHistory(data) {
        let params = new HttpParams();

        Object.entries(data).forEach(([key, value]) => {
            params = params.append(String(key), String(value));
        });

        return this.httpClient.get(this.br.getJavaWebServicesUrl() + '/rest/consultant/history', {
            params: params,
            headers: { ignoreLoadingBar: '' }
        })
            .pipe(map((obj) => {
                return plainToClass(HistoryResponse, obj);
            }));
    }

    getNotes(customerId) {
        return this.httpClient.get(this.br.getJavaWebServicesUrl() + `/rest/consultant/customer/${customerId}/notes`, {headers: { ignoreLoadingBar: '' }});
    }

    updateNotes(customerId, str) {
        return this.httpClient.put(this.br.getJavaWebServicesUrl() + `/rest/consultant/customer/${customerId}/notes`, {notes: str}, {headers: { ignoreLoadingBar: '' }});
    }

    getHistoryCustomer(customerId): Observable<CustomerHistory[]> {
        return this.httpClient.get(this.br.getJavaWebServicesUrl() + `/rest/consultant/history/customer/${customerId}`, {headers: { ignoreLoadingBar: '' }})
            .pipe(map((objs: Object[]) => {
                return objs.map(obj => plainToClass(CustomerHistory, obj));
            }));
    }

    getChatLog(readingId) {
        return this.httpClient.get(this.br.getJavaWebServicesUrl() + `/rest/reading/${readingId}/chatlog`, {headers: { ignoreLoadingBar: '' }})
            .pipe(map((objs: Object[]) => {
                return objs;
            }));
    }

    getRate() {
        return this.httpClient.get(this.br.makeBackUrl('/rest/consultant/profile/rate'), {headers: { ignoreLoadingBar: '' }})
            .pipe(map((obj: any) => {
                return obj;
            }));
    }

    getCategories() {
        return this.httpClient.get(this.br.makeBackUrl(`/rest/consultant/profile/categories`), {headers: { ignoreLoadingBar: '' }}).pipe(map((obj: Object) => {
            // return objs.map(obj => plainToClass(CategoriesResponse, obj));
            const res = plainToClass(CategoriesResponse, obj);
            return res;
        }));
    }

    updateCategories(data) {
        return this.httpClient.put(this.br.makeBackUrl(`/rest/consultant/profile/categories`), data, {headers: { ignoreLoadingBar: '' }}).pipe(
            map((objs: Object) => {
                const res = plainToClass(CategoriesResponse, objs);
                return res;
            // return objs.map(obj => plainToClass(180, obj));
        }));
    }

    getAvailability() {
        this.logger.debug('GETTING AVA');
        return this.httpClient.get(this.br.makeBackUrl('/rest/consultant/availability'), {headers: { ignoreLoadingBar: '' }})
            .pipe(map((obj) => plainToClass(Availability, obj)));
    }

    setAvailability(data) {
        this.logger.debug('setting AVA', data);
        return this.httpClient.post(this.br.makeBackUrl('/rest/consultant/availability'), data, {headers: { ignoreLoadingBar: '' }})
            .pipe(map((obj) => plainToClass(Availability, obj)));
    }

    sendEmail() {
        return this.httpClient.get(this.br.makeBackUrl('/rest/consultant/send-email'), {});
    }
}
