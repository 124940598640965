import {Component, EventEmitter, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-confirm-payment-modal',
    templateUrl: './confirm-payment-modal.component.html',
    styleUrls: ['./confirm-payment-modal.component.scss'],
})
export class ConfirmPaymentModalComponent implements OnInit {

    onConfirm = new EventEmitter();
    onCancel = new EventEmitter();

    data: any = {
        amountAdded: 0,
        amount: 10,
        asciiSymbol: '£',
        payg: false,
        confirmation: true
    };

    constructor(
        public bsModalRef: BsModalRef,
    ) {
    }

    ngOnInit() {
    }

    doConfirm() {
        this.onConfirm.emit(true);
        this.bsModalRef.hide();
    }

    doCancel() {
        this.onCancel.emit(true);
        this.bsModalRef.hide();
    }
}
