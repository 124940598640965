import {environment} from '../../../../environments/environment';
import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {WINDOW} from '../../../providers/window.provider';
import {ActivatedRoute} from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class BackRouter {
    public backendUrl = '';
    public backendImageUrl = 'https://dev.lifereader.com';
    public nestJsUrl = '';

    constructor(
        private httpClient: HttpClient,
        @Inject(WINDOW) private window: Window,
        private route: ActivatedRoute
    ) {
        this.setUrl();
    }

    getJavaWebServicesUrl() {
        this.setUrl();
        return this.backendUrl + environment.api_prefix;

    }

    getServerUrl() {
        this.setUrl();
        return this.backendUrl;
    }
    getNestJsUrl() {
        this.setNestJsUrl();
        return this.nestJsUrl;
    }


    makeBackUrl(str) {
        this.setUrl();
        return this.backendUrl + environment.api_prefix + str;
    }

    getDomainList() {
        this.setUrl();
        return this.httpClient.get(this.makeBackUrl('/rest/sitemap/domain/list'), {headers: { ignoreLoadingBar: '' }});
    }

    nodeJsServer() {
        return environment.nodeJsServer;
    }
    nestJsServer() {
        return environment.nestJs.url;
    }

    backEndServer() {
        this.setUrl();
        return this.backendUrl;
    }

    private setUrl() {
        if (this.backendUrl === '') {
            const host = this.window.location.host;
            if (environment.name === 'local' || environment.name === 'ip') {
                this.backendUrl = environment.backend_url;
                this.backendImageUrl = environment.backend_url;
            } else {
                this.backendUrl = 'https://' + host;
                this.backendImageUrl = 'https://' + host;
            }
        }
    }
    private setNestJsUrl() {
        if (this.nestJsUrl === '') {
            let host = this.window.location.host;
            if (environment.name === 'local' || environment.name === 'ip') {
                this.nestJsUrl = environment.nestJs.url;
            } else {
                // Delete www from host
                host = host.replace('www.', '');
                this.nestJsUrl = 'https://api.' + host;
            }
            // this.nestJsUrl = environment.nestJs.url;
        }
    }
}
