import {Component, Input, OnInit} from '@angular/core';
import {Reader} from '../../entity/reader';

@Component({
    selector: 'shared-busy-button',
    templateUrl: './busy-button.component.html',
    styleUrls: ['./busy-button.component.scss'],
})
export class BusyButtonComponent implements OnInit {

    @Input()
    reader: Reader;

    @Input()
    role = '';
    /**
     * It is used to determinate where the component is used
     */
    @Input()
    type: any;

    constructor() {
    }

    ngOnInit() {
    }
}
