import {Component, OnDestroy, OnInit} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ForgotPasswordComponent} from '../../modules/shared/components/forgot-password/forgot-password.component';

@Component({
    selector: 'app-site-links',
    templateUrl: './site-links.component.html',
    styleUrls: ['./site-links.component.scss'],
})
export class SiteLinksComponent implements OnInit, OnDestroy {


    constructor(
        private bsModalService: BsModalService
    ) {
    }

    ngOnInit() {
        // I tried to explain
        // this.subs.sink = this.userService.getFooterLinks().subscribe((res) => {
        //     this.backLinks = res;
        // });
    }

    openModalForgotPassword() {
        this.bsModalService.show(ForgotPasswordComponent, {animated: false, class: 'modal-sm'});
    }

    ngOnDestroy(): void {
    }
}
