import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BackRouter} from '../../shared/services/back-router';
import {plainToClass} from 'class-transformer';
import {NotificationMail} from '../entity/notification-mail';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class MailService {
    constructor(
        private httpClient: HttpClient,
        private br: BackRouter,
    ) {
    }

    getNotificationMail() {
        return this.httpClient.get(this.br.getJavaWebServicesUrl() + '/rest/notification/mail', {headers: { ignoreLoadingBar: '' }})
            .pipe(map((obj) => {
                return plainToClass(NotificationMail, obj);
            }));
    }

    updateNotificationMail(data: NotificationMail) {
        return this.httpClient.put(this.br.getJavaWebServicesUrl() + '/rest/notification/mail', data, {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((obj) => {
                    return plainToClass(NotificationMail, obj);
                })
            );

    }

    getNotificationOnline() {
        return this.httpClient.get(this.br.makeBackUrl('/rest/customer/notification/online'), {headers: { ignoreLoadingBar: '' }});
    }

    removeNotificationOnline(readerId) {
        return this.httpClient.delete(this.br.makeBackUrl(`/rest/customer/notification/reader/${readerId}/online`), {headers: { ignoreLoadingBar: '' }});
    }

    addNotification(data: { onGoing: boolean, readerId: number }) {
        return this.httpClient.post(this.br.makeBackUrl(`/rest/customer/notification/reader/${data.readerId}/online?onGoing=${data.onGoing}`), data, {headers: { ignoreLoadingBar: '' }});
    }
}
