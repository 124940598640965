    <div class="modal-header" ngxModalDraggable>
        <button (click)="close()" aria-label="Close" class="close" type="button">
            <span>&times;</span>
        </button>
        <span>My notes on {{customerName}}</span>
    </div>
    <div class="modal-body">
        <angular-editor
                [(ngModel)]="notes"
                (blur)="lock = false"
                (change)="doChange()"
                (focus)="lock = true"
                [config]="editorConfig"></angular-editor>
    </div>
    <div class="modal-footer">
        <button (click)="doSubmit()" [disabled]="lock" class="btn btn-success">save</button>
        <button (click)="close()" class="btn btn-default">cancel</button>
    </div>
