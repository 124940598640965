export class ConsultantHistory {
    id: number;
    lastContactDate: number;
    customerName: string;
    callsNumber: number;
    chatsNumber: number;
    totalEarningNZD: number;
    blocked: boolean;
    customerId: number;
    daysSinceReading?: string;
}
