import {Consultant} from './consultant';
import {Type} from 'class-transformer';

export class Recent {
    num?: number;
    rating: number;
    description: string;
    date: number;
    @Type(() => Consultant)
    consultant: Consultant;
    reviewer: string;
}
