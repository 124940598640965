import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BackRouter} from '../../shared/services/back-router';
import {Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {CountryResponse} from '../entity/country-response';
import {PersonalDetailsResponse} from '../entity/personal-details-response';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';
import {AccountBank} from '../entity/account-bank';
import {Phone} from '../entity/phone';
import {NGXLogger} from 'ngx-logger';
import {UtilService} from '../../../services/util.service';

@Injectable({
    providedIn: 'root',
})
export class AccountService {

    constructor(
        private br: BackRouter,
        private httpClient: HttpClient,
        private logger: NGXLogger,
        private utilService: UtilService,
    ) {
    }

    getBank() {
        return this.httpClient.get(this.br.getJavaWebServicesUrl() + '/rest/consultant/account/bank', {headers: { ignoreLoadingBar: '' }})
            .pipe(map((obj) => {
                return plainToClass(AccountBank, obj);
            }));
    }

    updateBank(data: AccountBank) {
        return this.httpClient.put(this.br.getJavaWebServicesUrl() + '/rest/consultant/account/bank', data, {headers: { ignoreLoadingBar: '' }});
    }

    getDetails() {
        // dispatcher/rest/consultant/account/details
        return this.httpClient.get(this.br.getJavaWebServicesUrl() + '/rest/user/account/details', {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((obj) => {
                    return plainToClass(PersonalDetailsResponse, obj);
                }));
    }

    updateDetails(data: {
        titleId: number,
        firstName: string,
        lastName: string,
        address: string,
        city: string,
        postCode: string,
        countryId: number,
        defaultPhoneCountryId: number,
        defaultPhoneNumber: string
    }) {
        return this.httpClient.put(this.br.makeBackUrl('/rest/user/account/details'), data, {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((obj) => {
                    return plainToClass(PersonalDetailsResponse, obj);
                }));
    }

    getTimezones() {
        const self = this;
        const timezone = self.utilService.localStorageGetItem('Timezone');
        if (timezone) {
            return of(JSON.parse(timezone));
        }

        return this.httpClient.get(this.br.getJavaWebServicesUrl() + '/rest/dictionary/timezones', {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((arr: string[]) => {
                    self.utilService.localStorageSetItem('Timezone', JSON.stringify(arr));
                    return arr;
                }));
    }

    updateTimeZone(data) {
        return this.httpClient.put(this.br.getJavaWebServicesUrl() + '/rest/user/timezone', data, {headers: { ignoreLoadingBar: '' }})
            .pipe(map((obj: { email: string }) => {
                return obj;
            }));
    }

    getCountries(): Observable<CountryResponse[]> {
        const self = this;
        const countries = self.utilService.localStorageGetItem('Countries');
        if (countries) {
            return of(plainToClass(CountryResponse, JSON.parse(countries)) as any);
        }

        return this.httpClient.get(this.br.getJavaWebServicesUrl() + '/rest/dictionary/countries', {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((obj: CountryResponse[]) => {
                    self.utilService.localStorageSetItem('Countries', JSON.stringify(obj));
                    return plainToClass(CountryResponse, obj);
                }));
    }

    getLanguages(): Observable<Array<{ id: number, name: string }>> {
        const self = this;
        const countries = self.utilService.localStorageGetItem('Languages');
        if (countries) {
            return of(JSON.parse(countries));
        }

        return this.httpClient.get(this.br.getJavaWebServicesUrl() + '/rest/dictionary/languages', {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((obj: { id: number, name: string }[]) => {
                    self.utilService.localStorageSetItem('Languages', JSON.stringify(obj));
                    return obj;
                }));
    }

    getTitleList() {
        const self = this;
        const titleList = self.utilService.localStorageGetItem('TitleList');
        if (titleList) {
            return of(JSON.parse(titleList));
        }

        return this.httpClient.get(this.br.getJavaWebServicesUrl() + '/rest/dictionary/titles', {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((obj: { id: number, name: string }[]) => {
                    self.utilService.localStorageSetItem('TitleList', JSON.stringify(obj));
                    return obj;
                }));
    }

    getEmail() {
        return this.httpClient.get(this.br.getJavaWebServicesUrl() + '/rest/user/email', {headers: { ignoreLoadingBar: '' }})
            .pipe(map((obj: { email: string }) => {
                return obj;
            }));
    }

    updateEmail(data) {
        return this.httpClient.put(this.br.getJavaWebServicesUrl() + '/rest/user/email', data, {headers: { ignoreLoadingBar: '' }})
            .pipe(map((obj: { email: string }) => {
                return obj;
            }));
    }

    updatePassword(data) {
        return this.httpClient.put(this.br.getJavaWebServicesUrl() + '/rest/user/password', data, {headers: { ignoreLoadingBar: '' }});
    }

    getPhones() {
        try {
            return this.httpClient.get(this.br.getJavaWebServicesUrl() + '/rest/user/account/phones', {headers: { ignoreLoadingBar: '' }})
                .pipe(
                    map((objs: Object[]) => {
                        return objs.map(obj => plainToClass(Phone, obj));
                    }),
                    catchError(function (error: HttpErrorResponse) {
                        return [];
                    }));
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    updatePhones(data: {
        phones: {
            number: string,
            countryCode: number,
            phoneType: string,
            defaultPhone?: boolean
        }[]
    }) {
        return this.httpClient.put(this.br.makeBackUrl('/rest/user/account/phones'), data, {headers: { ignoreLoadingBar: '' }})
            .pipe(map((objs: Object[]) => {
                try {
                    return objs.map(obj => plainToClass(Phone, obj));
                } catch (e) {
                    return [];
                }
            }));
    }

    updateDefaultPhone(data: {
        defaultPhoneType: number
    }) {
        return this.httpClient.put(this.br.makeBackUrl('/rest/user/account/phones/default'), data, {headers: { ignoreLoadingBar: '' }})
            .pipe(map((objs: Object[]) => {
                try {
                    return objs.map(obj => plainToClass(Phone, obj));
                } catch (e) {
                    return [];
                }
            }));
    }

    getCategories() {
        return this.httpClient.get(this.br.makeBackUrl(`/rest/dictionary/categories`), {headers: { ignoreLoadingBar: '' }});
    }

    getRates() {
        return this.httpClient.get(this.br.makeBackUrl(`/rest/dictionary/rate`), {headers: { ignoreLoadingBar: '' }});
    }
}
