import { Injectable, OnDestroy } from '@angular/core';
import {Api} from '../api/api';
import {HttpClient} from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class UserCreditCardService {


  constructor(
              private api: Api) {
  }

  async  checkCCExpire(): Promise<boolean> {
    try {
      const result: {hasExpired: boolean} =  await this.api.get('/user-credit-card/check-cc-expired', null);
      return  result.hasExpired;
    } catch (e) {
      return false;
    }
  }
}
