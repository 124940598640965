import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
// import {FeesListComponent} from '../../modules/shared/components/fees-list/fees-list.component';
import {Currency} from '../../entity/currency';
import {AllEmiterService} from '../../services/all-emiter.service';
import {SubSink} from 'subsink';
import {LoadableComponent} from 'ngx-loadable';
import {UtilService} from '../../services/util.service';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
    bsModalRef: BsModalRef;
    viewElements = [false, false, false, false];
    currency: Currency = {
        asciiSymbol: '$',
        id: 1,
        sign: 'USD'
    };
    auxInterval: any;
    private subs = new SubSink();
    @ViewChild('siteLinksComponent') siteLinksComponent: LoadableComponent;
    showSiteLinks = false;

    @ViewChild('footer', {static: false}) footer: ElementRef;

    chatRunning = true;

    showFooter = false;
    currentYear = 2023;
    constructor(
        private utilService: UtilService,
        private modalService: BsModalService,
        private allEmiterService: AllEmiterService,
        private router: Router,
        private activatedRoute: ActivatedRoute) {

        // tslint:disable-next-line:max-line-length
        this.subs.sink = this.allEmiterService.subsCurrencyChange = this.allEmiterService.invokeCurrencyChange.subscribe((currency1: Currency) => {
            if (currency1 != null) {
                this.currency = currency1;
            }

        });
    }

    ngOnInit() {
        const self = this;
        const aux = JSON.parse(self.utilService.localStorageGetItem('currency'));
        if (aux === null) {
            this.currency = {
                asciiSymbol: '$',
                id: 1,
                sign: 'USD'
            };
        } else {
            this.currency = aux;
        }
        let pos = 0;
        this.subs.sink = this.auxInterval = self.utilService.setInterval(function () {
            if (pos === 4) {
                // clearInterval(self.auxInterval);
                // self.utilService.clearInterval(self.auxInterval);
            }
            self.viewElements[pos] = true;
            pos++;
        }, 150);

        this.router.events.subscribe(event => {
            // This will avoid the footer showing again after initial load and user goes to another page
            if (event instanceof NavigationStart) {
                this.showFooter = false;
            }
            if (event instanceof NavigationEnd) {

                // In case the page is open using navigateUrl on the chat modal
                if (history?.state?.chatRunning) {
                    this.chatRunning = history.state.chatRunning;
                } else {
                    this.chatRunning = this.activatedRoute.firstChild.snapshot.data.chatRunning;
                }
                this.displayFooter();

            }
        });

        this.subs.sink = this.allEmiterService.subsLoadFooter = this.allEmiterService.invokeLoadFooter.subscribe(async () => {
            this.showFooter = true;
        });
        this.getCurrentYear();
    }

    async openFeesListModal() {
        const component: any = await
            import('../../modules/shared/components/fees-list/fees-list.component')
            .then(cp => cp.FeesListComponent);
        this.bsModalRef = this.modalService.show(component, {animated: false, class: 'modal-sm'});
    }

    async siteLinksOnView(event) {
        if (event.visible) {
            if (this.siteLinksComponent && !this.siteLinksComponent.loaded) {
                await this.siteLinksComponent.preload();
                this.showSiteLinks = true;
            }
        }
    }

    ngOnDestroy(): void {
        // clearInterval(this.auxInterval);
        this.subs.unsubscribe();
    }

    /**
     * This function will determinate which css class add to the footer
     * If there is a chat running the css class will hide the footer
     */
    displayFooter() {
        if (this.chatRunning) {
            this.footer.nativeElement.classList.add('chat');
        } else {
            this.footer.nativeElement.classList.remove('chat');
        }

    }

    getCurrentYear(){
        // Create a new Date object
        const currentDate = new Date();
        // Get the current year
        this.currentYear = currentDate.getFullYear();

    }
}
