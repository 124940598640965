<div class="modal-header">
    <button (click)="bsModalRef.hide()" aria-label="Close" class="close" type="button">
        <span>&times;</span>
    </button>
    <p class="text-center">
        <span class="fa fa-comment"></span>
        <b class="title">{{title}}</b>
    </p>
</div>
<div *ngIf="!rejected" class="modal-body text-center modal-reading-body">
    <p *ngIf="!started">
        <button (click)="doConfirmChat()" class="btn btn-success">
            <b>
                Start <span class="fa fa-comment"></span> Chat
            </b>
        </button>
    </p>
    <div (click)="reFocus()" *ngIf="started" class="reading-confirmed pointer">
        <p><b>Reading confirmed</b></p>
        <div><b><small>Click here to show Chat window</small></b></div>
    </div>
    <p>with</p>
    <p class="green"><b>{{reader.nickname}}</b></p>
    <hr>
    <p>
        <small>

            <ng-container *ngIf="reading.giftCardFreeMinutes>0">
                {{reading.giftCardFreeMinutes}} FREE Minutes gifted by <b>{{reader.nickname}}</b>
                <br>
            </ng-container>
            <ng-container *ngIf="reading.freeMinutes>0">
                First {{reading.freeMinutes}} minutes free.
                Further minutes are charged at {{reader.callRates.currency.asciiSymbol}}{{reader.callRates.chatRate |number:'.2-2'}}/minute
            </ng-container>
            <ng-container *ngIf="reading.freeMinutes<=0">
                The Reading will be charged at {{reader.callRates.currency.asciiSymbol}}{{reader.callRates.chatRate |number:'.2-2'}}
                /min.
            </ng-container>
            The maximum duration is {{reading.maxDuration}} minutes.
            <ng-container *ngIf="reading.autoRefill">
                <b>Your refill status is turned ON</b> and funds will be automatically added to your account according to the number of minutes you selected.
                You can change your refill status in your account.
            </ng-container>
            <ng-container *ngIf="!reading.autoRefill">
                <b>Your refill status is turned OFF</b> and the Reading will hangup when funds are depleted.
                You can change your refill status in your account.
            </ng-container>
            By starting the Reading you confirm that you are liable for the charges.

        </small>

    </p>
</div>
<!--<div *ngIf="rejected" class="modal-body text-center">-->
<!--    <p>-->
<!--        Sorry but I am not able to take your Chat right now.<br>-->
<!--        Please try again later or contact me via LifeMail to arrange a time for a Reading.-->
<!--        Thank you.-->
<!--    </p>-->
<!--    <div (click)="bsModalRef.hide()" class="reading-confirmed pointer">-->
<!--        <div><small>click here to close</small></div>-->
<!--    </div>-->
<!--</div>-->
