export class BillingHistory {
    id: number;
    date: number;
    operationType: number;
    amount: number;
    bonus: number;
    balance: number;
    bonusBalance: number;
    currency: {
        id: number;
        asciiSymbol: string;
        sign: string;
    };
    giftBonusExpirationDate: number;
    recordType: string;
    reading: {
        id: number;
        readerNickname: string;
        startTime: number;
        giftCardDTO: any;
        currency: {
            id: number;
            asciiSymbol: string;
            sign: string;
        },
        amount: number;
        valid: boolean;
        paymentMethod: number;
        freeCall: number;
        perMinute: number;
        freeCallRate: number;
        freeMinutes: number;
        freeDurationMinWithGift: number;
        customerMobileRate: number;
        perMinuteNZDFormatted: string;
        connectionFeeNZFormatted: string;
        readerEarningFormatted: string;
        hasChatLog: boolean;
        feedback: any
    };
    refillTransactions: any;

    isCollapsed = true;
}
