export class SignInWithGoogleRequest {
    email: string;
    familyName: string;
    givenName: string;
    id: string;
    imageUrl: string;
    name: string;
    requestUrl: string;
    ip: string;
    app: boolean;
    deviceId: string;
    authentication: {
        accessToken: string;
        idToken: string;
        refreshToken: string;
    };

    constructor(
        email: string, familyName: string, givenName: string, id: string,
        imageUrl: string, name: string, requestUrl: string, ip: string, app: boolean,
        deviceId: string, accessToken: string, idToken: string, refreshToken: string
    ) {
        this.email = email;
        this.familyName = familyName;
        this.givenName = givenName;
        this.id = id;
        this.imageUrl = imageUrl;
        this.name = name;
        this.requestUrl = requestUrl;
        this.ip = ip;
        this.app = app;
        this.deviceId = deviceId;
        this.authentication = {
            accessToken: accessToken,
            idToken: idToken,
            refreshToken: refreshToken
        };
    }
}
