export class SignInWithAppleRequest {
    user: string;
    email: string;
    givenName: string;
    familyName: string;
    identityToken: string;
    authorizationCode: string;
    ip: string;
    requestUrl: string;
    app: boolean;
    deviceId: string;

    constructor(
        user: string,
        email: string,
        givenName: string,
        familyName: string,
        identityToken: string,
        authorizationCode: string,
        ip: string,
        requestUrl: string,
        app: boolean,
        deviceId: string, ) {
        this.user = user;
        this.email = email;
        this.givenName = givenName;
        this.familyName = familyName;
        this.identityToken = identityToken;
        this.authorizationCode = authorizationCode;
        this.ip = ip;
        this.requestUrl = requestUrl;
        this.app = app;
        this.deviceId = deviceId;
    }
}
