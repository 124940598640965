<div *ngIf="reader">
    <div class="col-sm-12 padding-zero image-block text-right">
        <div class="">
<!--            <img [src]="br.backendImageUrl + '/images/photo/' + reader.photoOrig" alt="">-->
            <img [lazyLoad]="br.backendImageUrl + '/images/photo/' + reader.photoOrig"  alt="" [useSrcset]="true">

            <div class="speech_balloon" >

                <div class="head">
                    <div (click)="doOpenSade()" *ngIf="reader.giftCard" class="present">
                        <div class="present-link">
                            <!--<span class="icon-present"></span>-->
                            <i class="fa fa-gift"></i>
                            Click to see your Gift!
                        </div>
                    </div>
                    <shared-gift-shade *ngIf="reader.giftCard"
                                       [reader]="reader"></shared-gift-shade>
                    <div [ngClass]="{'has-gift':reader.giftCard}" class="rfc-top">
                        <div class="left-column rfc{{type}}">
                            <shared-online [type]="9" [data]="reader" [short]="true"></shared-online>
                            <shared-premier [type]="9" [data]="reader"></shared-premier>
                            <a [routerLink]="profileLink"
                               class="nickname">{{reader.nickname}}</a>
                        </div>
                        <div class="right-column rfc{{type}}">
                            <shared-favourite [type]="9" [reader]="reader" class="pull-right"></shared-favourite>
                        </div>
                    </div>
                </div>
                <a class="body" [routerLink]="profileLink"></a>
                <div class="footer">
                    <div class="prices">
                                <span style="font-weight: normal">
                                    {{reader.callRates.currency.asciiSymbol}}
                                    {{reader.callRates.callRate|currency:'symbol':''}}
                                    /min*
                                </span>
                        <span *ngIf="reader.freeChatMinutes>0 || !userIsLoggedIn">
                                    Chat: 4 free min
                                </span>
                    </div>
                    <shared-major-actions [data]="reader">
                        <ng-container *ngIf="reader.available">
                            <div class="left-button">
                                <shared-call-button [reader]="reader" [role]="customerRole" [type]="type"></shared-call-button>
                                <shared-book-button [reader]="reader" [role]="customerRole" [type]="type"></shared-book-button>
                            </div>
                            <div class="right-button">
                                <shared-chat-button [reader]="reader" [role]="customerRole" [type]="type"></shared-chat-button>
                                <shared-busy-button [reader]="reader" [role]="customerRole" [type]="type"></shared-busy-button>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!reader.available">
                            <button (click)="openNotifyModal(reader)" class="btn notify-btn{{type}}"><span
                                    class="fa fa-envelope"></span> Notify me when online
                            </button>
                        </ng-container>
                    </shared-major-actions>
                </div>
            </div>

        </div>
    </div>
</div>
