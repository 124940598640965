import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Reader} from '../../entity/reader';
import {BackRouter} from '../../services/back-router';
import {UserService} from '../../../../services/user.service';
import {AllEmiterService} from '../../../../services/all-emiter.service';
import {SubSink} from 'subsink';
import {NotifyComponent} from '../notify/notify.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-small-reader-card',
  templateUrl: './small-reader-card.component.html',
  styleUrls: ['./small-reader-card.component.scss']
})
export class SmallReaderCardComponent implements OnInit, OnDestroy {

  @Input()
  reader: Reader;
  @Input()
  type: String;
  @Input()
  userIsLoggedIn: boolean;
  profileLink = '';
  customerRole = '';

  private subs = new SubSink();
  bsModalRef: BsModalRef;
  constructor(
      public br: BackRouter,
      private userService: UserService,
      private allEmiterService: AllEmiterService,
      private modalService: BsModalService,
  ) {

    this.subs.sink = this.allEmiterService.subsLoggedIn = this.allEmiterService.invokeLoggedIn.subscribe((data) => {
      this.customerRole = this.userService.getRole();
    });
  }

  ngOnInit(): void {

    this.profileLink = this.goToProfile(this.reader);
    this.customerRole = this.userService.getRole();

  }

  doOpenSade() {

  }

  goToProfile(reader) {
    return '/psychic-reader/' + reader.nickname.toLowerCase()
        .replace(/ /g, '-')
        .replace(/\b\w/g, l => l.toUpperCase());
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  openNotifyModal(reader) {
    const initialState = {
      readerId: reader.id,
    };
    this.bsModalRef = this.modalService.show(NotifyComponent, {
      initialState: initialState,
      class: 'modal-md',
      animated: false,
    });
  }

}
